import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { convertFileToBase64 } from '../../../helpers/convertFileToBase64';
import { useAxiosPrivateData } from '../../../hooks/axios/useAxiosPrivate';
import IntuIconButton from '../../buttons/IntuIconButton';
import CanvasContainer from '../container/image/CanvasContainer';
import CloseIcon from '@mui/icons-material/Close';
import StarsIcon from '@mui/icons-material/Stars';
import DropzoneContainer from '../../dropzoneContainer/DropzoneContainer';
import { ProductImageHeaderContext } from '../container/image/ProductImageHeader';
import { useFormikContext } from 'formik';
import useResponseHandling from '../../../hooks/useResponseHandler';

export const ImageContext = createContext();

const UploadImageDialog = ({ images, productId }) => {
  const { t: transButtons } = useTranslation('buttons');
  const { t: transFields } = useTranslation('fields');
  const { t: transMessages } = useTranslation('messages');
  const { t: transValidations } = useTranslation('validation');
  const {
    isOpen,
    handleConfirmNewImage,
    handleConfirmEdit,
    handleClose,
    editingImage,
    handleDeleteImage,
    handleSetDefaultImage,
  } = useContext(ProductImageHeaderContext);

  const { setErrorDialog, handleErrorResponse, handleRegularResponse } =
    useResponseHandling();
  const { values } = useFormikContext();

  const axiosData = useAxiosPrivateData();

  const [initialMeshes, setInitialMeshes] = useState([]);
  const [meshes, setMeshes] = useState([]);
  const [cameraPosition, setCameraPosition] = useState([0, 0, 50]);
  const [rotationX, setRotationX] = useState(0);
  const [rotationY, setRotationY] = useState(0);
  const [isProductImageDirtied, setIsProductImageDirtied] = useState(false);

  const [image, setImage] = useState(null);
  const [imageType, setImageType] = useState(null);
  const [previewURL, setPreviewURL] = useState(null);
  const [remoteURL, setRemoteURL] = useState();
  const [isSaving, setIsSaving] = useState(false);
  const [fileName, setFileName] = useState('');
  const [is3DFile, setIs3DFile] = useState(false);

  const intialValuesSet = useRef(false);
  useEffect(() => {
    if (!editingImage) return;
    if (intialValuesSet.current) return;

    if (editingImage.options) {
      const {
        rotationX = 0,
        rotationY = 0,
        cameraPosition = [0, 0, 50],
      } = editingImage.options;
      setRotationX(rotationX);
      setRotationY(rotationY);
      setCameraPosition(cameraPosition);
      intialValuesSet.current = true;
    }
  }, [editingImage?.options]);

  useEffect(() => {
    if (!editingImage?.url) {
      return;
    }
    setRemoteURL(editingImage.url);
    if (editingImage.imageType === 'gltf') {
      setIs3DFile(true);
    }
  }, [editingImage]);

  const handleNewImage = async (file, fileType) => {
    try {
      setIsSaving(true);
      const base64File = await convertFileToBase64(file);
      let url = '';
      let payload = {
        file: base64File,
      };
      if (fileType === 'stp' || fileType === 'step' || fileType === 'obj') {
        payload = {
          ...payload,
          fileType,
          fileName: file.name,
        };
        url = `/api/file-processor/process-step/${productId}`;
      }

      if (fileType === 'obj') {
        payload = {
          ...payload,
          fileType,
          fileName: file.name,
        };
        url = `/api/file-processor/process-obj/${productId}`;
      }

      if (fileType === 'png' || fileType === 'jpeg' || fileType === 'jpg') {
        payload = {
          ...payload,
          file_type: fileType,
          file_name: file.name,
          folder: `products/${productId}/images`,
        };
        url = '/api/aws/content/add';
      }

      const { data, status } = await axiosData.post(
        url,
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (status === 200) {
        setRemoteURL(data.data);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setFileName(file.name);
      setIsSaving(false);
    }
  };

  const onDrop = useCallback(
    (file, fileType) => {
      const fileName = file.name;
      const fileTypeProper = fileType.toLowerCase();

      const duplicate = values?.images.find((image) => image.name === fileName);

      if (duplicate) {
        setErrorDialog({
          open: true,
          title: transMessages('global.error'),
          message: transValidations('image.duplicate'),
        });
        return;
      }

      if (
        fileTypeProper !== 'stp' &&
        fileTypeProper !== 'step' &&
        fileTypeProper !== 'obj'
      ) {
        setPreviewURL(URL.createObjectURL(file));
      }
      setImage(file);
      setImageType(fileTypeProper);
      handleNewImage(file, fileTypeProper);
    },
    [productId, values.images],
  );

  const saveModelChanges = async (updatedMeshes, name) => {
    try {
      setIsSaving(true);

      const payload = {
        meshes: updatedMeshes,
        fileName: name,
      };

      const saveModelToRemote = await axiosData.post(
        `/api/file-processor/update-gltf/${productId}`,
        JSON.stringify(payload),
        {
          headers: { 'Content-Type': 'application/json' },
        },
      );

      const { data, status } = saveModelToRemote;

      if (status === 200) {
        const images = data.data;
        setRemoteURL(data.data.gltf);
        setMeshes(updatedMeshes);
        handleConfirmEdit(images, name, {
          rotationX,
          rotationY,
          cameraPosition,
        });
        setIsProductImageDirtied(false);
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      } else {
        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
      return saveModelToRemote;
    } catch (error) {
      console.error('Error while saving model changes: ', error);
      handleErrorResponse(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleConfirmClick = async () => {
    let localName = fileName || editingImage.name;
    let localType =
      imageType?.toLowerCase() || editingImage.imageType?.toLowerCase();
    if (imageType === 'stp' || imageType === 'step' || imageType === 'obj') {
      const [name] = fileName.split('.');
      localType = 'gltf';
      localName = `${name}.gltf`;
    }

    if (!isProductImageDirtied) {
      if (!remoteURL) {
        return;
      }

      handleConfirmNewImage({
        url: remoteURL,
        fileName: localName,
        imageType: localType,
      });
    } else {
      const updatedMeshes = meshes.map((mesh) => ({
        name: mesh.name,
        color: mesh.material.color.getHex(),
        roughness: mesh.material.roughness,
        metalness: mesh.material.metalness,
        positions: Array.from(mesh.geometry.attributes.position.array),
        normals: Array.from(mesh.geometry.attributes.normal.array),
        indices: mesh?.geometry?.index
          ? Array.from(mesh.geometry.index.array)
          : [],
      }));

      await saveModelChanges(updatedMeshes, localName, localType);
    }

    setImage(null);
    setImageType(null);
    setRemoteURL(null);
    setPreviewURL(null);
  };

  const handleCloseClick = () => {
    handleClose();
    setImage(null);
    setImageType(null);
    setPreviewURL(null);
    setRemoteURL(null);
  };

  const handleRemoveImage = () => {
    setImage(null);
    setImageType(null);
    setPreviewURL(null);
    setRemoteURL(null);
    handleDeleteImage(remoteURL || editingImage.url);
  };

  const handleResetScene = () => {
    if (meshes) {
      setMeshes((prevMeshes) => {
        return prevMeshes.map((mesh, index) => {
          // Get the corresponding initial state
          const initialState = initialMeshes[index];

          // Reset the mesh's material properties using the stored hex color
          mesh.material.color.setHex(initialState.color);
          mesh.material.roughness = initialState.roughness;
          mesh.material.metalness = initialState.metalness;

          return mesh;
        });
      });

      setRotationX(0);
      setRotationY(0);
      setCameraPosition([0, 0, 100]);
    }
    // Reset the dirty state since we've restored to initial values
    setIsProductImageDirtied(false);
  };

  const handleDefaultClick = () => {
    handleSetDefaultImage(editingImage || remoteURL);
  };

  const editingImageType = useMemo(() => {
    if (!editingImage) return null;
    return editingImage.imageType.toLowerCase();
  }, [editingImage]);

  const isConfirmDisabled = useMemo(() => {
    if (editingImage && isProductImageDirtied) return false;

    if (editingImage && !isProductImageDirtied) return true;

    if (!image && !imageType) return true;

    if (isSaving) return true;

    return false;
  }, [editingImage, isProductImageDirtied, image, imageType, isSaving]);

  const isRemoveDisabled = useMemo(() => {
    if (editingImage) return false;

    if (remoteURL && image) return false;

    return true;
  }, [editingImage, remoteURL, image]);

  return (
    <ImageContext.Provider
      value={{
        handleResetScene,
        image,
        setImage,
        is3DFile,
        setIs3DFile,
      }}
    >
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle
          sx={{
            textTransform: 'none',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {transFields('upload_image.description')}
        </DialogTitle>
        <DialogContent
          style={{
            paddingTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            width: '80%',
          }}
        >
          {!previewURL && !remoteURL && !isSaving && !editingImage ? (
            <DropzoneContainer onDrop={onDrop} />
          ) : null}
          {image ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography>{image.name}</Typography>
            </Box>
          ) : null}
          {isSaving ? <CircularProgress /> : null}
          <Box display="flex" flexDirection="column" width="100%">
            {previewURL &&
            (imageType === 'png' ||
              imageType === 'jpeg' ||
              imageType === 'jpg') ? (
              <Box margin="0 auto" width="100%" maxWidth="500px" height="auto">
                <Box
                  component="img"
                  src={`${previewURL}#page=1`}
                  alt="product preview image"
                  sx={{
                    display: 'block',
                    margin: '0 auto',
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    maxWidth: '500px',
                    maxHeight: '500px',
                  }}
                />
              </Box>
            ) : remoteURL &&
              (imageType === 'stp' ||
                imageType === 'step' ||
                imageType === 'obj') ? (
              <Box>
                <CanvasContainer
                  url={remoteURL}
                  type={imageType}
                  viewMode="edit"
                  width="500px"
                  height="500px"
                  enableZoom={true}
                  initialCenter={false}
                  setRotationX={setRotationX}
                  setRotationY={setRotationY}
                  rotationX={rotationX ?? 0}
                  rotationY={rotationY ?? 0}
                  cameraPosition={cameraPosition ?? [0, 0, 50]}
                  setCameraPosition={setCameraPosition}
                  setIsProductImageDirtied={setIsProductImageDirtied}
                  meshes={meshes}
                  setMeshes={setMeshes}
                  setInitialMeshes={setInitialMeshes}
                />
              </Box>
            ) : editingImage && editingImageType === 'gltf' ? (
              <Box>
                <CanvasContainer
                  url={editingImage.url}
                  width="500px"
                  height="500px"
                  viewMode="edit"
                  rotationX={rotationX ?? 0}
                  setRotationX={setRotationX}
                  rotationY={rotationY ?? 0}
                  setRotationY={setRotationY}
                  cameraPosition={cameraPosition ?? [0, 0, 50]}
                  setCameraPosition={setCameraPosition}
                  setIsProductImageDirtied={setIsProductImageDirtied}
                  meshes={meshes}
                  setMeshes={setMeshes}
                  setInitialMeshes={setInitialMeshes}
                  showOrbitControls={true}
                  enableZoom={true}
                />
              </Box>
            ) : editingImage &&
              (editingImageType === 'png' ||
                editingImageType === 'jpeg' ||
                editingImageType === 'jpg') ? (
              <Box margin="0 auto" width="100%" maxWidth="500px" height="auto">
                <Box
                  component="img"
                  src={`${editingImage.url}#page=1`}
                  alt="product preview image"
                  sx={{
                    display: 'block',
                    margin: '0 auto',
                    width: '100%',
                    height: 'auto',
                    objectFit: 'contain',
                    maxWidth: '500px',
                    maxHeight: '500px',
                  }}
                />
              </Box>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            padding: '0 16px 16px 16px',
          }}
        >
          <Box width="100%" display="flex" justifyContent="space-between">
            <IntuIconButton
              size="small"
              variant="outlined"
              tooltipTitle={transButtons('close')}
              onClick={handleCloseClick}
              IconComponent={CloseIcon}
            />
            {image || editingImage?.url ? (
              <Box display="flex" gap={2}>
                <IntuIconButton
                  size="small"
                  type="delete"
                  variant="outlined"
                  tooltipTitle={transButtons('remove')}
                  disabled={isRemoveDisabled}
                  onClick={handleRemoveImage}
                />
                <IntuIconButton
                  size="small"
                  variant="outlined"
                  tooltipTitle={transButtons('default')}
                  onClick={handleDefaultClick}
                  disabled={
                    (!remoteURL && !editingImage) ||
                    images.length === 1 ||
                    images.length === 0
                  }
                  IconComponent={StarsIcon}
                />
                <IntuIconButton
                  size="small"
                  type="submit"
                  variant="outlined"
                  tooltipTitle={transButtons('confirm')}
                  onClick={() => handleConfirmClick(image)}
                  disabled={isConfirmDisabled}
                />
              </Box>
            ) : null}
          </Box>
        </DialogActions>
      </Dialog>
    </ImageContext.Provider>
  );
};

export default UploadImageDialog;
