import React, { useContext, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Box,
} from '@mui/material';
import i18n from '../../../../i18n.js';
import axios from '../../../../hooks/axios/axios.js';
import { Formik, Form, useFormikContext } from 'formik';
import AccountTypeField from '../../fields/type/AccountTypeField.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../../../context/auth/AuthProvider.jsx';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsTracker.jsx';
import { useTranslation } from 'react-i18next';
import IntuIconButton from '../../../buttons/IntuIconButton.jsx';

const LinkedInSignUpDialog = ({
  linkedInDialogOpen = false,
  setLinkedInDialogOpen,
}) => {
  const { setAuth } = useContext(AuthContext);
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { gaEventTracker } = useAnalyticsEventTracker();
  const { setIsLoading } = useProcessingHandler();
  const { values } = useFormikContext();

  const { t } = useTranslation('dialogs', {
    keyPrefix: 'LinkedInSignUpDialog',
  });
  const { t: transButtons } = useTranslation('buttons');

  const navigate = useNavigate();

  const host_url = window.location.host;
  const location = useLocation();
  const destination_url = location.state?.from?.pathname;

  // handle linkedin SignUp
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_SIGNIN_CLIENT_ID,
    scope: 'openid profile email',
    redirectUri: `${window.location.origin}/linkedin-callback`,
    onSuccess: async (authCode) => {
      const controller = new AbortController();
      const { signal } = controller;

      console.log('Submit Values -> ', values);

      try {
        setIsLoading({
          status: true,
          type: 'spinner',
          text: i18n.t('form.message.sign_up'),
        });

        const url = `/api/linkedin/signup`;
        const params = {
          code: authCode,
          host_url: host_url,
          accType: values.account_type,
        };

        const userInfoResponse = await axios.get(url, {
          params,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        if (userInfoResponse.status === 200) {
          // SIgnUp successful
          const { user_info, auth_info, navigate_to } = userInfoResponse.data;

          // console.log('Auth Info -> ', auth_info);
          // console.log('User Info -> ', user_info);

          gaEventTracker({
            category: 'Account Management',
            action: 'Event',
            label: 'User Logged In',
          });

          let image = '';
          // Set Profile Picture
          if (user_info?.profile_picture?.mimetype) {
            image = `data:${user_info.profile_picture.mimetype};base64,${user_info.profile_picture.buffer.toString('base64')}`;
          }

          // Set User Auth State
          setAuth({
            auth_info,
            user_info: {
              ...user_info,
              profile_picture_view_url: image,
            },
          });

          // Navigate user
          if (destination_url) {
            // Redirect to original page
            navigate(destination_url, { replace: true });
          } else {
            // Navigate to user dashboard
            navigate(navigate_to, { replace: true });
          }
        } else {
          const { status, message, navigate_to } = userInfoResponse.data;

          if (navigate_to) {
            navigate(navigate_to, { replace: true });
          }

          handleRegularResponse({
            open: true,
            status: status,
            message: message,
          });
        }
      } catch (error) {
        console.log(error);
        handleErrorResponse(error);
      } finally {
        setIsLoading({ status: false, type: '', text: '' });
        controller.abort(signal);
      }
    },
    onError: (error) => {
      console.log('Error Authenticating: ', error);
    },
  });

  return (
    <Dialog
      open={linkedInDialogOpen}
      onClose={setLinkedInDialogOpen}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2} sx={{ mt: 1 }}>
          <AccountTypeField required />
        </Stack>
        <DialogActions sx={{ mt: 2 }}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              <IntuIconButton
                type="cancel"
                onClick={setLinkedInDialogOpen}
                tooltipTitle={transButtons('cancel')}
              />
            </Box>
            <Box sx={{ marginLeft: 'auto' }}>
              <IntuIconButton
                type="submit"
                variant="contained"
                disabled={!values.account_type}
                tooltipTitle={transButtons('signup')}
                onClick={() => linkedInLogin()}
              />
            </Box>
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default LinkedInSignUpDialog;
