import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { CartContext } from '../../../context/cart/CartContext';
import { useTranslation } from 'react-i18next';

const CartTotalsContainer = () => {
  const { cartSubTotal } = useContext(CartContext);
  const { t } = useTranslation('container', {
    keyPrefix: 'CartTotalsContainer',
  });
  return (
    <Box
      display="flex"
      flexDirection="column"
      p={2}
      width="40%"
      margin="0 auto"
      minWidth={400}
    >
      <Box
        boxShadow={2}
        display="flex"
        border="1px solid"
        borderColor="secondary.light"
        borderRadius="4px"
      >
        <Box
          bgcolor="primary.main"
          flex={0.4}
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Typography my={2} color="primary.contrastText" textAlign="right">
            {t('subtotal')}
          </Typography>
        </Box>
        <Box
          flex={1}
          p={2}
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
        >
          <Typography my={2} fontWeight="bold" textAlign="right">
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(cartSubTotal)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CartTotalsContainer;
