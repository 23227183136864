import { useMemo, useState, createContext, useContext } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlaceHolderImage from '../../../../assets/core/intuPlaceHolder.png';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadImageDialog from '../../dialogs/UploadImageDialog';
import DraggableCarousel from '../../../mui/DraggableCarousel';
import { useFormikContext } from 'formik';
import ResponseContext from '../../../../context/providers/ResponseProvider';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';
import { CircularProgress } from '@mui/material';

export const ProductImageHeaderContext = createContext();

const ProductImageHeader = () => {
  const { t } = useTranslation('container', {
    keyPrefix: 'ProductImageHeader',
  });
  const { t: transButtons } = useTranslation('buttons');

  const { values, setValues } = useFormikContext();
  const { setStatusMsg } = useContext(ResponseContext);

  const axios = useAxiosPrivate();

  const [images, setImages] = useState([]);
  const [activeImage, setActiveImage] = useState(0);
  const [editingImage, setEditingImage] = useState(null);
  const [isUploadImageDialogOpen, setIsUploadImageDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const filteredProductImages = useMemo(() => {
    if (!values?.images.length) return [];
    const filteredImages = values?.images.filter(
      (image) =>
        image.imageType.toLowerCase() !== 'stp' &&
        image.imageType.toLowerCase() !== 'step' &&
        image.imageType.toLowerCase() !== 'obj',
    );
    return filteredImages;
  }, [values?.images]);

  useMemo(() => {
    if (!filteredProductImages || !filteredProductImages.length) return;
    setIsLoading(true);
    setImages(filteredProductImages);
    setTimeout(() => setIsLoading(false), 500); // Simulate brief load
  }, [filteredProductImages]);

  const handleEditClick = () => {
    setIsUploadImageDialogOpen(true);
    setEditingImage(images[activeImage]);
  };

  const handleClose = () => {
    setIsUploadImageDialogOpen(false);
    setEditingImage(null);
  };

  const handleConfirmNewImage = (newImage) => {
    console.log('New Image -> ', newImage);

    setValues({
      ...values,
      images: [
        ...values.images,
        {
          url: newImage.url,
          name: newImage.fileName,
          imageType: newImage.imageType,
          options: {},
          created: new Date(),
        },
      ],
    });

    setIsUploadImageDialogOpen(false);
  };

  const handleConfirmEdit = (images, imageName, options) => {
    const updatedImages = [...values.images];

    images.forEach((image) => {
      const keys = Object.keys(image);
      const fileType = keys[0];
      const fileName = `${imageName.split('.').slice(0, -1).join('.')}.${fileType}`;
      const remoteURL = image[fileType];

      // Create the new image object
      const newImage = {
        url: remoteURL,
        name: fileName,
        imageType: fileType,
        options: { ...options },
        modified: new Date(),
      };

      const existingIndex = updatedImages.findIndex(
        (img) => img.url === remoteURL,
      );

      if (existingIndex !== -1) {
        // Replace existing image
        updatedImages[existingIndex] = newImage;
      } else {
        // Add as new image
        updatedImages.push(newImage);
      }
    });

    setValues({
      ...values,
      images: updatedImages,
    });

    setEditingImage(null);
    setIsUploadImageDialogOpen(false);
  };

  // Set Default Image
  const handleSetDefault = (image) => {
    setValues({
      ...values,
      images: [image, ...values.images.filter((img) => img.url !== image.url)],
    });
    setEditingImage(null);
    setIsUploadImageDialogOpen(false);
  };

  const handleReSortImages = (updatedFilteredImages) => {
    setValues({
      ...values,
      images: updatedFilteredImages,
    });
  };

  // Delete Image
  const handleDeleteImage = async (file) => {
    const fileType = file.substring(file.lastIndexOf('.') + 1, file.length);
    const fileName = file.substring(file.lastIndexOf('/') + 1, file.length);
    const folder = `products/${values._id}/images/${fileType}/${fileName}`;

    const bucketPayload = {
      folder,
    };

    try {
      const { status, data } = await axios.post(
        '/api/aws/content/delete',
        bucketPayload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (status === 200) {
        setStatusMsg({
          open: true,
          status: data.status,
          message: data.message,
        });

        const newImages = values.images.filter((img) => img.url !== file);

        setValues({
          ...values,
          images: newImages,
        });

        setEditingImage(null);
        setIsUploadImageDialogOpen(false);
      }
    } catch (error) {
      console.error('error', error);
      setStatusMsg({
        open: true,
        status: 'error',
        message: error.message,
      });
    }
  };

  return (
    <ProductImageHeaderContext.Provider
      value={{
        images,
        setImages,
        activeImage,
        setActiveImage,
        isOpen: isUploadImageDialogOpen,
        handleClose,
        handleConfirmNewImage,
        handleConfirmEdit,
        handleDeleteImage,
        handleReSortImages,
        editingImage,
        setEditingImage,
        handleSetDefaultImage: handleSetDefault,
      }}
    >
      {isLoading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            height: isMobile ? '80vh' : '90vh',
            backgroundColor: 'background.default',
          }}
        >
          <CircularProgress color="info" size={64} />
        </Grid>
      ) : images.length ? (
        <Box
          sx={{
            minHeight: '100vh', // fix the typo from "vdh"
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start', // so it doesn't vertically center everything
            overflowY: 'auto', // allow scrolling
          }}
        >
          <Box
            mb={2}
            sx={{
              height: isMobile ? '80vh' : '90vh',
              position: 'relative',
            }}
          >
            <DraggableCarousel
              isUploadImageDialogOpen={isUploadImageDialogOpen}
              setIsUploadImageDialogOpen={setIsUploadImageDialogOpen}
              handleEditClick={handleEditClick}
            />
          </Box>
        </Box>
      ) : (
        <Grid className="intu__divider" sx={{ top: 0, margin: 0 }}>
          <Grid
            item
            className="article"
            sx={{
              top: 0,
              backgroundImage: `url(${PlaceHolderImage})`,
              backgroundSize: '30%',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundAttachment: 'fixed',
              height: isMobile ? '80vh' : '90vh',
            }}
          >
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              textAlign="center"
            >
              <Grid item>
                <Typography
                  variant="h1"
                  className="header"
                  sx={{ color: 'white' }}
                >
                  {t('placeholder')}
                </Typography>
              </Grid>
              {/* Buttons in Edit Mode & View mode */}
              <Grid item>
                <Button
                  component="span"
                  variant="outlined"
                  startIcon={<FileUploadIcon sx={{ color: 'info.main' }} />}
                  disabled={images.length > 10}
                  sx={{
                    textTransform: 'none',
                    borderRadius: '24px',
                    minWidth: 187,
                    height: 40,
                  }}
                  color="info"
                  onClick={() => setIsUploadImageDialogOpen(true)}
                >
                  {transButtons('upload_img')}
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              direction={{ xs: 'column', md: 'row' }}
              alignItems="center"
              sx={{
                minWidth: 300,
                width: 'auto',
                height: 'auto',
              }}
            ></Grid>
          </Grid>
        </Grid>
      )}
      <UploadImageDialog images={images} productId={values._id} />
    </ProductImageHeaderContext.Provider>
  );
};

export default ProductImageHeader;
