import { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CartContext } from '../../context/cart/CartContext';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FaceIcon from '@mui/icons-material/Face';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CanvasContainer from '../../components/new-product/container/image/CanvasContainer';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CartTotalsContainer from '../../components/cart/container/CartTotalsContainer';

const CartPage = () => {
  const { cartProducts, setCartProducts, manufacturers } =
    useContext(CartContext);
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.CartPage',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');
  const { setFooterConfig } = useNavigationFooter();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedEstimate, setSelectedEstimate] = useState(null);

  // Setup navigation footer
  useEffect(() => {
    setFooterConfig((prev) => ({
      ...prev,
      showFooter: true,
    }));

    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  const handleQuantityIncrease = (id) => {
    setCartProducts((prevState) => {
      return prevState.map((product) => {
        if (id === product.product._id) {
          return {
            ...product,
            quantity: product.quantity + 1,
          };
        } else {
          return product;
        }
      });
    });
  };

  const handleQuantityDecrease = (id) => {
    setCartProducts((prevState) => {
      return prevState.map((product) => {
        if (id === product.product._id) {
          return {
            ...product,
            quantity: product.quantity - 1,
          };
        } else {
          return product;
        }
      });
    });
  };

  const handleQuantityChange = (p, newQuantity) => {
    setCartProducts((prevState) => {
      return prevState.map((product) => {
        if (p._id === product.product._id) {
          return {
            ...product,
            quantity: newQuantity,
          };
        } else {
          return product;
        }
      });
    });
  };

  const handleRemove = (id) => {
    setCartProducts((prevState) => {
      return prevState.filter((product) => id !== product.product._id);
    });
  };

  const handleProceed = () => {
    navigate('/checkout');
  };

  const [initialMeshes, setInitialMeshes] = useState([]);
  const [meshes, setMeshes] = useState([]);
  const [cameraPosition, setCameraPosition] = useState([0, 0, 50]);
  const [rotationX, setRotationX] = useState(0);
  const [rotationY, setRotationY] = useState(0);
  const [isProductImageDirtied, setIsProductImageDirtied] = useState(false);

  console.log('Cart Products on Cart Page -> ', cartProducts);
  return (
    <Box mt={4} px={4} minHeight="60dvh">
      <Box display="flex" justifyContent="center">
        <Typography variant="h2" textAlign="center">
          {t('title')}
        </Typography>
      </Box>

      {!cartProducts.length ? (
        // Empty Products
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="h5" color="error" textAlign="center">
            {transMessages('cart.empty')}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate('/products')}
            sx={{ width: '200px' }}
          >
            {transButtons('shop_now')}
          </Button>
        </Box>
      ) : (
        <>
          <Box display="flex" flexDirection="column" gap={2} minHeight="60dvh">
            <Box
              display={isSmall ? 'none' : 'flex'}
              alignItems="center"
              justifyContent="space-between"
              p={4}
              gap={2}
            >
              <Typography width={300}>{t('product')}</Typography>
              <Typography flex={1}></Typography>
              <Typography flex={0.25} textAlign="center">
                {t('price')}
              </Typography>
              <Typography minWidth={146} flex={0.25} textAlign="center">
                {t('quantity')}
              </Typography>
              <Typography flex={0.25} textAlign="center">
                {t('subtotal')}
              </Typography>
              <Typography flex={0.25}></Typography>
            </Box>
            {Array.from(manufacturers).map(([key, value]) => {
              return (
                <Box key={value._id}>
                  <Box
                    display="flex"
                    alignItems="center"
                    borderRadius="4px"
                    p={4}
                    bgcolor="primary.main"
                    gap={4}
                    height={70}
                  >
                    <Typography color="primary.contrastText">
                      <span
                        style={{
                          fontColor: 'white',
                          fontWeight: 'bold',
                        }}
                      >
                        {t('manufacturer')}:{' '}
                      </span>
                      <span
                        style={{
                          fontColor: 'text.primary',
                          fontWeight: 'bold',
                        }}
                      >
                        {value.name.toUpperCase()}
                      </span>
                    </Typography>
                  </Box>

                  {Object.keys(value.products).map((key) => {
                    const product = value.products[key];
                    let subtotal =
                      product.product.list_price * product.quantity;
                    const fileType =
                      product.product.images[0].imageType.toLowerCase();
                    let image = '';
                    if (
                      product.appointedRep &&
                      product.appointedRep.profile_picture
                    ) {
                      image = `data:${product.appointedRep.profile_picture.mimetype};base64,${product.appointedRep.profile_picture.buffer.toString('base64')}`;
                    }

                    // If there is a sales rep appointed add a 3 percent discount
                    const hasSalesRep =
                      product.appointedRep &&
                      product.appointedRep.account_type !== 'intutec';
                    if (hasSalesRep) {
                      const discount = subtotal * 0.03;
                      subtotal = subtotal - discount;
                    }

                    return (
                      <Box
                        key={key}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderRadius="4px"
                        p={4}
                        gap={2}
                        flexDirection={isSmall ? 'column' : 'row'}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection={isMedium ? 'column' : 'row'}
                          maxWidth={
                            isSmall ? '100%' : isMedium ? '20%' : '100%'
                          }
                        >
                          {fileType === 'png' ? (
                            <Avatar src={product.product.images[0]}>
                              <PrecisionManufacturingIcon
                                style={{
                                  fontSize: 60,
                                  color: 'primary.main',
                                }}
                              />
                            </Avatar>
                          ) : fileType === 'gltf' ? (
                            <Box minHeight={80} height={80}>
                              <CanvasContainer
                                url={product.product.images[0].url}
                                options={product.product.images[0].options}
                                viewMode="view"
                                showSliders={false}
                                minHeight="80px"
                                rotationX={rotationX}
                                setRotationX={setRotationX}
                                rotationY={rotationY}
                                setRotationY={setRotationY}
                                cameraPosition={cameraPosition}
                                setCameraPosition={setCameraPosition}
                                setIsProductImageDirtied={
                                  setIsProductImageDirtied
                                }
                                meshes={meshes}
                                setMeshes={setMeshes}
                                setInitialMeshes={setInitialMeshes}
                              />
                            </Box>
                          ) : (
                            <Box
                              width={300}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Avatar src={''}>
                                <PrecisionManufacturingIcon
                                  style={{
                                    color: 'primary.main',
                                  }}
                                />
                              </Avatar>
                            </Box>
                          )}
                          <Typography flex={1}>
                            {product.product.product_name}
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection={isMedium ? 'column' : 'row'}
                          alignItems="center"
                        >
                          <Avatar src={image} alt="profile picture">
                            <FaceIcon sx={{ color: 'primary.dark' }} />
                          </Avatar>
                          <Box ml={2}>
                            <Typography>
                              {product.appointedRep?.first_name}{' '}
                              {product.appointedRep?.last_name}
                            </Typography>
                            <Typography variant="body2">
                              {product.appointedRep?.address.city},{' '}
                              {product.appointedRep?.address.state}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography flex={0.25} textAlign="center">
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(product.product.list_price)}
                        </Typography>
                        <Box
                          flex={0.25}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <IconButton
                            disabled={product.quantity === 1}
                            onClick={() =>
                              handleQuantityDecrease(product.product._id)
                            }
                          >
                            <RemoveIcon />
                          </IconButton>
                          <TextField
                            type="number"
                            value={product.quantity}
                            onChange={(e) =>
                              handleQuantityChange(
                                product.product,
                                Math.max(1, parseInt(e.target.value) || 1),
                              )
                            }
                            inputProps={{
                              min: 1,
                              style: { textAlign: 'center' },
                            }}
                            sx={{ mx: 1, minWidth: 50, width: 50 }}
                            InputProps={{
                              sx: {
                                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                  {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                  },
                                '& input[type=number]': {
                                  MozAppearance: 'textfield',
                                },
                              },
                            }}
                          />
                          <IconButton
                            onClick={() =>
                              handleQuantityIncrease(product.product._id)
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        </Box>
                        {hasSalesRep ? (
                          <Badge badgeContent="Discounted" color="primary">
                            <Typography flex={0.25} textAlign="center">
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(subtotal)}
                            </Typography>
                          </Badge>
                        ) : (
                          <Typography flex={0.25} textAlign="center">
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(subtotal)}
                          </Typography>
                        )}

                        <Box
                          flex={0.25}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <IntuIconButton
                            size="small"
                            type="cancel"
                            variant="outlined"
                            tooltipTitle={'Remove'}
                            onClick={() => handleRemove(product.product._id)}
                            IconComponent={DeleteIcon}
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
          <Box display="flex" justifyContent="center" py={4}>
            <CartTotalsContainer
              selectedEstimate={selectedEstimate}
              setSelectedEstimate={setSelectedEstimate}
            />
          </Box>
          <Box display="flex" justifyContent="center" my={4}>
            <Button
              variant="contained"
              disabled={!cartProducts.length}
              onClick={handleProceed}
            >
              {t('proceed_btn')}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CartPage;
