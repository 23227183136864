// Provides Context the authenicated Users Company Information, or in other words the company they are related to!

import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import useResponseHandling from '../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import {
  formatMuiRichTextEditor,
  getMuiRichTextEditorContent,
} from '../../helpers/muiRichtTextEditor';
import { searchUsers } from '../../api/usersRoutes';
import { searchBrands } from '../../api/brandRoutes';
import { useProcessingHandler } from '../../hooks/useProcessingHandler';
import { BrandsContext } from '../brands/BrandsProvider';
import { useTranslation } from 'react-i18next';
import { ProductTypeContext } from '../providers/ProductTypeContextProvider';
import { ApplicationsContext } from '../applications/ApplicationsContextProvider';
import i18n from '../../i18n';
import AuthContext from '../auth/AuthProvider';
import { IndustriesContext } from '../industries/IndustriesContextProvider';
import IntuThemeContext from '../providers/IntuThemeProvider';
import {
  addPaymentTerms,
  getPaymentTermsList,
} from '../../api/paymentTermRoutes';
// import { PaymentContext } from './PaymentContextProvider';

export const CompanyProfileContext = createContext();

const initialCompanyProfileValues = {
  _id: '',
  name: '',
  email: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    country: {
      label: 'United States',
      dialCode: '+1',
      code: 'US',
    },
    postcode: '',
    coordinates: { longitude: '', latitude: '' },
  },
  tax_id: '',
  theme: {
    active: false,
    logo_url: '',
    logo_alt: '',
    primary: { main: '#A8C957' },
    secondary: { main: '#333333' },
    tertiary: { main: '#A10976' },
    contrastColor: '#ffffff',
    textColor: '#000000',
  },
  phone: {
    value: '',
    info: {
      countryCallingCode: null,
      countryCode: null,
      nationalNumber: null,
      numberType: null,
      numberValue: null,
      reason: null,
    },
  },
  type: '',
  brands: [],
  website: '',
  applications: [],
  industries: [],
  product_types: [],
  commission_schedule: {},
  tow: {
    terms: [
      {
        name: 'English',
        code: 'en',
        term: getMuiRichTextEditorContent(' '),
      },
    ],
    duration: 0,
  },
  intro: '',
  intro_rte: getMuiRichTextEditorContent(' '),
  employees: [],
  admins: [],
  isAdmin: false,
  profile_picture: '',
  profile_picture_view_url: '',
  profile_completion: 0,
  payment_terms: [],
  missing_fields: [],
  initial_setup: false,
};

export const CompanyProfileProvider = ({ children }) => {
  // Get Contaxt
  const { auth } = useContext(AuthContext);

  const errRef = useRef(null);

  // Translator
  const { t } = useTranslation();

  // Subscribe to Context
  const { newBrandsList, addNewBrandsToDatabase } = useContext(BrandsContext);
  const {
    newProductTypeList,
    addNewProductTypesToDatabase,
    getProductTypeNamesById,
  } = useContext(ProductTypeContext);
  const {
    newApplicationList,
    addNewApplicationsToDatabase,
    getApplicationNamesById,
  } = useContext(ApplicationsContext);
  const { getIndustriesById } = useContext(IndustriesContext);
  const { userTheme, setUserTheme, isMemberRoute } =
    useContext(IntuThemeContext);

  // SetUp Axios
  const axios = useAxiosPrivate();

  // Set States
  const [newCompany, setNewCompany] = useState(true);
  const [companyClaimer, setCompanyClaimer] = useState({
    company_name: '',
    company_id: '',
    relationship: '',
  });
  const [isFetched, setIsFetched] = useState(false);

  // Payment Terms
  const [paymentTermOptions, setPaymentTermOptions] = useState([]);
  const [newPaymentTermDialogOpen, setNewPaymentTermDialogOpen] =
    useState(false);
  const [newPaymentTerm, setNewPaymentTerm] = useState({
    payment_term: 0,
  });
  const [newPaymentTermsList, setNewPaymentTermsList] = useState([]);

  // List of Companies
  const [companyOptions, setCompanyOptions] = useState([]);

  // Company Profile Intormations
  const [companyProfile, setCompanyProfile] = useState(
    initialCompanyProfileValues,
  );

  const [newCompanyDialog, setNewCompanyDialog] = useState(false);

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  //Get User Informations
  async function getEmployeesInformation(companyEmployees) {
    const controller = new AbortController();
    const { signal } = controller;

    const userIDs = companyEmployees.map((employee) => employee._id);
    const userInfo = [];
    const payload = {
      key: '_id',
      values: userIDs,
    };

    try {
      const { data, status } = await searchUsers(payload);
      if (status === 'success') {
        for (let i = 0; i < companyEmployees.length; i++) {
          const companyEmployee = companyEmployees[i];

          const user = data.find(
            (element) => element._id === companyEmployee._id,
          );

          if (user) {
            userInfo.push({
              ...companyEmployee,
              _id: user?._id,
              name: `${user.first_name} ${user.last_name}`,
              first_name: user.first_name,
              last_name: user.last_name,
              email: user.email,
            });
          }
        }
      }

      return userInfo;
    } catch (err) {
      handleErrorResponse(err);
      controller.abort();
    } finally {
      controller.abort(signal);
    }
  }

  // Get Company Profile Completion Process
  async function getCompanyProfileProgress() {
    const controller = new AbortController();
    const { signal } = controller;
    try {
      const url = '/api/companies/company/completion-status';
      const request = await axios.get(url, { signal });

      const response = request?.data;

      if (response) {
        return response;
      }
    } catch (err) {
      handleErrorResponse(err);
      return err;
    } finally {
      controller.abort(signal);
    }
  }

  // Search Brands in database
  async function handleGetBrands(brandsById) {
    // Guard Clause
    if (brandsById?.length === 0) {
      return;
    }

    let companyBrands = [];
    try {
      const payload = {
        key: '_id',
        values: brandsById,
      };
      const { data, status } = await searchBrands(payload);
      if (status === 'success') {
        companyBrands = data;
      }
    } catch (err) {
      handleErrorResponse(err);
    }

    return companyBrands;
  }

  // Handle Get Company Info
  async function getCompanyProfile(id) {
    // Guard Clause
    if (!id && !auth.user_info && !auth.auth_info) {
      return;
    }

    const url = `/api/companies/company/details`;

    const controller = new AbortController();
    const signal = controller.signal;

    try {
      const response = await axios.get(url, {
        params: {
          id,
        },
        headers: { 'Content-Type': 'application/json' },
        signal,
      });
      const { data, status } = response;
      const info = data.data;

      if (status === 200) {
        // Format Image
        let image = '';
        if (info?.profile_picture?.mimetype) {
          image = `data:${info.profile_picture.mimetype};base64,${info.profile_picture.buffer.toString('base64')}`;
        }

        // Get Employee Details
        let companyEmployees = [];
        let companyAdmins = [];
        let companySuperUsers = [];
        let companyAssociates = [];
        let companyAdminsInfo = [];
        let companySuperUsersInfo = [];
        let companyAssociateInfo = [];

        if (info?.employees?.length > 0) {
          companyEmployees = await getEmployeesInformation(info.employees);

          // Filter Admins
          companyAdmins = info.employees.filter(
            (employee) => employee.role === 'admin',
          );

          companySuperUsers = info.employees.filter(
            (employee) => employee.role === 'superuser',
          );
        }

        if (companyAdmins.length > 0) {
          companyAdminsInfo = await getEmployeesInformation(companyAdmins);
        }

        if (companySuperUsers.length > 0) {
          companySuperUsersInfo =
            await getEmployeesInformation(companySuperUsers);
        }

        if (companyAssociates.length > 0) {
          companyAssociateInfo =
            await getEmployeesInformation(companyAssociates);
        }

        const paymentMethods = info?.payment_methods?.map((method) => {
          return method.toLowerCase();
        });

        const companyData = {
          ...companyProfile,
          ...info,
          _id: info._id,
          name: info?.name,
          email: info?.email,
          address: info?.address,
          phone: info?.phone,
          type: info?.type,
          brands:
            info?.brands?.length > 0 ? await handleGetBrands(info.brands) : [],
          website: info?.website,
          applications:
            (info?.applications?.length > 0 &&
              (await getApplicationNamesById(info.applications))) ||
            [],
          industries:
            info?.industries?.length > 0
              ? await getIndustriesById(info.industries)
              : [],
          product_types:
            (info?.product_types.length > 0 &&
              (await getProductTypeNamesById(info.product_types))) ||
            [],
          intro: info?.intro,
          intro_rte: info?.intro || formatMuiRichTextEditor(' '),
          tow: info?.tow || {
            terms: [
              {
                name: 'English',
                code: 'en',
                term: getMuiRichTextEditorContent(' '),
              },
            ],
            duration: 0,
          },
          commission_schedule: info?.commission_schedule,
          employees: companyEmployees,
          associates: companyAssociates,
          admins: companyAdminsInfo,
          super_users: companySuperUsersInfo,
          profile_picture: info?.profile_picture,
          profile_picture_view_url: image,
          isAdmin: false,
          isSuperUser: false,
          isAssociate: false,
          initial_setup: info.initial_setup,
          payment_methods: paymentMethods,
          payment_terms: info?.payment_terms || [],
          theme: {
            ...info?.theme,
          },
        };

        // Set User Theme
        if (companyData?.theme?.active && isMemberRoute) {
          const companyTheme = info.theme;

          let theme = {
            ...userTheme,
            ...companyTheme,
          };

          if (companyData?.profile_picture_view_url) {
            theme = {
              ...theme,
              logo_url: companyData?.profile_picture_view_url,
              logo_alt: companyData?.name,
            };
          }

          setUserTheme(theme);
        }

        // Set Company Admins
        if (companyAdmins?.length > 0) {
          companyAdmins.forEach(async (employee) => {
            if (employee._id === auth.user_info?._id) {
              companyData.isAdmin = true;
            }
          });
        }

        // Set Company Super Users
        if (companySuperUsers?.length > 0) {
          companySuperUsers.forEach(async (employee) => {
            if (employee._id === auth.user_info?._id) {
              companyData.isSuperUser = true;
            }
          });
        }

        // Set Company Super Users
        if (companyAssociates?.length > 0) {
          companyAssociates.forEach(async (employee) => {
            if (employee._id === auth.user_info?._id) {
              companyData.isAssociate = true;
            }
          });
        }

        // Set Account Propfile Completion
        if (companyData.isAdmin || companyData.isSuperUser) {
          const profilecompletionProgress = await getCompanyProfileProgress();
          const { data, statusCode } = profilecompletionProgress;
          if (statusCode === 200) {
            const percentage = data.percentage;
            const missingFields = data.missingFields;
            if (percentage) {
              companyData.profile_completion = parseFloat(percentage + '%');
            }
            companyData.missing_fields = missingFields;
          }
        }

        setCompanyProfile(companyData);
        return companyData;
      }
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      controller.abort();
    }
  }

  // Handle Get Payment Terms
  async function getPaymentTerms() {
    try {
      const paymentTermsList = await getPaymentTermsList();

      if (paymentTermsList.status === 200) {
        setPaymentTermOptions(paymentTermsList.data);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  // Get User Company Info
  useEffect(() => {
    if (!auth?.auth_info) {
      return;
    }

    try {
      setIsLoading({ status: true, type: 'skeleton', text: '' });
      setIsFetched(false);
      async function handlegetCompanyProfile() {
        await getCompanyProfile(auth.user_info.company);
      }

      if (auth?.user_info?.company) {
        handlegetCompanyProfile();
        getPaymentTerms();
      } else {
        setCompanyProfile(initialCompanyProfileValues);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading({ status: false, type: 'skeleton', text: '' });
      setIsFetched(true);
    }
  }, [auth?.user_info?.company, i18n.language]);

  // Handle Company Update
  async function updateCompanyProfile(values) {
    setIsLoading({
      status: true,
      type: 'skeleton',
      text: t('form.message.update_company_details'),
    });

    try {
      // Add New Products to Database
      let productTypesByID = [];
      if (values?.product_types.every((item) => typeof item !== 'string')) {
        if (values?.product_types?.length > 0) {
          productTypesByID = values?.product_types
            .filter((product) => product.type_id)
            .map((product) => product.type_id);

          if (newProductTypeList.length > 0) {
            const newProductData = await addNewProductTypesToDatabase();
            if (newProductData?.length > 0) {
              const newProductTypesById = newProductData.map(
                (product) => product.type_id,
              );

              productTypesByID = [...productTypesByID, ...newProductTypesById];
            }
          }
        }
      } else {
        productTypesByID = values?.product_types;
      }

      // Add New Brands to the Database
      let brandsByID = [];
      if (values?.brands?.every((item) => typeof item !== 'string')) {
        if (values?.brands?.length > 0) {
          brandsByID = values?.brands
            .filter((brand) => brand._id)
            .map((brand) => brand._id);

          if (newBrandsList.length > 0) {
            const newBrandData = await addNewBrandsToDatabase(values);
            if (newBrandData?.length > 0) {
              const newBrandsById = newBrandData.map((brand) => brand._id);

              brandsByID = [...brandsByID, ...newBrandsById];
            }
          }
        }
      } else {
        brandsByID = values?.brands;
      }

      // Reformat Industries by ID
      let industriesByID = [];
      if (values?.industries.length > 0) {
        industriesByID = values?.industries
          .filter((industry) => industry.industry_id)
          .map((industry) => industry.industry_id);
      }

      // Add newly added application id's to product values
      let applicationsByID = [];
      if (values?.applications.every((item) => typeof item !== 'string')) {
        if (values?.applications.length > 0) {
          applicationsByID = values?.applications
            .filter((application) => application.application_id)
            .map((application) => application.application_id);

          // Add New Applications to the Database
          if (newApplicationList.length > 0) {
            const newApplicationData = await addNewApplicationsToDatabase();
            if (newApplicationData?.length > 0) {
              const newApplicationsById = newApplicationData.map(
                (application) => application.application_id,
              );

              applicationsByID = [...applicationsByID, ...newApplicationsById];
            }
          }
        }
      } else {
        applicationsByID = values?.applications;
      }

      // Format Employees for Database
      let employeesByID = [];
      if (values?.employees.every((item) => typeof item !== 'string')) {
        if (values?.employees?.length > 0) {
          employeesByID = values?.employees.map((employee) => {
            return {
              _id: employee._id,
              role: employee.role,
            };
          });
        }
      } else {
        employeesByID = values?.employees;
      }

      // Add new payment terms to database
      if (newPaymentTermsList.length > 0) {
        const newTerms = newPaymentTermsList.map((term) => {
          return term.payment_term;
        });
        await addPaymentTerms(newTerms);
        setNewPaymentTermsList([]);
      }

      // Update Company Account
      const url = '/api/companies/company/update';

      const payload = {
        _id: values._id,
        name: values.name,
        email: values.email,
        website: values.website,
        employees: employeesByID,
        brands: brandsByID,
        applications: applicationsByID,
        industries: industriesByID,
        product_types: productTypesByID,
        address: values.address,
        phone: values.phone,
        intro: values.intro,
        tow: values.tow,
        tax_id: values.tax_id,
        commission_schedule: values.commission_schedule,
        notification: true,
        theme: values.theme,
        payment_methods: values.payment_methods,
        payment_terms: values.payment_terms,
      };

      try {
        const request = await axios.put(url, payload, {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        const response = request?.data;

        if (request.status === 200) {
          const updatedCompanyData = response.data;
          await getCompanyProfile(updatedCompanyData._id);
        }
        handleRegularResponse({
          open: true,
          status: response.status,
          message: response.message,
        });
        return response;
      } catch (err) {
        handleErrorResponse(err);
        errRef.current?.focus();
        return err;
      }
    } catch (error) {
      console.error(error);
      return error;
    } finally {
      setIsLoading({
        status: false,
        type: 'skeleton',
        text: '',
      });
    }
  }

  return (
    <CompanyProfileContext.Provider
      value={{
        newCompany,
        setNewCompany,
        companyClaimer,
        setCompanyClaimer,
        companyOptions,
        setCompanyOptions,
        companyProfile,
        setCompanyProfile,
        getCompanyProfile,
        updateCompanyProfile,
        newCompanyDialog,
        setNewCompanyDialog,
        newPaymentTermsList,
        setNewPaymentTermsList,
        paymentTermOptions,
        setPaymentTermOptions,
        newPaymentTermDialogOpen,
        setNewPaymentTermDialogOpen,
        newPaymentTerm,
        setNewPaymentTerm,
        isFetched,
        setIsFetched,
        initialCompanyProfileValues,
      }}
    >
      {children}
    </CompanyProfileContext.Provider>
  );
};
