import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

const FormikValidationErrorDialog = ({
  open,
  title,
  message = [],
  onClose,
}) => {
  if (!open) return null;

  const renderMessageList = (
    <List>
      {message.map(({ field, message, subErrors }, i) => (
        <ListItem key={i} disablePadding sx={{ my: 1 }}>
          <ListItemText
            primary={
              <Box sx={{ my: 1 }}>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    color: 'error.main',
                    textTransform: 'capitalize',
                  }}
                >
                  {field}
                </Typography>

                {subErrors ? (
                  <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    {subErrors.map(({ subField, subMessage }, j) => (
                      <ListItem
                        key={j}
                        sx={{ display: 'list-item', py: 0 }}
                        disablePadding
                      >
                        <ListItemText
                          primary={
                            <Typography component="span">
                              <strong style={{ textTransform: 'capitalize' }}>
                                {subField}
                              </strong>
                              {`: ${subMessage}`}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography component="div" sx={{ mt: 0.5 }}>
                    {message}
                  </Typography>
                )}
              </Box>
            }
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{renderMessageList}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormikValidationErrorDialog;
