import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import FaceIcon from '@mui/icons-material/Face';
import MessageIcon from '@mui/icons-material/Message';
import IntuIconButton from '../buttons/IntuIconButton';
import useAuth from '../../hooks/useAuth';
import getUserImage from '../../helpers/getUserImage';
import useUser from '../../hooks/useUser';
import { useTranslation } from 'react-i18next';
import { Signpost } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const toRadians = (degree) => (degree * Math.PI) / 180;
  const R = 3958.8;
  const dLat = toRadians(lat2 - lat1);
  const dLon = toRadians(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return isNaN(c) ? null : (R * c).toFixed(0);
};

const ProductRepresentativesContainer = ({
  productRepresentatives,
  handleMessage,
  chats,
  displayTitle,
}) => {
  const { auth } = useAuth();
  const { user } = useUser();
  const theme = useTheme();
  const { t } = useTranslation('container', {
    keyPrefix: 'ProductRepresentativesBox',
  });
  const navigate = useNavigate();

  const { t: transTypes } = useTranslation('types');
  const { t: transButtons } = useTranslation('buttons');

  const sortedRepresentatives = productRepresentatives
    .map((rep) => ({
      ...rep,
      distance: calculateDistance(
        Number(user.location.latitude),
        Number(user.location.longitude),
        rep.address.coordinates?.latitude,
        rep.address.coordinates?.longitude,
      ),
    }))
    .sort((a, b) => a.distance - b.distance);

  return (
    <Box display="flex" flexDirection="column" width="100%" gap={4}>
      {displayTitle && (
        <Box>
          <Tabs
            value={0}
            variant="scrollable"
            sx={{
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '1.25rem',
                color: theme.palette.text.primary,
              },
              '& .Mui-selected': {
                color: theme.palette.text.primary + '!important',
              },
            }}
          >
            <Tab label={t('title')} />
          </Tabs>
          <Divider
            sx={{
              borderBottomWidth: '1px',
              borderColor: theme.palette.divider,
            }}
          />
        </Box>
      )}
      <Box display="flex" flexWrap="wrap" gap={2}>
        {sortedRepresentatives.map((rep) => {
          const found = chats.find((chat) => chat.sender._id === rep._id);
          const isDisabled = rep._id === auth?.user_info?._id || !!found;

          return (
            <Card
              key={rep._id}
              sx={{
                width: '100%',
                boxShadow: 3,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                p: 2,
              }}
            >
              <CardHeader
                avatar={
                  <Avatar src={getUserImage(rep)} alt="profile picture">
                    <FaceIcon sx={{ color: 'primary.dark' }} />
                  </Avatar>
                }
                title={
                  rep.account_type === 'intutec'
                    ? `${rep.name}`
                    : `${rep.first_name} ${rep.last_name}`
                }
                subheader={
                  rep.address
                    ? `${rep.address.city}, ${rep.address.state.toUpperCase()}`
                    : ''
                }
                action={
                  <IntuIconButton
                    size="small"
                    type="chat"
                    variant="outlined"
                    tooltipTitle="Message"
                    onClick={() => handleMessage(rep)}
                    IconComponent={MessageIcon}
                    disabled={isDisabled}
                  />
                }
              />

              {rep.distance && (
                <CardContent
                  sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                >
                  <Signpost />
                  <Typography>
                    {new Intl.NumberFormat('en-US', {
                      maximumFractionDigits: 2,
                    }).format(rep.distance)}{' '}
                    {transTypes('miles')}
                  </Typography>
                </CardContent>
              )}
            </Card>
          );
        })}
        <Card
          sx={{
            width: '100%',
            boxShadow: 3,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <CardHeader title={t('sign_up_header')} />
          <CardContent>
            <Typography variant="body2">{t('sign_up_text')}</Typography>
            <Box display="flex" justifyContent="flex-start">
              <Button
                variant="outlined"
                onClick={() => navigate('/account/signup')}
                sx={{ mt: 2 }}
              >
                {transButtons('sign_up')}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ProductRepresentativesContainer;
