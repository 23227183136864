import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import { getFileName } from '../../helpers/getFileName';
import { ViewInAr } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import filePurposeOptions from '../../data/FilePurposeOptions.json';

const PublicProductFiles = ({ files }) => {
  const { t: transData } = useTranslation('data', {
    keyPrefix: 'FilePurposeOptions',
  });

  const { t: transButtons } = useTranslation('buttons');

  const handleDownload = (url, name) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${name}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error(`Error downloading the ${name}:`, error));
  };

  const purposeOptions = filePurposeOptions.map((option) => ({
    ...option,
    label: transData(`${option.value}`),
  }));

  const groupedFiles = files.reduce((acc, file) => {
    const purpose = file.purpose || 'unknown';
    if (!acc[purpose]) acc[purpose] = [];
    acc[purpose].push(file);
    return acc;
  }, {});

  if (!files.length) return null;

  return (
    <Box
      width="100%"
      bgcolor="background.default"
      mb={2}
      p={2}
      // boxShadow={theme.shadows[3]}
      borderRadius="5px"
    >
      {Object.entries(groupedFiles).map(([purpose, grouped]) => (
        <Box key={purpose} mb={6}>
          <Typography variant="body1" gutterBottom>
            {purposeOptions.find((option) => option.value === purpose)?.label ||
              purpose}
            :
          </Typography>
          {grouped.map((file, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              gap={2}
              sx={{ mb: '0.5rem' }}
            >
              {file.purpose === '3D_files' ? (
                <ViewInAr fontSize="large" />
              ) : (
                <DescriptionIcon fontSize="large" />
              )}

              <Box alignContent="center">
                <Typography variant="p" fontSize="12px" mx={1}>
                  {getFileName(file?.file_url)}
                </Typography>
              </Box>

              <Box sx={{ marginLeft: 'auto' }} alignContent="center">
                <IntuIconButton
                  size="small"
                  type="info"
                  iconOnly
                  tooltipTitle={transButtons('download')}
                  onClick={() =>
                    handleDownload(file.file_url, getFileName(file.file_url))
                  }
                  IconComponent={DownloadIcon}
                />
              </Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default PublicProductFiles;
