import { useContext, useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import useAuth from '../../hooks/useAuth';
import { Form, Formik } from 'formik';
import IndividualFirstNameField from '../../components/account/individual/IndividualFirstNameField';
import IndividualLastNameField from '../../components/account/individual/IndividualLastNameField';
import BusinessNameTextField from '../../components/account/business/BusinessNameTextField';
import EmailField from '../../components/account/fields/email/EmailField';
import PhoneField from '../../components/account/PhoneField ';
import MessageFieldRegular from '../../components/account/MessageFieldRegular';
import { useTranslation } from 'react-i18next';
import { useAxiosPrivateData } from '../../hooks/axios/useAxiosPrivate';
import * as Yup from 'yup';
import Transition from './Transition';
import UserContext from '../../context/users/UserInfoProvider';
import { useLocation, useNavigate } from 'react-router-dom';

// Blocked Domains
import blockedDomainList from '../../data/BlockedDomains.json';
import { trackActivity } from '../../api/tracker/trackerRoutes';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';
import ProcessingContext from '../../context/providers/ProcessingProvider';

// List of blocked burner email domains
let blockedDomains = [];
if (
  process.env.REACT_APP_ENV !== 'development' &&
  process.env.REACT_APP_ENV !== 'staging'
) {
  blockedDomains = blockedDomainList;
}

const createValidationSchema = (t, auth, customer_id) => {
  if (!auth || (!auth.user_info?.account_complete && !customer_id)) {
    if (process.env.REACT_APP_ENV === 'production') {
      blockedDomains.push([
        'yopmail.com',
        'mailinator.com',
        '10minutemail.com',
      ]);
    }

    return Yup.object().shape({
      first_name: Yup.string()
        .min(2, t('validation.short'))
        .max(50, t('validation.long'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
          t('validation.name.first_matches'),
        )
        .required(t('validation.name.first_required')),
      last_name: Yup.string()
        .min(2, t('validation.short'))
        .max(50, t('validation.long'))
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z]).{2,50}$/,
          t('validation.name.last_matches'),
        )
        .required(t('validation.name.last_required')),
      email: Yup.string()
        .required(t('validation.email.contact_required'))
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          t('validation.email.invalid'),
        )
        .test(
          'is-not-burner-email',
          t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
          (value) => {
            if (!value) return true; // Skip validation for empty values
            const domain = value.split('@')[1]?.toLowerCase();
            return !blockedDomains.includes(domain);
          },
        ),

      phone: Yup.object({
        value: Yup.string(),
        info: Yup.object({
          countryCallingCode: Yup.number(
            t('validation.phone.country_code.invalid'),
          ).typeError(t('validation.phone.country_code.error')),
          nationalNumber: Yup.string()
            .min(8, t('validation.phone.min'))
            .max(15, t('validation.phone.max')),
        }),
      }),
      captcha: Yup.string().required(t('validation.captcha_required')),
      message: Yup.string()
        .required(t('validation.message_required'))
        .min(8, t('validation.message_min')),
    });
  } else {
    return Yup.object().shape({
      message: Yup.string()
        .required(t('validation.message_required'))
        .min(8, t('validation.message_min')),
    });
  }
};

const MessageDialog = ({
  isOpen,
  handleClose,
  selectedRep,
  product,
  handleSetChats,
  referalID,
  productID,
}) => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');
  const { auth } = useAuth();
  const axios = useAxiosPrivateData();
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser, setSelectedChatId, setActiveChatIds, setIsChatDialogOpen } =
    useContext(UserContext);

  const { isLoading, setIsLoading } = useContext(ProcessingContext);

  const { mode } = useContext(IntuThemeContext);

  const formRef = useRef(null);
  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    message: '',
    phone: {
      value: '',
      info: {
        countryCallingCode: null,
        countryCode: null,
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    },
    ip: '',
    sessionID: '',
    captcha: '',
  };

  const validationSchema = createValidationSchema(
    t,
    auth,
    localStorage.getItem('intu_customer_id'),
  );

  const submitForm = async (values) => {
    setIsLoading({
      status: true,
      text: transMessages('chat.create'),
      type: 'skeleton',
    });
    try {
      // Customer already exists
      if (localStorage.getItem('intu_customer_id')) {
        const payload = {
          message: values.message,
          chat_title: `${product.product_name} inquiry`,
          id: selectedRep._id,
          sender_id: localStorage.getItem('intu_customer_id'),
        };

        // Create chat with sales rep
        const chatResponse = await axios.post(
          '/api/chat',
          JSON.stringify(payload),
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        );

        console.log('Create Chat Response 1 -> ', chatResponse);

        // Get conversations
        const chatsResponse = await axios.get(
          `/api/chat/customer/${localStorage.getItem('intu_customer_id')}`,
        );

        if (chatsResponse.status === 200) {
          const lastChat = chatsResponse.data.data.length - 1;
          handleSetChats([...chatsResponse.data.data]);
          setSelectedChatId(chatsResponse.data.data[lastChat].chat_id);
          setActiveChatIds((prevState) => {
            const set = new Set(prevState);
            set.add(chatsResponse.data.data[lastChat].chat_id);
            return set;
          });
          setIsChatDialogOpen(true);
        }

        // Track Referal
        if (referalID) {
          console.log('Track Referal on Inquiry -> ', referalID);
          const payload = {
            documentId: referalID,
            module: 'referral_campaigns',
            action: 'inquiry',
            tracker: true,
          };
          const trackReferralActivity = await trackActivity({ payload });

          console.log(
            'Track Referral Activity on Chat Creation -> ',
            trackReferralActivity,
          );
        }

        return;
      }

      // Customer does not exist
      const payload = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        company: values.company,
        phone: values.phone,
        host_url: window.location.host,
        account_type: 'customer',
      };

      // Create new customer user
      const createCustomerResponse = await axios.post(
        '/api/users/account/create/customer',
        JSON.stringify(payload),
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        },
      );

      console.log('Create Customer Response -> ', createCustomerResponse);

      if (
        createCustomerResponse.status === 200 ||
        createCustomerResponse.status === 240
      ) {
        const payload = {
          message: values.message,
          chat_title: `${product.product_name} inquiry`,
          id: selectedRep._id,
          sender_id: createCustomerResponse.data.data,
          host_url: window.location.host,
        };

        // Set Customer Info & Save Customer in local strage
        setUser((prevState) => {
          return {
            ...prevState,
            customer_id: createCustomerResponse.data.data,
          };
        });

        localStorage.setItem(
          'intu_customer_id',
          createCustomerResponse.data.data,
        );

        // Track Referal
        if (referalID) {
          console.log('Track Referal on Inquiry -> ', referalID);
          const payload = {
            documentId: referalID,
            module: 'referral_campaigns',
            action: 'inquiry',
            tracker: true,
          };
          const trackReferralActivity = await trackActivity({ payload });

          console.log(
            'Track Referral Activity on Chat Creation -> ',
            trackReferralActivity,
          );
        }

        // Track Product Activity
        const productAcivityPayload = {
          documentId: productID,
          module: 'products',
          action: 'inquiry',
          tracker: true,
        };
        const trackInquiry = await trackActivity({
          payload: productAcivityPayload,
        });

        console.log('Track Product Inquiry on Product Level -> ', trackInquiry);

        // Create chat with sales rep
        const createChatResponse = await axios.post(
          '/api/chat',
          JSON.stringify(payload),
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
          },
        );

        console.log('Get Chats Response -> ', createChatResponse);

        if (createChatResponse.status === 200) {
          // Get conversations
          const getChatsResponse = await axios.get(
            `/api/chat/customer/${createCustomerResponse.data.data}`,
          );

          console.log('Get Chats -> ', getChatsResponse);

          if (getChatsResponse.statusCode === 200) {
            const chatData = getChatsResponse.data.data;
            console.log('Chats -> ', chatData);
            const lastChat = chatData.length - 1;
            handleSetChats([...chatData]);
            setSelectedChatId(chatData[lastChat].chat_id);
            setActiveChatIds((prevState) => {
              const set = new Set(prevState);
              set.add(chatData[lastChat].chat_id);
              return set;
            });
            setIsChatDialogOpen(true);
          }
        }

        if (createCustomerResponse.status === 240) {
          navigate(createCustomerResponse.data.navigate_to, {
            replace: true,
            state: { from: location },
          });
        }
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsLoading({
        status: false,
        text: '',
        type: '',
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => submitForm(values)}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const { setFieldValue, handleSubmit, isValid, is } = formik;
        const name =
          selectedRep?.account_type === 'intutec'
            ? selectedRep.name
            : `${selectedRep?.first_name} ${selectedRep?.last_name}`;
        return (
          <Dialog
            open={isOpen}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            sx={{
              '& .MuiDialog-container': {
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              },
              '& .MuiDialog-paper': {
                margin: '16px',
                width: '100%',
              },
            }}
            hideBackdrop
          >
            <Form onSubmit={handleSubmit} role="form">
              <DialogTitle>Send a Message to {name}</DialogTitle>
              <DialogContent style={{ paddingTop: '20px' }}>
                <>
                  {!auth ||
                  (!auth.user_info?.account_complete &&
                    !localStorage.getItem('intu_customer_id')) ? (
                    <>
                      <Box my={2} display="flex" gap={2}>
                        <IndividualFirstNameField
                          required
                          fieldID="first_name"
                        />
                        <IndividualLastNameField required fieldID="last_name" />
                      </Box>
                      <BusinessNameTextField sx={{ my: 2 }} />
                      <EmailField transTypeID="your" required sx={{ my: 2 }} />
                      <PhoneField
                        fieldID="phone"
                        transTypeID="your"
                        required
                        sx={{ my: 2 }}
                      />
                    </>
                  ) : null}
                  <MessageFieldRegular sx={{ my: 2 }} required />
                  {!auth ||
                  (!auth.user_info?.account_complete &&
                    !localStorage.getItem('intu_customer_id')) ? (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                        onChange={(token) => {
                          setFieldValue('captcha', token);
                        }}
                        theme={mode}
                        onErrored={() => console.warn('recaptcha error')}
                      />
                    </Box>
                  ) : null}
                </>
              </DialogContent>
              <DialogActions
                style={{
                  paddingBottom: '16px',
                  justifyContent: 'space-evenly',
                }}
              >
                <Button
                  onClick={handleClose}
                  color="secondary"
                  variant="contained"
                  disabled={isLoading?.status}
                >
                  {transButtons('close')}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  disabled={!isValid || isLoading?.status}
                  variant="contained"
                >
                  {transButtons('send')}
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default MessageDialog;
