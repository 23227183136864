// Example Payload: {
//     "key": "_id",
//     "values": [ "64fb8de3708aa1293917d193" ]
// }

import axios from 'axios';
import { axiosPrivate } from '../hooks/axios/axios.js';
import { t } from 'i18next';

// Create Account
const createUserAccount = async (payload) => {
  if (!payload) {
    console.log('Cannot create user acocunt without Payload');
  }

  const url = `/api/users/account/create`;

  const controller = new AbortController();
  const { signal } = controller;

  try {
    const createUser = await axios.post(url, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      signal,
    });

    return createUser;
  } catch (error) {
    console.error('Error', error);
    return error;
  } finally {
    controller.abort();
  }
};

// Create Account
const inviteUser = async (payload) => {
  if (!payload) {
    console.log('Cannot create user acocunt without Payload');
  }

  const url = `/api/users/account/create/invite`;

  const controller = new AbortController();
  const { signal } = controller;

  try {
    const inviteUser = await axiosPrivate.post(url, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      signal,
    });

    return inviteUser;
  } catch (error) {
    console.error('Error', error);
    return error;
  } finally {
    controller.abort();
  }
};

// Search Users by payload
const searchUsers = async (payload) => {
  const controller = new AbortController();
  const signal = controller.signal;

  if (!payload) {
    throw new Error(t('routes.req_fields.payload'));
  }

  try {
    const url = '/api/users/search';
    const request = await axiosPrivate.post(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    let { data, status, statusCode, message } = request.data;

    if (request.status === 200) {
      // Update User Info
      return {
        status,
        statusCode,
        message,
        data,
      };
    } else {
      return {
        statusCode,
        status,
        data,
      };
    }
  } catch (err) {
    return err;
  }
};

const updateUser = async (payload) => {
  const controller = new AbortController();
  const signal = controller.signal;

  if (!payload) {
    throw new Error(t('routes.req_fields.payload'));
  }

  try {
    const url = '/api/users/account/update?userDashboard=false';
    const response = await axiosPrivate.put(url, JSON.stringify(payload), {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    const { data, status, statusCode, message } = response.data;

    if (response.status === 200) {
      // Update User Info
      return {
        status,
        statusCode,
        message,
        data,
      };
    } else {
      return {
        statusCode,
        status,
        data,
      };
    }
  } catch (err) {
    console.error('error', err);
  }
};

async function getSalesReps() {
  try {
    const { data, status } = await axiosPrivate.get('/api/users/sales-reps');
    if (status === 200) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error('error', error);
  }
}

export { createUserAccount, searchUsers, updateUser, getSalesReps, inviteUser };
