import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UnitSelectionField from '../../fields/unit/UnitSelectionField';
import {
  measurementOptions,
  weightOptions,
} from '../../../data/MeasruementUnits.js';
import { Formik } from 'formik';
import { useRef } from 'react';

export const convertValue = (value, fromUnit, toUnit) => {
  const conversionTable = {
    in: { in: 1, ft: 1 / 12, mm: 25.4, cm: 2.54, m: 0.0254 },
    ft: { in: 12, ft: 1, mm: 304.8, cm: 30.48, m: 0.3048 },
    mm: { in: 1 / 25.4, ft: 1 / 304.8, mm: 1, cm: 0.1, m: 0.001 },
    cm: { in: 1 / 2.54, ft: 1 / 30.48, mm: 10, cm: 1, m: 0.01 },
    m: { in: 1 / 0.0254, ft: 1 / 0.3048, mm: 1000, cm: 100, m: 1 },
  };

  return value * conversionTable[fromUnit][toUnit];
};

export const convertWeight = (value, fromUnit, toUnit) => {
  const conversionTable = {
    oz: { oz: 1, lb: 1 / 16, g: 28.3495, kg: 0.0283495 },
    lb: { oz: 16, lb: 1, g: 453.592, kg: 0.453592 },
    g: { oz: 1 / 28.3495, lb: 1 / 453.592, g: 1, kg: 0.001 },
    kg: { oz: 1 / 0.0283495, lb: 1 / 0.453592, g: 1000, kg: 1 },
  };

  if (!conversionTable[fromUnit] || !conversionTable[fromUnit][toUnit]) {
    console.log(`Unsupported weight conversion: ${fromUnit} to ${toUnit}`);
    return value;
  }

  return value * conversionTable[fromUnit][toUnit];
};

const ProductShippingContainer = ({
  dimensions,
  weight,
  displayTitle,
  countryOfOrigin,
  tarifCode,
}) => {
  const { t } = useTranslation('container', {
    keyPrefix: 'ProductShippingContainer',
  });
  const { t: transTypes } = useTranslation('types');

  console.log('Packaging Values -> ', dimensions);
  console.log('Weight Values -> ', weight);

  const initialValues = {
    dimensions: {
      unit: dimensions.unit,
      length: dimensions.length,
      width: dimensions.width,
      height: dimensions.height,
    },
    weight: {
      unit: weight.unit,
      value: weight.weight,
    },
  };

  const formikRef = useRef(null);

  const handleDimensionUnitChange = (newUnit) => {
    const { values, setFieldValue } = formikRef.current;
    const oldUnit = values.dimensions.unit;

    if (newUnit !== oldUnit) {
      const convertedLength = convertValue(
        values.dimensions.length,
        oldUnit,
        newUnit,
      );
      const convertedWidth = convertValue(
        values.dimensions.width,
        oldUnit,
        newUnit,
      );
      const convertedHeight = convertValue(
        values.dimensions.height,
        oldUnit,
        newUnit,
      );

      setFieldValue('dimensions.unit', newUnit);
      setFieldValue('dimensions.length', +convertedLength.toFixed(2));
      setFieldValue('dimensions.width', +convertedWidth.toFixed(2));
      setFieldValue('dimensions.height', +convertedHeight.toFixed(2));
    }
  };

  const handleWeightUnitChange = (newUnit) => {
    const { values, setFieldValue } = formikRef.current;

    console.log('triggered unit conversion for new weight unit -> ', newUnit);

    const oldUnit = values.weight.unit;

    if (newUnit !== oldUnit) {
      const convertedWeight = convertWeight(
        values.weight.value,
        oldUnit,
        newUnit,
      );

      setFieldValue('weight.unit', newUnit);
      setFieldValue('weight.value', +convertedWeight.toFixed(2));
    }
  };

  return (
    <Formik initialValues={initialValues} innerRef={formikRef}>
      {(formik) => {
        const { values } = formik;

        console.log('Values -> ', values);
        return (
          <Box display="flex" flexDirection="column" my={4}>
            {displayTitle && (
              <Typography variant="h6">{t('packaging.title')}</Typography>
            )}

            {/* Packaging Dimensions */}
            {values?.dimensions?.unit && (
              <Box display="flex" flexDirection="column" gap={3} width="100%">
                {/* Title */}
                <Box width={{ xs: '100%', md: '50%' }}>
                  <Typography variant="body">{t('packaging.title')}</Typography>
                  <Divider sx={{ mt: 1 }} />
                </Box>

                <Box
                  width={{ xs: '100%', md: '50%' }}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={3}
                    width="100%"
                    sx={{ mb: 3 }}
                  >
                    <Box>
                      <UnitSelectionField
                        required
                        fieldID="dimensions.unit"
                        options={measurementOptions}
                        disableClearable={true}
                        transTypeID={transTypes('measurement')}
                        onChange={(newValue) =>
                          handleDimensionUnitChange(newValue)
                        }
                      />
                    </Box>
                    <Box>
                      <Typography>
                        <strong>{t('packaging.fields.length')}:</strong>{' '}
                        {`${values.dimensions.length} ${values.dimensions.unit}`}
                      </Typography>

                      <Typography>
                        <strong>{t('packaging.fields.width')}:</strong>{' '}
                        {`${values.dimensions.width} ${values.dimensions.unit}`}
                      </Typography>

                      <Typography>
                        <strong>{t('packaging.fields.height')}:</strong>{' '}
                        {`${values.dimensions.height} ${values.dimensions.unit}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {/* Weight */}
            {values?.weight?.unit && (
              <Box
                display="flex"
                flexDirection="column"
                gap={3}
                width="100%"
                sx={{ mb: 4 }}
              >
                {/* Title */}
                <Box width={{ xs: '100%', md: '50%' }}>
                  <Typography variant="body">{t('weight.title')}</Typography>
                  <Divider sx={{ mt: 1 }} />
                </Box>

                <Box
                  width={{ xs: '100%', md: '50%' }}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={3}
                    width="100%"
                  >
                    <Box>
                      <UnitSelectionField
                        required
                        fieldID="weight.unit"
                        options={weightOptions}
                        transTypeID={transTypes('weight')}
                        onChange={(newValue) =>
                          handleWeightUnitChange(newValue)
                        }
                      />
                    </Box>
                    <Box>
                      <Typography>
                        <strong>{t('packaging.fields.weight')}:</strong>{' '}
                        {`${values.weight.value} ${values.weight.unit}`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {/* Country of Origin */}
            {countryOfOrigin?.label && (
              <Box
                display="flex"
                flexDirection="column"
                gap={3}
                width="100%"
                sx={{ mb: 4 }}
              >
                {/* Title */}
                <Box width={{ xs: '100%', md: '50%' }}>
                  <Typography variant="body">
                    {t('countryOfOrigin.title')}
                  </Typography>
                  <Divider sx={{ mt: 1 }} />
                </Box>

                <Box
                  width={{ xs: '100%', md: '50%' }}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={3}
                    width="100%"
                  >
                    <Box>
                      <Typography>{countryOfOrigin.label}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {/* Customs tarif Code */}
            {tarifCode && (
              <Box
                display="flex"
                flexDirection="column"
                gap={3}
                width="100%"
                sx={{ mb: 4 }}
              >
                {/* Title */}
                <Box width={{ xs: '100%', md: '50%' }}>
                  <Typography variant="body">{t('tarifCode.title')}</Typography>
                  <Divider sx={{ mt: 1 }} />
                </Box>

                <Box
                  width={{ xs: '100%', md: '50%' }}
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={3}
                    width="100%"
                  >
                    <Box>
                      <Typography>{tarifCode}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        );
      }}
    </Formik>
  );
};

export default ProductShippingContainer;
