import * as Yup from 'yup';
import i18next from 'i18next';
import blockedDomainList from '../../data/BlockedDomains.json';

// List of blocked burner email domains
let blockedDomains = [];
if (
  process.env.REACT_APP_ENV !== 'development' &&
  process.env.REACT_APP_ENV !== 'staging'
) {
  blockedDomains = blockedDomainList;
}

export const createCompanyProfileValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object().shape({
    name: Yup.string()
      .min(5, t('short', { type: transTypes('name'), char: 5 }))
      .required(t('company.name_required_alt')),
    address: Yup.object({
      line1: Yup.string()
        .min(5, t('short', { type: transTypes('address'), char: 5 }))
        .required(t('address.line1_required')),
      city: Yup.string()
        .min(3, t('short', { type: transTypes('city'), char: 3 }))
        .required(t('address.city_required')),
      state: Yup.string()
        .min(2, t('short', { type: transTypes('state'), char: 2 }))
        .required(t('address.state_required')),
      postcode: Yup.string()
        .min(3, t('short', { type: transTypes('postcode'), char: 3 }))
        .required(t('address.postcode_required')),
      country: Yup.object({
        label: Yup.string().required(t('country.required')),
        dialCode: Yup.number(t('country.dial_code.invalid'))
          .required(t('country.dial_code.required'))
          .typeError(t('country.dial_code.error')),
        code: Yup.string()
          .required(t('country.code.required'))
          .min(2, t('country.code.min')),
      }),
    }),
    phone: Yup.object({
      value: Yup.string().required(t('company.phone_required')),
      info: Yup.object({
        countryCallingCode: Yup.number(t('phone.country_code.invalid'))
          .required(t('phone.country_code.required'))
          .typeError(t('phone.country_code.error')),
        nationalNumber: Yup.string()
          .required(t('phone.required'))
          .min(8, t('phone.min'))
          .max(15, t('phone.max')),
      }),
    }),
    email: Yup.string()
      .required(t('email.user_required'))
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        t('email.invalid'),
      )
      .test(
        'is-not-burner-email',
        t('email.burner_blocked'), // Example: 'Burner email addresses are not allowed'
        (value) => {
          if (!value) return true; // Skip validation for empty values
          const domain = value.split('@')[1]?.toLowerCase();
          return !blockedDomains.includes(domain);
        },
      ),
    website: Yup.string()
      .min(3, t('short', { type: transTypes('website'), char: 3 }))
      .required(t('website_required'))
      .matches(
        /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-./?%&=]*)?$/,
        t('website_invalid'),
      ),
    employees: Yup.array().min(1, t('employee_min')),
    tax_id: Yup.string().required(t('company.taxid_required')),
    type: Yup.string().required(t('company.type_required')),
    brands: Yup.array().when('account_type', {
      is: (type) => type === 'manufacturer',
      then: () => Yup.array().min(1, t('brand_min')),
    }),
    applications: Yup.array().when('type', {
      is: (type) => type !== 'marketplace',
      then: () => Yup.array().min(1, t('industry_name_required')),
    }),
    product_types: Yup.array().when('type', {
      is: (type) => type !== 'marketplace',
      then: () => Yup.array().min(1, t('product_min')),
    }),
    intro: Yup.object().shape({
      blocks: Yup.array()
        .of(
          Yup.object().shape({
            text: Yup.string().test(
              'is-not-empty-string',
              t('intro.text_required'),
              (value) => {
                return value !== '' || value === null;
              },
            ),
          }),
        )
        .min(2, t('intro.text_min'))
        .required(t('intro.text_required')),
    }),
  });
};
