import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TermsOfUseContent } from '../terms/TermsOfUseContent';
import { useTranslation } from 'react-i18next';
import IntuIconButton from '../buttons/IntuIconButton';

const TermsOfServiceDialog = ({ isOpen, handleClose }) => {
  const { t: transButtons } = useTranslation('buttons');
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogContent>
        <TermsOfUseContent />
      </DialogContent>
      <DialogActions>
        <IntuIconButton
          size="small"
          variant="outlined"
          tooltipTitle={transButtons('close')}
          onClick={() => handleClose()}
          IconComponent={CloseIcon}
        />
      </DialogActions>
    </Dialog>
  );
};

const TermsOfServiceBox = ({ areTermsAccepted, setAreTermsAccepted }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCheckboxChange = () => {
    setAreTermsAccepted(!areTermsAccepted);
  };

  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={areTermsAccepted}
            onChange={handleCheckboxChange}
          />
        }
        label={
          <Typography variant="body2">
            I have read and agree to the website{' '}
            <Link component="button" variant="body2" onClick={handleClickOpen}>
              terms and conditions
            </Link>
          </Typography>
        }
      />
      <TermsOfServiceDialog isOpen={isOpen} handleClose={() => handleClose()} />
    </>
  );
};

export default TermsOfServiceBox;
