import { useContext, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography,
  Grid,
  Stack,
  Skeleton,
  Fade,
} from '@mui/material';
import { classes } from '../../settings/theme.js';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import useResponseHandling from '../../hooks/useResponseHandler.js';
import countryList from '../../data/CountryData.json';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import MapLocationByAddressProvider, {
  MapLocationByAddressContext,
} from '../../context/providers/maps/MapLocationByAddressProvider.jsx';
import { MapLocation } from '../maps/MapLocation.jsx';
import useUser from '../../hooks/useUser.js';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate.js';
import { getUserPhoneDetails } from '../../helpers/getUserPhoneDetails.js';
import { getNestedValue } from '../../helpers/getNestedValuesIn.js';
import ProcessingContext from '../../context/providers/ProcessingProvider.jsx';
import IntuThemeContext from '../../context/providers/IntuThemeProvider.jsx';
import { v4 as uuid } from 'uuid';

const AddressFieldContent = ({
  fieldTitle, // Field Title
  fieldDescription, // Field Description
  fieldID = 'address', //Determines the field ID and Name
  labelID = 'Your Address', //source of the Field which keeps the Value for the Map Label Name
  phoneFieldID = 'company.phone', // Takes a string value of the field if which holds the phone number or false for disabled
  autocompleteID = 'address', //Determines the Autocomplete Values
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'AddressField', // KeyPrefix which holds label and placeholder
  transition = true, // Whether the Field transitions fade in, fade out
  disabled = false, // Whether the field is disabled
  required = false, // Whether the Field is required or not
  variant = 'outlined', // Design standard or
  showLocationMap = false, // Whehter or not to show the locations map
  disableZoom = false, // Disables scroll to zoom on map
  isLoadingManual = false, // Manually control he loading state
  showInitialErrors = false, // Show Errors even when the field has not been touched
  onBlur, //Add to onBlur Beharios
  countryOptions = countryList, // Country Data
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  //SetUp Axios
  const axios = useAxiosPrivate();

  // SetUp Context
  const { user } = useUser();
  const { setMapAddress, getUpdatedCoordinates } = useContext(
    MapLocationByAddressContext,
  );
  const {
    values,
    setFieldTouched,
    setValues,
    handleBlur,
    setFieldValue,
    errors,
    touched,
    setTouched,
  } = useFormikContext();

  const fieldValue = getNestedValue(values, fieldID);
  const autocompleteValue = autocompleteID
    ? getNestedValue(values, autocompleteID)
    : {};
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  // Response Handling
  const { handleErrorResponse } = useResponseHandling();

  const { isLoading } = useContext(ProcessingContext);

  const { transitionInterval } = useContext(IntuThemeContext);

  // SetUp States
  const [isLoadingAddress, setIsLoadingAddress] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressSearch, setAddressSearch] = useState({
    line1: '',
    line2: '',
    city: '',
    state: '',
    postcode: '',
    country: {
      label: user.location.countryLabel,
      dialCode: user.location.dialCode,
      code: user.location.country,
    },
    coordinates: { latitude: '', longitude: '' },
  });
  const [addressSearchTool, setAddressSearchTool] = useState(true);

  const [autofillObserved, setAutofillObserved] = useState(false);
  const autofillCheckInterval = useRef(null);

  // Get Coordinates
  const handleFetCoordinates = async (address) => {
    const coordinates = await getUpdatedCoordinates(address);
    if (coordinates.status === 'success') {
      setFieldValue(`${fieldID}['coordinates']`, {
        longitude: coordinates.location.lng,
        latitude: coordinates.location.lat,
      });
    }
  };

  useEffect(() => {
    if (autofillObserved || !showLocationMap) return;

    autofillCheckInterval.current = setInterval(() => {
      const street = values[fieldID]?.line1;
      const city = values[fieldID]?.city;
      const state = values[fieldID]?.state;
      const postcode = values[fieldID]?.postcode;
      const countryLabel = values[fieldID]?.country?.label;

      const allPresent = street && city && state && postcode && countryLabel;

      if (allPresent) {
        const newAddress = {
          name: `${labelID}<br/>${street}<br/>${city}, ${state} ${postcode}<br/>${countryLabel}`,
          street,
          city,
          postcode,
          state,
          country: countryLabel,
        };

        setMapAddress(newAddress);
        handleFetCoordinates(newAddress);

        setAutofillObserved(true);
        clearInterval(autofillCheckInterval.current);
      }
    }, 300); // Poll every 300ms

    return () => {
      if (autofillCheckInterval.current)
        clearInterval(autofillCheckInterval.current);
    };
  }, [autofillObserved, values]);

  // SertUp Refs
  const addressSearchRef = useRef();

  const uniqueId = uuid();

  return isLoading.status || isLoadingManual ? (
    <Stack spacing={4} style={classes.root}>
      {/* Line 1 */}
      <Skeleton variant="rectangular" width={'100%'} height={60} />
      {/* Line 2 */}
      <Skeleton variant="rectangular" width={'100%'} height={60} />
      <Stack
        spacing={1}
        direction={{ xs: 'column', sm: 'row' }}
        style={classes.root}
      >
        {/* City  */}
        <Skeleton variant="rectangular" width={'100%'} height={60} />
        {/* State */}
        <Skeleton variant="rectangular" width={'100%'} height={60} />
        {/* Postal Code */}
        <Skeleton variant="rectangular" width={'100%'} height={60} />
      </Stack>
      {/* Country */}
      <Skeleton variant="rectangular" width={'100%'} height={60} />
    </Stack>
  ) : (
    <>
      {fieldTitle && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography
            variant="h4"
            textTransform="none"
            textAlign={{ xs: 'center', md: 'left' }}
          >
            {fieldTitle}
          </Typography>
        </Fade>
      )}
      {fieldDescription && (
        <Fade in={transition} timeout={transitionInterval}>
          <Typography
            className="form-note"
            textAlign={{ xs: 'center', md: 'left' }}
            sx={{ marginBottom: '1rem' }}
          >
            {fieldDescription}
          </Typography>
        </Fade>
      )}
      {transition && (
        <Stack spacing={{ xs: 2, md: 4 }} style={classes.root}>
          {/* Line 1 */}
          <Fade in={transition} timeout={transitionInterval}>
            <Autocomplete
              freeSolo
              id={autocompleteID}
              name={autocompleteID}
              disabled={disabled}
              autoComplete={false}
              includeInputInList
              filterSelectedOptions
              disableClearable={true}
              loading={isLoadingAddress}
              loadingText={t('search.loading')}
              className="form-select-field"
              value={autocompleteValue || ''}
              options={addressOptions}
              getOptionLabel={(option) =>
                option?.label
                  ? option.label
                  : fieldValue?.line1
                    ? fieldValue.line1
                    : ''
              }
              filterOptions={(x) => x}
              noOptionsText={t('search.no_option')}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={props.key}>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        sx={{
                          display: 'flex',
                          width: 44,
                        }}
                      >
                        <LocationOnIcon
                          sx={{
                            color: 'text.secondary',
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: 'calc(100% - 44px)',
                          wordWrap: 'break-word',
                        }}
                      >
                        <Box key={option.id} component="span">
                          {option.label}
                        </Box>
                        <Typography variant="body2" color="text.secondary">
                          {t('distance.label')} {option.distance_formatted}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
              onChange={async (e, option, reason) => {
                if (reason === 'selectOption') {
                  setIsLoadingAddress(true);
                  let id = option.id;
                  let sessionID = user.sessionID || uniqueId;
                  let url = '/api/mapbox/retrieve-address-public';

                  const controller = new AbortController();
                  const signal = controller.signal;

                  const payload = { id, sessionID };
                  try {
                    const request = await axios.post(
                      url,
                      JSON.stringify(payload),
                      {
                        signal,
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        withCredentials: true,
                      },
                    );

                    const response = request?.data;

                    if (request.status === 200) {
                      // Set Address Fields
                      let data = response.data;

                      // Coordinates
                      setFieldValue(
                        `${fieldID}['coordinates']`,
                        data?.coordinates,
                      );

                      setTimeout(
                        () => setFieldTouched(`${fieldID}['coordinates']`),
                        100,
                      );

                      // Country
                      const [countryDetails] = countryOptions.filter(
                        (country) => country.code === data.countryCode,
                      );
                      const country = {
                        label: data.country,
                        dialCode: countryDetails.dialCode,
                        code: countryDetails.code,
                      };
                      setFieldValue(`${fieldID}['country']`, country);
                      setTimeout(
                        () => setFieldTouched(`${fieldID}['country']`),
                        100,
                      );

                      // Set User Phone Details based on Country
                      if (phoneFieldID) {
                        const isFieldTouched = phoneFieldID
                          .split('.')
                          .reduce((acc, key) => acc && acc[key], touched);
                        if (!isFieldTouched) {
                          const phoneDetails = getUserPhoneDetails(
                            countryDetails.code,
                          );
                          setFieldValue(phoneFieldID, phoneDetails);
                          setTimeout(
                            () => setFieldTouched(phoneFieldID, true),
                            100,
                          );
                        }
                      }

                      // state
                      setFieldValue(`${fieldID}['state']`, data?.region || '');
                      setTimeout(
                        () => setFieldTouched(`${fieldID}['state']`),
                        100,
                      );

                      // City
                      setFieldValue(`${fieldID}['city']`, data?.place || '');
                      setTimeout(
                        () => setFieldTouched(`${fieldID}['city']`),
                        100,
                      );

                      // Postcode
                      setFieldValue(
                        `${fieldID}['postcode']`,
                        data?.postcode || '',
                      );
                      setTimeout(
                        () => setFieldTouched(`${fieldID}['postcode']`),
                        100,
                      );

                      // Address
                      if (data?.address) {
                        setFieldValue(
                          `${fieldID}['line1']`,
                          data?.address || '',
                        );
                      } else {
                        setFieldValue(
                          `${fieldID}['line1']`,
                          fieldID?.line1 || '',
                        );
                      }

                      setTimeout(() => {
                        setFieldTouched(`${fieldID}['line1']`);
                        const newAddress = {
                          name: `${labelID}<br/>${data?.address}<br/>${data?.city}, ${data?.region} ${data?.postcode}<br/>${country}`,
                          street: data?.address,
                          city: data?.city,
                          postcode: data?.postcode,
                          state: data?.region,
                          country: country,
                        };

                        setMapAddress(newAddress);
                      }, 500);
                    } else {
                      setFieldValue(`${fieldID}['line1']`, option);
                    }
                  } catch (err) {
                    handleErrorResponse(err);
                  } finally {
                    setIsLoadingAddress(false);
                  }
                } else {
                  // User hit return key

                  // Set Country
                  const [countryDetails] = countryOptions.filter(
                    (country) => country.code === user?.location.country,
                  );

                  setValues({
                    ...values,
                    address: {
                      line1: addressSearch,
                      city: user?.location?.city,
                      postcode: user?.location?.postcode,
                      state: user?.location?.state,
                      country: {
                        label: countryDetails.label,
                        dialCode: countryDetails.dialCode,
                        code: user?.location?.country,
                      },
                    },
                  });

                  setTimeout(
                    () =>
                      setTouched({
                        ...touched,
                        address: true,
                      }),
                    100,
                  );
                }
              }}
              onBlur={(e) => {
                const newAddress = {
                  name: `${labelID}<br/>${fieldValue?.line1}<br/>${fieldValue?.city}, ${fieldValue?.state} ${fieldValue?.postcode}<br/>${fieldValue?.country?.label}`,
                  street: fieldValue?.line1,
                  postcode: fieldValue?.postcode,
                  city: fieldValue?.city,
                  state: fieldValue?.state,
                  country: fieldValue?.country?.label,
                };

                setMapAddress(newAddress);
                setAddressOptions([]);

                // Handle On Blur
                if (onBlur) {
                  onBlur(e);
                } else {
                  handleBlur(e);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`${fieldID}.line1`}
                  name={`${fieldID}.line1`}
                  required={required}
                  autoComplete="address-line1"
                  inputRef={addressSearchRef}
                  onKeyDown={(e) => {
                    if (e.key === 'Unidentified') {
                      setAddressSearchTool(false);
                    } else {
                      setAddressSearchTool(true);
                    }
                  }}
                  onChange={async (e) => {
                    let input = e.target.value;
                    setAddressSearch(input);
                    setFieldValue(`${fieldID}['line1']`, input);

                    if (addressSearchTool === true && input.length > 5) {
                      setIsLoadingAddress(true);
                      let addressSearchFunction = setTimeout(async () => {
                        let searchTextEncoded = encodeURIComponent(input);
                        let position = user.location;
                        let sessionID = user.sessionID || uniqueId;
                        let url = '/api/mapbox/suggest-address-public';

                        const controller = new AbortController();
                        const signal = controller.signal;

                        const payload = {
                          searchText: searchTextEncoded,
                          position,
                          sessionID,
                        };

                        try {
                          const { data, status } = await axios.post(
                            url,
                            JSON.stringify(payload),
                            {
                              signal,
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              withCredentials: true,
                            },
                          );

                          if (status === 200) {
                            setAddressOptions(data);
                          }
                        } catch (error) {
                          console.error('error', error);
                          handleErrorResponse(error);
                        } finally {
                          setIsLoadingAddress(false);
                          clearTimeout(addressSearchFunction);
                        }
                      }, 100);
                    } else {
                      setTimeout(() => {
                        if (onBlur) {
                          onBlur(e);
                        } else {
                          handleBlur(e);
                        }
                      }, 500);
                    }
                  }}
                  variant={variant}
                  label={!disabled ? t('line1.label') : null}
                  placeholder={!disabled ? t('line1.placeholder') : null}
                  InputProps={{
                    ...params.InputProps,
                    type: 'text',
                    endAdornment: (
                      <>
                        {isLoadingAddress ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  error={
                    showInitialErrors
                      ? fieldError &&
                        fieldError['line1'] &&
                        fieldTouched['line1']
                        ? true
                        : false
                      : fieldError &&
                          fieldTouched &&
                          fieldError['line1'] &&
                          fieldTouched['line1']
                        ? true
                        : false
                  }
                  helperText={
                    fieldError?.line1 && fieldTouched?.line1
                      ? fieldError?.line1
                      : null
                  }
                />
              )}
            />
          </Fade>

          {/* Line 2 */}
          <Fade in={transition} timeout={transitionInterval}>
            <TextField
              id={`${fieldID}.line2`}
              name={`${fieldID}.line2`}
              className="form-select-field"
              disabled={disabled}
              variant={variant}
              label={!disabled ? t('line2.label') : null}
              placeholder={!disabled ? t('line2.placeholder') : null}
              type="text"
              value={fieldValue?.line2 || ''}
              onChange={(e) => {
                setFieldValue(`${fieldID}['line2']`, e.target.value);
              }}
              onBlur={(e) => {
                if (onBlur) {
                  onBlur(e);
                } else {
                  handleBlur(e);
                }
              }}
            />
          </Fade>
          <Stack
            spacing={{ xs: 2, md: 1 }}
            direction={{ xs: 'column', sm: 'row' }}
            style={classes.root}
          >
            {/* City  */}
            <Fade in={transition} timeout={transitionInterval}>
              <TextField
                required={required}
                id={`${fieldID}.city`}
                name={`${fieldID}.city`}
                autoComplete="off"
                disabled={disabled}
                style={classes.root}
                className="form-select-field"
                variant={variant}
                type="text"
                label={!disabled ? t('city.label') : null}
                placeholder={!disabled ? t('city.placeholder') : null}
                onChange={(e) => {
                  setFieldValue(`${fieldID}['city']`, e.target.value);
                }}
                onBlur={async (e) => {
                  setFieldTouched(`${fieldID}['city']`, true, true);

                  // Map New Address

                  const newAddress = {
                    name: `${labelID}<br/>${fieldValue?.line1}<br/>${fieldValue?.city}, ${fieldValue?.state} ${fieldValue?.postcode}<br/>${fieldValue?.country?.label}`,
                    street: fieldValue?.line1,
                    postcode: fieldValue?.postcode,
                    state: fieldValue?.state,
                    country: fieldValue?.country?.label,
                  };
                  if (
                    newAddress.postcode &&
                    newAddress.state &&
                    newAddress.country
                  ) {
                    setMapAddress(newAddress);

                    // Update Coordinates
                    const coordinates = await getUpdatedCoordinates(newAddress);
                    if (coordinates.status === 'success') {
                      setFieldValue(`${fieldID}['coordinates']`, {
                        longitude: coordinates.location.lng,
                        latitude: coordinates.location.lat,
                      });
                    }
                  }

                  // Handle On Blur
                  if (onBlur) {
                    onBlur(e);
                  } else {
                    handleBlur(e);
                  }
                }}
                value={fieldValue?.city || ''}
                error={
                  showInitialErrors
                    ? fieldError && fieldError['city'] && fieldTouched['city']
                      ? true
                      : false
                    : fieldError &&
                        fieldTouched &&
                        fieldError['city'] &&
                        fieldTouched['city']
                      ? true
                      : false
                }
                helperText={
                  fieldError?.city && fieldTouched?.city
                    ? fieldError.city
                    : null
                }
              />
            </Fade>
            {/* State */}
            <Fade in={transition} timeout={transitionInterval}>
              <TextField
                required={required}
                autoComplete="off"
                disabled={disabled}
                id={`${fieldID}.state`}
                name={`${fieldID}.state`}
                className="form-select-field"
                variant={variant}
                label={!disabled ? t('state.label') : null}
                placeholder={!disabled ? t('state.placeholder') : null}
                type="text"
                onChange={(e) => {
                  setFieldValue(`${fieldID}['state']`, e.target.value);
                }}
                onBlur={async (e) => {
                  setFieldTouched(`${fieldID}['state']`, true, true);

                  // Map New Address
                  const newAddress = {
                    name: `${labelID}<br/>${fieldValue?.line1}<br/>${fieldValue?.city}, ${fieldValue?.state} ${fieldValue?.postcode}<br/>${fieldValue?.country?.label}`,
                    street: fieldValue?.line1,
                    postcode: fieldValue?.postcode,
                    state: fieldValue?.state,
                    country: fieldValue?.country?.label,
                  };
                  if (
                    newAddress.street &&
                    newAddress.postcode &&
                    newAddress.state &&
                    newAddress.country
                  ) {
                    setMapAddress(newAddress);

                    // Update Coordinates
                    const coordinates = await getUpdatedCoordinates(newAddress);
                    if (coordinates.status === 'success') {
                      setFieldValue(`${fieldID}['coordinates']`, {
                        longitude: coordinates.location.lng,
                        latitude: coordinates.location.lat,
                      });
                    }
                  }

                  // Handle On Blur
                  if (onBlur) {
                    onBlur(e);
                  }
                  handleBlur(e);
                }}
                value={
                  fieldValue?.country?.code === 'US'
                    ? (fieldValue?.state && fieldValue?.state.toUpperCase()) ||
                      ''
                    : fieldValue?.state || ''
                }
                error={
                  showInitialErrors
                    ? fieldError && fieldError['state'] && fieldTouched['state']
                      ? true
                      : false
                    : fieldError &&
                        fieldTouched &&
                        fieldError['state'] &&
                        fieldTouched['state']
                      ? true
                      : false
                }
                helperText={
                  fieldError?.state && fieldTouched?.state
                    ? fieldError.state
                    : null
                }
              />
            </Fade>
            {/* Postal Code */}
            <Fade in={transition} timeout={transitionInterval}>
              <TextField
                required={required}
                disabled={disabled}
                autoComplete="off"
                id={`${fieldID}.postcode`}
                name={`${fieldID}.postcode`}
                className="form-select-field"
                variant={variant}
                label={!disabled ? t('postcode.label') : null}
                placeholder={!disabled ? t('postcode.placeholder') : null}
                type="text"
                onChange={(e) => {
                  setFieldValue(`${fieldID}['postcode']`, e.target.value);
                }}
                onBlur={async (e) => {
                  setFieldTouched(`${fieldID}['postcode']`, true, true);

                  // Map New Address
                  const newAddress = {
                    name: `${labelID}<br/>${fieldValue?.line1}<br/>${fieldValue?.city}, ${fieldValue?.state} ${fieldValue?.postcode}<br/>${fieldValue?.country?.label}`,
                    street: fieldValue?.line1,
                    postcode: fieldValue?.postcode,
                    state: fieldValue?.state,
                    country: fieldValue?.country?.label,
                  };
                  if (
                    newAddress.street &&
                    newAddress.postcode &&
                    newAddress.state &&
                    newAddress.country
                  ) {
                    setMapAddress(newAddress);
                    // Update Coordinates
                    const coordinates = await getUpdatedCoordinates(newAddress);
                    if (coordinates.status === 'success') {
                      setFieldValue(`${fieldID}['coordinates']`, {
                        longitude: coordinates.location.lng,
                        latitude: coordinates.location.lat,
                      });
                    }
                  }

                  // Handle On Blur
                  if (onBlur) {
                    onBlur(e);
                  } else {
                    handleBlur(e);
                  }
                }}
                value={fieldValue?.postcode || ''}
                error={
                  showInitialErrors
                    ? fieldError &&
                      fieldError['postcode'] &&
                      fieldTouched['postcode']
                      ? true
                      : false
                    : fieldError &&
                        fieldTouched &&
                        fieldError['postcode'] &&
                        fieldTouched['postcode']
                      ? true
                      : false
                }
                helperText={
                  fieldError?.postcode && fieldTouched?.postcode
                    ? fieldError.postcode
                    : null
                }
              />
            </Fade>
          </Stack>
          {/* Country */}
          <Fade in={transition} timeout={transitionInterval}>
            <Autocomplete
              required={required}
              disabled={disabled}
              autoComplete={false}
              id={`${autocompleteValue}.country`}
              name={`${autocompleteValue}.country`}
              autoSelect={true}
              disableClearable
              options={countryOptions}
              getOptionLabel={(option) => option?.label || ''}
              isOptionEqualToValue={(option, value) =>
                option?.code === value?.code
              }
              onChange={async (e, option) => {
                setFieldValue(`${fieldID}['country']`, option);
              }}
              onBlur={async (e) => {
                setFieldTouched(`${fieldID}['country']`, true, true);

                // Map New Address
                const newAddress = {
                  name: `${labelID}<br/>${fieldValue?.line1}<br/>${fieldValue?.city}, ${fieldValue?.state} ${fieldValue?.postcode}<br/>${fieldValue?.country?.label}`,
                  street: fieldValue?.line1,
                  postcode: fieldValue?.postcode,
                  state: fieldValue?.state,
                  country: fieldValue?.country?.label,
                };

                if (
                  newAddress.street &&
                  newAddress.postcode &&
                  newAddress.state &&
                  newAddress.country
                ) {
                  setMapAddress(newAddress);

                  // Update Coordinates
                  const coordinates = await getUpdatedCoordinates(newAddress);
                  if (coordinates.status === 'success') {
                    setFieldValue(`${fieldID}['coordinates']`, {
                      longitude: coordinates.location.lng,
                      latitude: coordinates.location.lat,
                    });
                  }
                }

                // Handle On Blur
                if (onBlur) {
                  onBlur(e);
                } else {
                  handleBlur(e);
                }
              }}
              defaultValue={countryOptions[231]}
              value={fieldValue?.country || null}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    '& > img': { mr: 2, flexShrink: 0 },
                  }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option?.label} ({option?.dialCode}) ({option?.code})
                </Box>
              )}
              className="form-select-field"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required={required}
                  variant={variant}
                  label={!disabled ? t('country.label') : null}
                  placeholder={!disabled ? t('country.placeholder') : null}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  error={
                    showInitialErrors
                      ? fieldError &&
                        fieldError['country'] &&
                        fieldTouched['country']
                        ? true
                        : false
                      : fieldError &&
                          fieldTouched &&
                          fieldError['country'] &&
                          fieldTouched['country']
                        ? true
                        : false
                  }
                  helperText={
                    fieldError?.country?.label && fieldTouched?.country?.label
                      ? fieldError.country.label
                      : null
                  }
                />
              )}
            />
          </Fade>
        </Stack>
      )}
      {/* Locations Map */}
      {showLocationMap &&
        fieldValue?.coordinates?.longitude &&
        fieldValue?.coordinates?.latitude && (
          <MapLocation disableZoom={disableZoom} />
        )}
    </>
  );
};

const AddressField = (props) => {
  return (
    <MapLocationByAddressProvider>
      <AddressFieldContent {...props} />
    </MapLocationByAddressProvider>
  );
};

export default AddressField;
