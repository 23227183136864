import { Box, Paper, CircularProgress } from '@mui/material';
import CanvasContainer from '../../new-product/container/image/CanvasContainer';
import { useEffect, useState } from 'react';

export const CarouselItem = ({
  image,
  viewMode,
  showSliders,
  showOrbitControls,
  isModelInteracting,
  wireframeMode,
  setWireframeMode,
  enableZoom,
  autoRotate,
  autoRotateSpeed,
  initialCenter = false,
  maxDistance,
}) => {
  const {
    rotationX: savedRotationX,
    rotationY: savedRotationY,
    cameraPosition: savedCameraPosition,
  } = image.options;

  const [initialMeshes, setInitialMeshes] = useState([]);
  const [meshes, setMeshes] = useState([]);
  const [cameraPosition, setCameraPosition] = useState(
    savedCameraPosition ?? [0, 0, 50],
  );
  const [rotationX, setRotationX] = useState(savedRotationX ?? 0);
  const [rotationY, setRotationY] = useState(savedRotationY ?? 0);
  const [isLoading, setIsLoading] = useState(true);
  const [isProductImageDirtied, setIsProductImageDirtied] = useState(false);

  const imageType = image.imageType.toLowerCase();

  useEffect(() => {
    const blockTouchMove = (e) => {
      if (isModelInteracting) {
        e.preventDefault(); // this is needed to block scroll
      }
    };

    if (isModelInteracting) {
      document.addEventListener('touchmove', blockTouchMove, {
        passive: false,
      });
    }

    return () => {
      document.removeEventListener('touchmove', blockTouchMove);
    };
  }, [isModelInteracting]);

  if (imageType === 'usdz' || imageType === 'stp' || imageType === 'step') {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 10,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'background.default',
          }}
        >
          <CircularProgress size={48} />
        </Box>
      )}

      {imageType === 'gltf' && image?.url ? (
        <CanvasContainer
          url={image?.url}
          type={imageType}
          options={image.options}
          viewMode={viewMode}
          showSliders={showSliders}
          showOrbitControls={showOrbitControls}
          rotationX={rotationX}
          setRotationX={setRotationX}
          rotationY={rotationY}
          setRotationY={setRotationY}
          cameraPosition={cameraPosition}
          setCameraPosition={setCameraPosition}
          setIsProductImageDirtied={setIsProductImageDirtied}
          meshes={meshes}
          setMeshes={setMeshes}
          setInitialMeshes={setInitialMeshes}
          isModelInteracting={isModelInteracting}
          wireframeMode={wireframeMode}
          setWireframeMode={setWireframeMode}
          enableZoom={enableZoom}
          autoRotate={autoRotate}
          autoRotateSpeed={autoRotateSpeed}
          initialCenter={initialCenter}
          maxDistance={maxDistance}
          makeDefault
          enablePan={false}
          onLoaded={() => setIsLoading(false)}
        />
      ) : (
        <Paper
          elevation={0}
          square
          sx={{
            background: 'transparent',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            component="img"
            src={image?.url}
            alt={image?.name ?? 'Product image'}
            onLoad={() => setIsLoading(false)}
            sx={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
              display: isLoading ? 'none' : 'block',
            }}
          />
        </Paper>
      )}
    </Box>
  );
};
