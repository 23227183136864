import { useContext, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Formik, Form, useFormikContext } from 'formik';
import { CartContext } from '../../../../context/cart/CartContext';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';
import CreditCardNumberField from '../../../paymentMethods/CreditCardNumberField';
import CreditCardExpirationDateField from '../../../paymentMethods/CreditCardExpirationField';
import CreditCardCVCField from '../../../paymentMethods/CreditCardCVCField';
import CreditCardHolderNameField from '../../../paymentMethods/CreditCardHolderNameField';
import AddressField from '../../../address/AddressField';
import TermsOfServiceBox from '../../TermsOfServiceBox';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { OrderPaymentValidationSchema } from './validationSchema';
import { useTranslation } from 'react-i18next';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { trackActivity } from '../../../../api/tracker/trackerRoutes';
import ProcessingContext from '../../../../context/providers/ProcessingProvider';
import { useNavigate } from 'react-router-dom';

const OrderTotalsPaymentContainer = () => {
  const stripe = useStripe();
  const elements = useElements();
  const axios = useAxiosPrivate();

  //   Set Host URL
  const host_url = window.location.host;

  const {
    eachProductHasShipping,
    eachProductHasTax,
    cartProducts,
    cartTotal,
    carrier,
    setCartProducts,
  } = useContext(CartContext);

  const { t: transButtons } = useTranslation('buttons');
  const { t: transMessages } = useTranslation('messages');
  const navigate = useNavigate();

  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();
  const { isLoading, setIsLoading } = useContext(ProcessingContext);
  const { values: parentValues, isValid: parentFormValid } = useFormikContext();
  const billingIndividual = parentValues.billingIndividual;
  const billingAddress = parentValues.billingAddress;
  const shippingIndividual = parentValues.shippingIndividual;
  const shippingAddress = parentValues.shippingAddress;

  const [paymentIntentSecret, setPaymentIntentSecret] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);

  const createPaymentIntent = async () => {
    const payload = {
      amount: Math.round(cartTotal * 100),
      products: cartProducts,
      billingIndividual,
      billingAddress,
      shippingIndividual,
      shippingAddress,
      carrier,
    };

    const { data, status } = await axios.post(
      `/api/stripe/payment-intents/public/create`,
      payload,
    );

    if (status === 201) {
      // console.log('Payment Intent Secret -> ', data.data.client_secret);
      setPaymentIntentSecret(data.data.client_secret);
      setOrderId(data.data.order_id);
    }
  };

  useEffect(() => {
    if (!cartProducts.length) return;
    if (!eachProductHasShipping || !eachProductHasTax) return;
    createPaymentIntent();
  }, [eachProductHasShipping, eachProductHasTax]);

  const initialValues = {
    name: `${billingIndividual.first_name} ${billingIndividual.last_name}`,
    address: billingAddress || {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postcode: '',
      country: '',
    },
    card_number: false,
    expiration: false,
    cvc: false,
  };

  const validationSchema = OrderPaymentValidationSchema();

  const trackProductOrder = async (referalID, orderValue) => {
    console.log('Track Order -> ', referalID);
    const payload = {
      documentId: referalID,
      module: 'referral_campaigns',
      action: 'order',
      additionalParams: {
        order: {
          value: orderValue,
        },
      },
      tracker: true,
    };
    await trackActivity({ payload });
  };

  const handleSubmit = async ({ formik, paymentId }) => {
    try {
      // Create a Stripe Customer
      setIsLoading(true);

      const payload = {
        email: parentValues.billingIndividual.email,
        name: `${parentValues.billingIndividual.first_name} ${parentValues.billingIndividual.last_name}`,
        company: parentValues.billingIndividual?.company,
        phone: parentValues.billingIndividual.phone.value,
        billing: {
          name: `${parentValues.billingIndividual.first_name} ${parentValues.billingIndividual.last_name}`,
          phone: parentValues.billingIndividual.phone.value,
          email: parentValues.billingIndividual.email,
          address: {
            line1: parentValues.billingAddress.line1,
            line2: parentValues.billingAddress.line2,
            city: parentValues.billingAddress.city,
            state: parentValues.billingAddress.state,
            postal_code: parentValues.billingAddress.postcode,
            country: parentValues.billingAddress.country.label,
          },
        },
        shipping: {
          name: `${parentValues.shippingIndividual.first_name} ${parentValues.shippingIndividual.last_name}`,
          phone: parentValues.shippingIndividual.phone.value,
          email: parentValues.shippingIndividual.email,
          address: {
            line1: parentValues.shippingAddress.line1,
            line2: parentValues.shippingAddress.line2,
            city: parentValues.shippingAddress.city,
            state: parentValues.shippingAddress.state,
            postal_code: parentValues.shippingAddress.postcode,
            country: parentValues.shippingAddress.country.label,
          },
        },
        address: {
          line1: parentValues.billingAddress.line1,
          line2: parentValues.billingAddress.line2,
          city: parentValues.billingAddress.city,
          state: parentValues.billingAddress.state,
          postal_code: parentValues.billingAddress.postcode,
          country: parentValues.billingAddress.country.label,
        },
      };

      const { data, status } = await axios.post(
        `/api/stripe/customers/public/create`,
        payload,
      );

      if (status !== 200 && status !== 241) {
        console.log('Error -> ', status);
        throw new Error('error creating customer');
      }

      // Track Order
      // console.log('Cart Products on Payment Submit -> ', cartProducts);
      cartProducts.forEach(async (product) => {
        if (product.referalID) {
          const orderValue = product.product.list_price * product.quantity;
          const trackOrder = await trackProductOrder(
            product.referalID,
            orderValue,
          );
          // console.log('Track Order Response -> ', trackOrder);
        }
      });

      // console.log('Payment succeeded! Order ID:', orderId);
      handleRegularResponse({
        open: true,
        status: 'success',
        statusCode: 200,
        message: transMessages('payment.successful'),
      });

      setCartProducts([]);

      // Navigate
      navigate(
        `/checkout-success?order=${orderId}&user=${data.data.userId}&email=${data.data.email}&payment=${paymentId}`,
      );
    } catch (err) {
      console.error('Payment exception:', err);
      handleErrorResponse(err);
    } finally {
      setIsLoading(false);
      formik.setSubmitting(false);
    }
  };

  if (!paymentIntentSecret) return null;

  // console.log('Values -> ', values);

  // console.log('Cart Products -> ', cartProducts);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {(formik) => {
        const { values, setFieldValue, isSubmitting, isValid, setSubmitting } =
          formik;
        return (
          <Form>
            <Box px={4}>
              <Stack spacing={2}>
                <CreditCardNumberField
                  required
                  disabled={isSubmitting}
                  isLoadingManual={isSubmitting}
                  onValidityChange={(result) =>
                    setFieldValue('card_number', result)
                  }
                />
                <Stack direction="row" spacing={2}>
                  <CreditCardExpirationDateField
                    required
                    disabled={isSubmitting}
                    isLoadingManual={isSubmitting}
                    onValidityChange={(result) =>
                      setFieldValue('expiration', result)
                    }
                  />
                  <CreditCardCVCField
                    required
                    disabled={isSubmitting}
                    isLoadingManual={isSubmitting}
                    onValidityChange={(result) => setFieldValue('cvc', result)}
                  />
                </Stack>
                <CreditCardHolderNameField
                  required
                  disabled={isSubmitting}
                  isLoadingManual={isSubmitting}
                />
                <AddressField
                  required
                  isLoadingManual={isSubmitting}
                  initialCountry={billingAddress?.country}
                />
              </Stack>
              <Box my={4} display="flex" justifyContent="space-between">
                <TermsOfServiceBox
                  areTermsAccepted={areTermsAccepted}
                  setAreTermsAccepted={setAreTermsAccepted}
                />
                <IntuIconButton
                  disabled={
                    !areTermsAccepted ||
                    isSubmitting ||
                    !isValid ||
                    !parentFormValid ||
                    isLoading?.status
                  }
                  type="submit"
                  onClick={async () => {
                    // Confirm Credit Card Payment
                    const cardElement = elements.getElement(CardNumberElement);
                    const expElement = elements.getElement(CardExpiryElement);
                    const cvcElement = elements.getElement(CardCvcElement);

                    if (
                      !stripe ||
                      !elements ||
                      !cardElement ||
                      !expElement ||
                      !cvcElement
                    ) {
                      createPaymentIntent();
                      return handleRegularResponse({
                        open: true,
                        status: 'warning',
                        statusCode: 240,
                        message: transMessages('payment.expired'),
                      });
                    }

                    const billing_details = {
                      name: values.name,
                      address: {
                        line1: values.address.line1,
                        line2: values.address.line2,
                        city: values.address.city,
                        state: values.address.state,
                        postal_code: values.address.postcode,
                        country: values.address.country?.code,
                      },
                    };

                    const { paymentIntent, error } =
                      await stripe.confirmCardPayment(paymentIntentSecret, {
                        payment_method: {
                          card: cardElement,
                          billing_details,
                        },
                      });

                    setSubmitting(true);

                    if (error) {
                      console.error('Payment error:', error);
                      createPaymentIntent();
                      return handleRegularResponse({
                        open: true,
                        status: 'warning',
                        statusCode: 240,
                        message: transMessages('payment.expired'),
                      });
                    } else if (paymentIntent.status === 'succeeded') {
                      // Payment Successfull
                      const paymentId = paymentIntent.id;

                      handleSubmit({
                        values,
                        formik,
                        paymentId,
                      });
                    }
                  }}
                  tooltipTitle={transButtons('place_order')}
                  isLoading={isLoading.status || isSubmitting}
                />
              </Box>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default OrderTotalsPaymentContainer;
