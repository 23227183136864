import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { AddPaymentMethodValidationSchema } from '../../../customers/paymentMethods/validationSchema';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import countryList from '../../../../data/CountryData.json';
import CreditCardNumberField from '../../../paymentMethods/CreditCardNumberField';
import CreditCardExpirationDateField from '../../../paymentMethods/CreditCardExpirationField';
import CreditCardCVCField from '../../../paymentMethods/CreditCardCVCField';
import CreditCardHolderNameField from '../../../paymentMethods/CreditCardHolderNameField';
import AddressField from '../../../address/AddressField';
import { createStripeCustomerSetUpIntent } from '../../../../api/stripe/setupIntent';
import { StripeContext } from '../../../../context/providers/stripe/StripeContextProvider';
import SimpleToggle from '../../../mui/SimpleToggle';
import { createNewSubscription } from '../../../../api/stripe/subscriptions';
import PromotionCodeContainer from '../container/PromotionCodeContainer';

const handleCustomValidation = async (elements, transValidations) => {
  const errors = {};

  if (!elements) {
    errors.card_number = 'Stripe Elements not initialized.';
    return errors;
  }

  // Validate Card Number Element
  const cardNumberElement = elements.getElement('cardNumber');
  if (!cardNumberElement) {
    errors.card_number = transValidations('card_number.missing');
  } else if (cardNumberElement._empty) {
    errors.card_number = transValidations('card_number.required');
  } else if (cardNumberElement._invalid) {
    errors.card_number = transValidations('card_number.invalid');
  }

  // Validate Expiration Date Element
  const cardExpiryElement = elements.getElement('cardExpiry');
  if (!cardExpiryElement) {
    errors.expiration = transValidations('expiration.missing');
  } else if (cardExpiryElement._empty) {
    errors.expiration = transValidations('expiration.required');
  } else if (cardExpiryElement._invalid) {
    errors.expiration = transValidations('expiration.invalid');
  }

  // You can add similar checks for the CVC field if required
  const cardCVCElement = elements.getElement('cardCvc');
  if (!cardCVCElement) {
    errors.cvc = transValidations('cvc.missing');
  } else if (cardCVCElement._empty) {
    errors.cvc = transValidations('cvc.required');
  } else if (cardCVCElement._invalid) {
    errors.cvc = transValidations('cvc.invalid');
  }

  return errors;
};

const SubscriptionPaymentDialog = ({
  open, //Modal Open State,
  onClose, // Modal Closed State,
  transNS = 'dialogs',
  transPrefix = 'NewSubscription',
  selectedSubscription,
}) => {
  const { t } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transValidations } = useTranslation('validation');
  const { t: transMessages } = useTranslation('messages');
  const { t: transTypes } = useTranslation('types');
  const { t: transButtons } = useTranslation('buttons');

  const stripe = useStripe();
  const elements = useElements();
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { stripeUserData, getStripeAccountDetails } = useContext(StripeContext);
  const [userCountryDetails, setUserCountryDetails] = useState({
    label: 'United States',
    dialCode: '1',
    code: 'US',
    currency: 'USD',
  });

  // Get user country data
  useEffect(() => {
    const userCountry =
      stripeUserData?.stripe_customer_data?.address?.country || null;

    if (userCountry) {
      const userCountryData = countryList.find(
        (element) => userCountry === element.code,
      );
      setUserCountryDetails(userCountryData);
    }
  }, [stripeUserData?.stripe_customer_data?.address?.country, open]);

  const createSubscription = async (values, formik) => {
    const cardElement = elements.getElement(CardNumberElement);
    const expElement = elements.getElement(CardExpiryElement);
    const cvcElement = elements.getElement(CardCvcElement);

    // console.log('values on submit', values);
    if (
      !stripeUserData?.stripe_customer_data?.id ||
      !stripe ||
      !elements ||
      !cardElement ||
      !expElement ||
      !cvcElement
    ) {
      return;
    }

    try {
      const billingAddress = Object.entries({
        line1: values?.address?.line1,
        line2: values?.address?.line2,
        city: values?.address?.city,
        state: values?.address?.state,
        postal_code: values?.address?.postcode,
        country: values?.address.country?.code,
      })
        .filter(
          ([key, value]) =>
            value !== '' && value !== null && value !== undefined,
        ) // Remove invalid values
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

      const req_fields = [];
      if (!billingAddress.line1)
        req_fields.push(transValidations('address.line1_missing'));
      if (!billingAddress.city)
        req_fields.push(transValidations('address.city_missing'));
      if (!billingAddress.state)
        req_fields.push(transValidations('address.state_missing'));
      if (!billingAddress.postal_code)
        req_fields.push(transValidations('address.postcode_missing'));
      if (!billingAddress.country)
        req_fields.push(transValidations('address.country_missing'));
      if (!stripe || !elements) {
        req_fields.push(transValidations('stripe_initialization'));
      }

      if (req_fields.length > 0) {
        const errorMessage = req_fields.join(', ');
        formik.setSubmitting(false);
        return handleRegularResponse({
          open: true,
          status: 'error',
          statusCode: '251',
          message: transValidations('required_fields', { errorMessage }),
        });
      }

      // Create Setup Intent
      const createCustomerSetUpIntent = await createStripeCustomerSetUpIntent({
        customer: stripeUserData?.stripe_customer_data?.id,
      });

      // console.log('createdCustomerSetupintent', createCustomerSetUpIntent);

      if (createCustomerSetUpIntent.statusCode === 200) {
        const { client_secret } = createCustomerSetUpIntent.data;

        // Confirm card setup with a Setup Intent client secret
        const { setupIntent, error } = await stripe.confirmCardSetup(
          client_secret,
          {
            payment_method: {
              card: elements.getElement('cardNumber'),
              allow_redisplay: 'always',
              billing_details: {
                name: values?.name,
                address: {
                  ...billingAddress,
                },
              },
            },
          },
        );

        formik.setSubmitting(true);

        // console.log('SetUp Intent Result -> ', setupIntent);

        if (error) {
          formik.setSubmitting(false);
          return handleRegularResponse({
            open: true,
            status: 'error',
            statusCode: 400,
            message: transMessages('actions.add.400', {
              type: transTypes('payment_method'),
              errorMessage: error.message,
            }),
          });
        }

        // const items =

        const payload = {
          customerId: stripeUserData.stripe_customer_data.id,
          items: checkoutDetails.items,
          paymentMethodId: setupIntent.payment_method,
          autoRenew: isAutoRenew,
          promotionId: promotion ? promotion.data.id : null,
          description: selectedSubscription.subscription.name,
        };

        const { data, status } = await createNewSubscription(payload);

        // console.log('res from /create-new', data, status);

        if (status === 200) {
          handleRegularResponse({
            open: true,
            status: 'success',
            statusCode: '200',
            message: transMessages('actions.add.200', {
              type: transTypes('subscription'),
            }),
          });
          setPromotion(initialPromotionValues);
          onClose(data.data);
          getStripeAccountDetails();
        }
      }
    } catch (error) {
      console.error('Error generating token', error);
      handleErrorResponse(error);
    } finally {
      formik.setSubmitting(false);
    }
  };

  const validationSchema = AddPaymentMethodValidationSchema();

  const initialValues = {
    name: stripeUserData?.stripe_customer_data?.name || '',
    address: {
      line1: stripeUserData?.stripe_customer_data?.address?.line1 || '',
      line2: stripeUserData?.stripe_customer_data?.address?.line2 || '',
      city: stripeUserData?.stripe_customer_data?.address?.city || '',
      state: stripeUserData?.stripe_customer_data?.address?.state || '',
      postcode:
        stripeUserData?.stripe_customer_data?.address?.postal_code || '',
      country: userCountryDetails || '',
    },
    card_number: false,
    expiration: false,
    cvc: false,
  };

  const [isAutoRenew, setIsAutoRenew] = useState(true);
  const [checkoutDetails, setCheckoutDetails] = useState({
    items: [],
    subtotal: 0,
    discount: 0,
    total: 0,
  });

  const initialPromotionValues = {
    id: '',
    code: '',
    valid: false,
    base: 0,
    discount: 0,
    sale: 0,
    data: {},
  };

  const [promotion, setPromotion] = useState(initialPromotionValues);

  useEffect(() => {
    const items = [
      {
        id: selectedSubscription?.defaultPrice?.id,
        type: selectedSubscription?.defaultPrice?.type,
        name: selectedSubscription?.defaultPrice?.nickname,
        price: selectedSubscription?.defaultPrice?.unit_amount / 100,
      },
    ];

    if (selectedSubscription?.oneTimePrice) {
      items.push({
        id: selectedSubscription?.oneTimePrice?.id,
        type: selectedSubscription?.oneTimePrice?.type,
        name: selectedSubscription?.oneTimePrice?.nickname,
        price: selectedSubscription?.oneTimePrice?.unit_amount / 100,
      });
    }

    let calculateSubTotal = 0;
    let calculateTotal = 0;

    items.forEach((element) => {
      calculateSubTotal += element.price;
      calculateTotal += element.price;
    });

    setCheckoutDetails({
      ...checkoutDetails,
      items: items,
      monthlyCost: 0 + selectedSubscription?.defaultPrice?.unit_amount / 100,
      subtotal: calculateSubTotal,
      total: calculateTotal,
    });

    setPromotion({
      ...promotion,
      // base: selectedSubscription?.defaultPrice?.unit_amount / 100,
      base: calculateSubTotal,
    });
  }, [selectedSubscription]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={true}
      validateOnMount={true}
      enableReinitialize={true}
      validateOnBlur={true}
      validate={async (values) => {
        try {
          // Run Yup validation and collect schema errors
          const schemaErrors = await AddPaymentMethodValidationSchema()
            .validate(values, { abortEarly: false })
            .then(() => ({})) // If validation passes, return an empty object
            .catch((err) => {
              return err.inner.reduce((acc, curr) => {
                acc[curr.path] = curr.message; // Map field paths to their messages
                return acc;
              }, {});
            });

          // Run custom validation and collect errors
          const customErrors = handleCustomValidation(
            elements,
            transValidations,
          );

          // Merge Yup schema errors with custom validation errors
          return { ...schemaErrors, ...customErrors };
        } catch (err) {
          return {};
        }
      }}
    >
      {(formik) => {
        const { values, isValid, isSubmitting, setFieldValue } = formik;

        return (
          <Form className="form-horizontal" role="form">
            <Dialog
              open={open}
              onClose={onClose}
              PaperProps={{
                component: 'form',
              }}
            >
              <DialogTitle>{t('title')}</DialogTitle>
              <DialogContent>
                <DialogContentText>{t('description')}</DialogContentText>

                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  sx={{ mt: '1rem' }}
                >
                  {/* Subscription Details */}
                  <Box sx={{ mb: '1rem' }}>
                    <Typography variant="body">
                      {t('subscription_details.title')}
                    </Typography>
                  </Box>

                  <Box sx={{ mb: '1rem' }}>
                    {/* Line Item */}
                    {checkoutDetails.items.map((item, index) => {
                      return (
                        <Box
                          key={index}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          gap={2}
                          width="100%"
                        >
                          <Box>{item?.name}</Box>
                          <Box>
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(item?.price)}
                          </Box>
                        </Box>
                      );
                    })}

                    {/* Total */}
                    <Divider />
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      gap={2}
                      width="100%"
                    >
                      <Box>{t('total')}</Box>
                      <Box>
                        {new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                        }).format(checkoutDetails.subtotal)}
                      </Box>
                    </Box>

                    {/* Promo Code */}
                    <Box>
                      <PromotionCodeContainer
                        disabled={isSubmitting}
                        promotion={promotion}
                        setPromotion={setPromotion}
                        checkoutDetails={checkoutDetails}
                        setCheckoutDetails={setCheckoutDetails}
                        initialPromotionValues={initialPromotionValues}
                      />
                    </Box>

                    {/* Price After Discount */}
                    {promotion?.valid ? (
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        gap={2}
                        width="100%"
                      >
                        <Box sx={{ marginLeft: 'auto' }}>
                          {t('note', {
                            salesPrice: new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(checkoutDetails?.total),
                            basePrice: new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(checkoutDetails?.monthlyCost),
                            duration:
                              promotion?.data?.coupon?.duration_in_months,
                          })}
                        </Box>
                      </Box>
                    ) : null}
                  </Box>

                  {/* Payment Details */}
                  <Box sx={{ mb: '1rem' }}>
                    <Typography variant="body">
                      {t('card_details.title')}
                    </Typography>
                    <Typography variant="body1">
                      {t('card_details.description')}
                    </Typography>
                  </Box>
                  <Box>
                    <Stack spacing={2} direction="column">
                      {/* Card Number */}
                      <CreditCardNumberField
                        required
                        disabled={isSubmitting}
                        isLoadingManual={isSubmitting}
                        onValidityChange={(result) =>
                          setFieldValue('card_number', result)
                        }
                      />
                      <Stack spacing={2} diection="row">
                        {/* Credit Card Expiration */}
                        <CreditCardExpirationDateField
                          required
                          disabled={isSubmitting}
                          isLoadingManual={isSubmitting}
                          onValidityChange={(result) =>
                            setFieldValue('expiration', result)
                          }
                        />

                        {/* Credit Card CVC */}
                        <CreditCardCVCField
                          required
                          disabled={isSubmitting}
                          isLoadingManual={isSubmitting}
                          onValidityChange={(result) =>
                            setFieldValue('cvc', result)
                          }
                        />
                      </Stack>
                    </Stack>
                  </Box>

                  {/* Billing Details */}
                  <Box sx={{ mb: '1rem' }}>
                    <Typography variant="h6">
                      {t('billing_details.title')}
                    </Typography>
                    <Typography variant="body1">
                      {t('billing_details.description')}
                    </Typography>
                  </Box>
                  <Box>
                    <Stack spacing={2}>
                      <CreditCardHolderNameField
                        required
                        disabled={isSubmitting}
                        isLoadingManual={isSubmitting}
                      />
                      <AddressField required isLoadingManual={isSubmitting} />
                    </Stack>
                  </Box>
                </Box>
                <Box mt={2}>
                  <SimpleToggle
                    isChecked={isAutoRenew}
                    disabled={isSubmitting}
                    setIsChecked={setIsAutoRenew}
                    text={t('auto_renew.label')}
                  />
                </Box>
              </DialogContent>
              <DialogActions>
                <Grid container sx={{ margin: '1rem 1rem 0 1rem' }}>
                  <Grid item>
                    <IntuIconButton
                      disabled={isSubmitting}
                      type="cancel"
                      onClick={() => {
                        setPromotion(initialPromotionValues);
                        onClose(values);
                      }}
                      tooltipTitle={transButtons('cancel')}
                    />
                  </Grid>
                  <Grid item sx={{ marginLeft: 'auto' }}>
                    <IntuIconButton
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      onClick={() => {
                        createSubscription(values, formik);
                      }}
                      tooltipTitle={transButtons('subscribe')}
                      isLoading={isSubmitting}
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SubscriptionPaymentDialog;
