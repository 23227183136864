import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { Gauge } from '@mui/x-charts/Gauge';

import FormikTabs from '../formik/FormikTabs';
import ReferralOffersList from './list/ReferralOffersList';
import { createContext, useEffect, useState } from 'react';
import {
  listReferralCampaigns,
  listReferralOffers,
} from '../../api/referrals/referralRoutes';
import useResponseHandling from '../../hooks/useResponseHandler';
import ReferralActiveCampaignList from './list/ReferralCampaignActiveList';
import ReferralCompletedCampaignList from './list/ReferralCampaignCompletedList';

export const ManageReferralsContext = createContext();

const ManageReferralsComponent = () => {
  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.ManageReferrals',
  });

  // Context
  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();

  // States
  const [referralOffers, setReferralOffers] = useState({
    count: 0,
    fetching: false,
    data: [],
  });
  const [referralCampaignsActive, setReferralCampaignsActive] = useState({
    count: 0,
    fetching: false,
    data: [],
  });
  const [referralCampaignsCompleted, setReferralCampaignsCompleted] = useState({
    count: 0,
    fetching: false,
    data: [],
  });

  const [campaignPerformance, setCampaignPerformance] = useState({
    visits: { count: 0, revenue: 0 },
    inquiries: { count: 0, revenue: 0 },
    orders: { count: 0, revenue: 0 },
    totalReward: 0,
  });

  const tabContent = [
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.active_referral_campaigns.title')}
        </Typography>
      ),
      description: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.active_referral_campaigns.description')}
        </Typography>
      ),
      content: <ReferralActiveCampaignList />,
      visible: referralCampaignsActive.count > 0 ? true : false,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.referral_offers.title')}
        </Typography>
      ),
      description: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.referral_offers.description')}
        </Typography>
      ),
      content: <ReferralOffersList />,
      visible: referralOffers.count > 0 ? true : false,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.completed_referral_campaigns.title')}
        </Typography>
      ),
      description: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.completed_referral_campaigns.description')}
        </Typography>
      ),
      content: <ReferralCompletedCampaignList />,
      // content: <ReferralActiveCampaignList />,
      visible: referralCampaignsCompleted.count > 0 ? true : false,
    },
  ];

  const getActiveReferralCampaigns = async ({ refresh = false }) => {
    setReferralCampaignsActive({ ...referralCampaignsActive, fetching: true });

    try {
      const { statusCode, data, status, message } =
        await listReferralCampaigns();

      // console.log('Get Active Campaigns Response -> ', data);

      if (statusCode === 200) {
        const filteredCampaigns = data.filter(
          (campaign) =>
            campaign?.status === 'pending' || campaign?.status === 'active',
        );

        setReferralCampaignsActive({
          ...referralCampaignsActive,
          count: filteredCampaigns.length,
          data: filteredCampaigns,
          fetching: false,
        });

        if (refresh === true) {
          handleRegularResponse({
            open: true,
            status: status,
            statusCode: statusCode,
            message: message,
          });
        }
      }
    } catch (error) {
      console.error('error', error);

      setReferralCampaignsActive({
        ...referralCampaignsActive,
        fetching: false,
        count: 0,
        data: [],
      });

      handleErrorResponse(error);
    }
  };

  const getCompletedReferralCampaigns = async ({ refresh = false }) => {
    setReferralCampaignsCompleted({
      ...referralCampaignsCompleted,
      fetching: true,
    });

    try {
      const { statusCode, data, status, message } =
        await listReferralCampaigns();

      if (statusCode === 200) {
        const filteredCampaigns = data.filter(
          (campaign) =>
            campaign?.status === 'completed' || campaign?.status === 'archived',
        );
        setReferralCampaignsCompleted({
          ...referralCampaignsCompleted,
          count: filteredCampaigns.length,
          data: filteredCampaigns,
          fetching: false,
        });

        if (refresh === true) {
          handleRegularResponse({
            open: true,
            status: status,
            statusCode: statusCode,
            message: message,
          });
        }
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
      setReferralCampaignsCompleted({
        ...referralCampaignsCompleted,
        fetching: false,
        count: 0,
        data: [],
      });
    }
  };

  const getReferralOffers = async ({ refresh = false }) => {
    setReferralOffers({
      ...referralOffers,
      fetching: true,
    });

    try {
      const { message, status, statusCode, data } = await listReferralOffers();

      if (statusCode === 200) {
        setReferralOffers({
          ...referralOffers,
          fetching: false,
          count: data.length,
          data: data,
        });

        if (refresh === true) {
          handleRegularResponse({
            open: true,
            status: status,
            statusCode: statusCode,
            message: message,
          });
        }
      } else {
        handleRegularResponse({
          open: true,
          status: status,
          message: message,
        });
      }
    } catch (error) {
      console.error('error', error);
      setReferralOffers({
        ...referralOffers,
        fetching: false,
        count: 0,
        data: [],
      });
    }
  };

  useEffect(() => {
    getActiveReferralCampaigns({ refresh: false });
    getCompletedReferralCampaigns({ refresh: false });
    getReferralOffers({ refresh: false });
  }, []);

  // Get Campaign Performance
  const getReferralPerformanceStats = (ocampaign) => {
    return ocampaign.reduce(
      (acc, campaign) => {
        const perf = campaign.referral_performance || {};

        acc.visits.count += perf.visits?.count || 0;
        acc.visits.revenue += perf.visits?.revenue || 0;

        acc.inquiries.count += perf.inquiries?.count || 0;
        acc.inquiries.revenue += perf.inquiries?.revenue || 0;

        acc.orders.count += perf.orders?.count || 0;
        acc.orders.revenue += perf.orders?.revenue || 0;

        acc.totalReward += perf.reward || 0;

        return acc;
      },
      {
        visits: { count: 0, revenue: 0 },
        inquiries: { count: 0, revenue: 0 },
        orders: { count: 0, revenue: 0 },
        totalReward: 0,
      },
    );
  };

  useEffect(() => {
    if (referralCampaignsActive.data.length > 0) {
      const stats = getReferralPerformanceStats(referralCampaignsActive.data);
      setCampaignPerformance(stats);
    }
  }, [referralCampaignsActive.data]);

  const pieDataInquiries = [
    { category: 'Inquiries', value: campaignPerformance.inquiries.count },
    {
      category: '',
      value:
        campaignPerformance.visits.count - campaignPerformance.inquiries.count,
    },
  ];

  const pieDataOrders = [
    { category: 'Orders', value: campaignPerformance.orders.count },
    {
      category: 'Visits',
      value:
        campaignPerformance.visits.count - campaignPerformance.orders.count,
    },
  ];

  // console.log('Campaign Performance Total', campaignPerformance);
  // console.log('Referall Offers Context -> ', referralOffers);
  // console.log('Referral Campaigns Active -> ', referralCampaignsActive);
  // console.log('Referral Campaigns Completed -> ', referralCampaignsCompleted);
  // console.log('Referral Campaign Offers -> ', referralOffers);

  return (
    <ManageReferralsContext.Provider
      value={{
        referralOffers,
        setReferralOffers,
        referralCampaignsActive,
        setReferralCampaignsActive,
        referralCampaignsCompleted,
        setReferralCampaignsCompleted,
        getActiveReferralCampaigns,
        getReferralOffers,
      }}
    >
      {/* Campaign Performanxce */}
      <Box flexDirection="column" gap={2}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 4 }}>
          {/* Row of 3 KPIs */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2,
            }}
          >
            {/* Visits */}
            <Box sx={{ flex: 1 }}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {t('dashboard.visits.title')}
                  </Typography>
                  <Box
                    sx={{ position: 'relative', width: '100%', height: 200 }}
                  >
                    <Gauge
                      value={campaignPerformance.visits.count}
                      valueMax={campaignPerformance.visits.count || 1}
                      startAngle={-110}
                      endAngle={110}
                      text={null}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {`${campaignPerformance.visits.count} ${t('dashboard.labels.visits')}`}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>

            {/* CTA */}
            <Box sx={{ flex: 1 }}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {t('dashboard.cta.title')}
                  </Typography>
                  <Box
                    sx={{ position: 'relative', width: '100%', height: 200 }}
                  >
                    <Gauge
                      value={campaignPerformance.inquiries.count}
                      valueMax={campaignPerformance.visits.count || 1}
                      startAngle={-110}
                      endAngle={110}
                      text={({ value, valueMax }) =>
                        `${Math.round((value / valueMax) * 100)}%`
                      }
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {campaignPerformance.inquiries.count}{' '}
                        {t('dashboard.labels.inquiries')}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>

            {/* CTO */}
            <Box sx={{ flex: 1 }}>
              <Card sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {t('dashboard.cto.title')}
                  </Typography>
                  <Box
                    sx={{ position: 'relative', width: '100%', height: 200 }}
                  >
                    <Gauge
                      value={campaignPerformance.orders.count}
                      valueMax={campaignPerformance.visits.count || 1}
                      startAngle={-110}
                      endAngle={110}
                      text={({ value, valueMax }) =>
                        `${Math.round((value / valueMax) * 100)}%`
                      }
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {campaignPerformance.orders.count}{' '}
                        {t('dashboard.labels.orders')}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>

          {/* Total Reward row */}
          <Box>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>
                  {t('dashboard.reward.title', 'Total Reward')}
                </Typography>
                <Typography variant="h6">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  }).format(campaignPerformance.totalReward)}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>

        {/* Campaign Tabs */}
        <Box width="100%">
          <FormikTabs tabsContent={tabContent} />
        </Box>
      </Box>
    </ManageReferralsContext.Provider>
  );
};

export default ManageReferralsComponent;
