import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Box,
  Stack,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import useResponseHandling from '../../../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../../../hooks/axios/useAxiosPrivate';
import { createContext, useContext, useRef, useState } from 'react';
import { CompanyEmployeesListContext } from './EmployeesList';
import ResponseContext from '../../../../context/providers/ResponseProvider';
import { CompanyProfileContext } from '../../../../context/company/CompanyProfileProvider';
import { inviteUser, searchUsers } from '../../../../api/usersRoutes';
import {
  associateUserToCompany,
  unAssociateUserFromCompany,
} from '../../../../api/employeeRoutes';
import { SearchUserField } from '../../SearchUserField';
import IndividualFirstNameField from '../../individual/IndividualFirstNameField';
import EmailField from '../../fields/email/EmailField';
import EmployeeRoleField from './EmployeeRoleField';
import IntuIconButton from '../../../buttons/IntuIconButton';
import { searchForExisitingEmployee } from '../../../../api/companies';
import IndividualLastNameField from '../../individual/IndividualLastNameField';
import { createEmployeeDetailsValidationSchema } from './validationSchema';

export const EmployeeDetailsDialogContext = createContext();

export const EmployeeDetailsDialog = () => {
  const { employeeDialog, setEmployeeDialog, employeeDetails } = useContext(
    CompanyEmployeesListContext,
  );

  const { companyProfile, setCompanyProfile, getCompanyProfile } = useContext(
    CompanyProfileContext,
  );

  const { setErrorDialog } = useContext(ResponseContext);

  const [employeeRolechange, setEmployeeRoleChange] = useState(false);

  const { t } = useTranslation('dialogs', {
    keyPrefix: 'CompanyEmployeeDialog',
  });
  const { t: transMessages } = useTranslation('messages');
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const errRef = useRef();
  const formRef = useRef();

  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();

  const axios = useAxiosPrivate();

  const initialValues = employeeDetails;
  const validationSchema = createEmployeeDetailsValidationSchema();

  const host_url = window.location.host;

  const handleEmployeeUpdate = async ({
    values,
    helpers, //formik functions
    action, // Type of Change
  }) => {
    // Set Form in Submitting State

    helpers.isSubmitting = true;

    let message;

    const currentEmployees = [...companyProfile.employees];
    let newEmployeesList = [];
    let blockedUsers = companyProfile.blocked_users
      ? companyProfile.blocked_users
      : [];

    if (action === 'addUser') {
      message = t('form.company.dialog.added_user_msg', {
        role: values.role,
        company: values.company,
      });

      // Search for User By Email
      const searchPayload = {
        key: 'email',
        values: values.email,
      };

      // Create a New User Account first
      let employeeID = values?._id;
      if (!employeeID) {
        //create new user account
        const payload = {
          account_type: companyProfile.type,
          first_name: values.first_name,
          company: companyProfile._id,
          last_name: values.last_name,
          email: values.email,
          host_url: host_url,
        };

        const createNewUser = await inviteUser(payload);
        console.log('create new user -> ', createNewUser);

        const { data } = createNewUser.data;

        employeeID = data._id;
      } else {
        // Search User
        const searchRes = await searchUsers(searchPayload);

        const { data: searchData, statusCode: searchStatusCode } = searchRes;

        if (searchStatusCode !== 200) {
          return setErrorDialog({
            open: true,
            title: transMessages('global.error'),
            message: transMessages('CompanyEmployeeDialog.userNotFound'),
          });
        }

        // Search companies if user is current emplopyee
        const exisitingEmployeeCheck = await searchForExisitingEmployee(
          searchData[0]._id,
        );
        if (exisitingEmployeeCheck) {
          setErrorDialog({
            open: true,
            title: transMessages('global.error'),
            message: transMessages('CompanyEmployeeDialog.existing_employee'),
          });
          return;
        }

        employeeID = searchData[0]._id;
      }

      // Update Values
      const newEmployee = {
        _id: employeeID,
        role: values.role,
      };

      // Update above user's company
      const updatePayload = {
        company_id: companyProfile._id,
        employees: [newEmployee],
      };

      const updateCompanyAssociation =
        await associateUserToCompany(updatePayload);

      if (updateCompanyAssociation.statusCode !== 200) {
        handleRegularResponse({
          open: true,
          status: updateCompanyAssociation.status,
          message: updateCompanyAssociation.message,
        });
      }

      if (updateCompanyAssociation.statusCode === 200) {
        newEmployeesList = companyProfile.employees.map((employee) => {
          return {
            _id: employee._id,
            role: employee.role,
          };
        });

        newEmployeesList.push(newEmployee);
        setCompanyProfile({ ...companyProfile, newEmployeesList });
      }
    } else if (action === 'removeUser') {
      // check if there is more than one admin
      let admin_count = 0;
      for (let i = 0; i < currentEmployees.length; i++) {
        if (currentEmployees[i].role === 'admin') admin_count++;
      }

      // Guard Clause Exit when there is only one admin
      if (admin_count === 0) {
        setCompanyProfile({ ...companyProfile, currentEmployees });
        setErrorDialog({
          open: true,
          title: transMessages('global.error'),
          message: transMessages('CompanyEmployeeDialog.employeeCount'),
        });
        return;
      }

      newEmployeesList = currentEmployees
        .filter((employee) => employee._id !== values._id)
        .map((employee) => {
          return {
            _id: employee._id,
            role: employee.role,
          };
        });

      const payload = {
        company_id: companyProfile._id,
        employees: [values._id],
      };
      const updateCompanyAssociation =
        await unAssociateUserFromCompany(payload);

      if (updateCompanyAssociation.statusCode !== 200) {
        return handleRegularResponse({
          open: true,
          status: updateCompanyAssociation.status,
          message: updateCompanyAssociation.message,
        });
      }

      message = t('form.company.dialog.removed_user_msg', {
        role: values.employee_role,
        company: values.name,
      });
    } else if (action === 'updateUser') {
      currentEmployees.forEach((employee) => {
        if (employee._id === values._id) {
          employee.role = values.role;
        }
      });

      // check if there is more than one admin
      let admin_count = 0;
      for (let i = 0; i < currentEmployees.length; i++) {
        if (currentEmployees[i].role === 'admin') admin_count++;
      }

      // Guard Clause Exit when there is only one admin
      if (admin_count === 0) {
        setCompanyProfile({ ...companyProfile, currentEmployees });
        setErrorDialog({
          open: true,
          title: transMessages('global.error'),
          message: transMessages('CompanyEmployeeDialog.employeeCount'),
        });
        return;
      }

      // If employee is blocked, remove user from company, add to companys blocked list and remove company from user's profile
      if (values.role === 'blocked') {
        blockedUsers.push(values._id);
        const payload = {
          company_id: companyProfile._id,
          employees: [values._id],
        };
        const updateCompanyAssociation =
          await unAssociateUserFromCompany(payload);

        if (updateCompanyAssociation.statusCode !== 200) {
          handleRegularResponse({
            open: true,
            status: updateCompanyAssociation.status,
            message: updateCompanyAssociation.message,
          });
        }
        newEmployeesList = [...currentEmployees];
      } else {
        // Remove user from blocked list
        blockedUsers = blockedUsers.filter((userID) => userID !== values._id);
        newEmployeesList = [...currentEmployees];
        setCompanyProfile({ ...companyProfile, newEmployeesList });
        message = t('form.company.dialog.updated_user_msg', {
          company: values.name,
          role: values.role,
        });
      }
    }

    // Update Company Employees
    const url = '/api/companies/company/update';
    const payload = {
      _id: companyProfile._id,
      employees: newEmployeesList,
      blocked_users: blockedUsers,
      notification: {
        send: true,
        message: message,
        recipients: companyProfile.admins,
      },
    };

    try {
      const response = await axios.put(url, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      const { data, status } = response;

      if (data && status === 200) {
        const newCompanyInfo = {
          ...data.data,
        };

        // Updete Auth
        // setAuth({
        //   ...auth,
        //   company_info: newCompanyInfo,
        // });

        const profile = await getCompanyProfile(data.data._id);

        setCompanyProfile({
          ...profile,
        });

        // Update Change Name Field
        helpers.setValues({
          ...values,
          _id: '',
          first_name: '',
          last_name: '',
          name: '',
          email: '',
          role: '',
        });

        handleRegularResponse({
          open: true,
          status: data.status,
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
    } finally {
      helpers.isSubmitting = false;
      setEmployeeDialog(false);
    }
  };

  return (
    <EmployeeDetailsDialogContext.Provider
      value={{ employeeRolechange, setEmployeeRoleChange }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {(formik) => {
          const { values, errors, isValid } = formik;
          return (
            <Dialog
              maxWidth="sm"
              fullWidth
              open={employeeDialog ? employeeDialog.open : false}
            >
              <DialogTitle className="intu__form-title">
                {t(`title.${employeeDialog?.action}`)}
              </DialogTitle>
              <DialogContent className="intu__form-content">
                <Form>
                  <Grid container>
                    <Grid item container>
                      <Grid item>
                        <Typography>
                          {t(`description.${employeeDialog?.action}`)}
                        </Typography>
                      </Grid>
                      {/* {employeeDialog?.action === 'edit' && */}
                      <Grid item className="intu__form-bullets">
                        <Trans
                          t={t}
                          i18nKey={'description.content'}
                          components={[<li />, <li />, <li />, <li />, <ul />]}
                        >
                          <ul>
                            <li>
                              Associates have a verified relation to your
                              company.
                            </li>
                            <li>
                              Super Users can see and edit company campaigns,
                              products and reports
                            </li>
                            <li>
                              Admins can change company data, add, remove or
                              edit employees
                            </li>
                            <li>
                              Blocked users are former employees which might
                              still associate themselves with you, but havent
                              changed their personal profile, including their
                              relationship to your company.
                            </li>
                          </ul>
                        </Trans>
                      </Grid>
                      {/* } */}
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      {employeeDialog.action === 'add' && (
                        <SearchUserField transPrefix="SearchExistingUserField" />
                      )}
                    </Grid>

                    <Grid item xs={12} mt={2}>
                      <Typography variant="h6" textAlign="center">
                        {t('invite_user')}
                      </Typography>
                    </Grid>

                    {/* Employee Name */}
                    <Grid item xs={12} mt={2}>
                      <Stack direction="row" spacing={2}>
                        <IndividualFirstNameField
                          fieldID="first_name"
                          disabled={values?._id ? true : false}
                        />
                        <IndividualLastNameField
                          fieldID="last_name"
                          disabled={values?._id ? true : false}
                        />
                      </Stack>
                    </Grid>
                    {/* Employee Email */}
                    <Grid item xs={12} mt={2}>
                      <EmailField
                        disabled={values?._id ? true : false}
                        fieldID="email"
                      />
                    </Grid>
                    {/* Employee Role */}
                    <Grid item xs={12} mt={2}>
                      <EmployeeRoleField />
                    </Grid>
                  </Grid>
                </Form>
              </DialogContent>
              <DialogActions>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  {/* Cancel */}
                  <Box>
                    <IntuIconButton
                      type="cancel"
                      onClick={() => {
                        setEmployeeDialog(false);
                      }}
                      tooltipTitle="cancel"
                    />
                  </Box>

                  {/* Button */}
                  <Box display="flex" gap={2} sx={{ marginLeft: 'auto' }}>
                    {employeeDialog.action === 'edit' && (
                      <IntuIconButton
                        type="delete"
                        onClick={() =>
                          handleEmployeeUpdate({
                            values: values,
                            helpers: formik,
                            action: 'removeUser',
                          })
                        }
                        disabled={!values?._id ? true : false}
                        tooltipTitle={transButtons('delete_type', {
                          type: transTypes('employee'),
                        })}
                        tooltipType="employee"
                      />
                    )}

                    {employeeDialog.action === 'edit' && (
                      <IntuIconButton
                        type="update"
                        onClick={() =>
                          handleEmployeeUpdate({
                            values: values,
                            helpers: formik,
                            action: 'updateUser',
                          })
                        }
                        disabled={
                          employeeRolechange !== true || errors?.employee_role
                            ? true
                            : false
                        }
                        tooltipTitle={transButtons('update', {
                          type: transTypes('employee'),
                        })}
                        tooltipType="employee"
                      />
                    )}

                    {/* Add Button */}
                    {employeeDialog.action === 'add' && (
                      <IntuIconButton
                        type="add"
                        onClick={() =>
                          handleEmployeeUpdate({
                            values: values,
                            helpers: formik,
                            action: 'addUser',
                          })
                        }
                        disabled={!isValid}
                        tooltipTitle={transButtons('add', {
                          type: transTypes('employee'),
                        })}
                        tooltipType="employee"
                      />
                    )}
                  </Box>
                </Box>
              </DialogActions>
            </Dialog>
          );
        }}
      </Formik>
    </EmployeeDetailsDialogContext.Provider>
  );
};
