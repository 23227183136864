import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  alpha,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  KeyboardDoubleArrowDown,
} from '@mui/icons-material';
import Carousel from 'react-material-ui-carousel';
import { CarouselItem } from '../../mui/carousel/Carouseltem.jsx';
import { getFeaturedProducts } from '../../../api/products/productsRoutes.js';
import { motion } from 'framer-motion';
import i18n from 'i18next';
import { translateMultiLineText } from '../../../api/translation/google.js';
import { useNavigate } from 'react-router-dom';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';
import ARButton from '../../buttons/ARButton.jsx';

const titleVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'easeOut',
      delay: 0,
      duration: 0.5,
    },
  },
  hidden: {
    y: -300,
    opacity: 0,
    transition: {
      type: 'tween',
      ease: 'easeIn',
      duration: 0.3,
    },
  },
};

const descriptionVariants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'easeOut',
      delay: 0.1,
      duration: 0.5,
    },
  },
  hidden: {
    x: -500,
    opacity: 0,
    transition: {
      type: 'tween',
      ease: 'easeIn',
      duration: 0.3,
    },
  },
};

const interactButtonVariants = {
  initial: {
    opacity: 0,
    x: 300,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  default: {
    opacity: 1,
    x: 0,
    y: 0,
    transition: {
      type: 'tween',
      duration: 0.6,
      ease: 'easeOut',
    },
  },
  centered: {
    opacity: 1,
    x: '-50%',
    y: 0,

    top: 'auto',
    right: 'auto',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.6,
    },
  },
};

const viewMoreButtonVariants = {
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'tween',
      ease: 'easeOut',
      delay: 0.1,
      duration: 0.5,
    },
  },
  hidden: {
    y: 300,
    opacity: 0,
    transition: {
      type: 'tween',
      ease: 'easeIn',
      duration: 0.3,
    },
  },
};

const ProductHeroContainer = () => {
  const { t: transButtons } = useTranslation('buttons');

  const theme = useTheme();
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  // 3D Model
  const [isModelInteracting, setIsModelInteracting] = useState(false);
  const [wireframeMode, setWireframeMode] = useState(false);

  const [productDescription, setProductDescription] = useState([]);

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const fetchProductDescription = async ({ product }) => {
    if (!product || !product.descriptions) {
      setProductDescription([]);
      return;
    }

    const findLocalDescription = product?.descriptions.find(
      (description) => i18n.language === description.code,
    );

    if (findLocalDescription?.description) {
      setProductDescription(findLocalDescription?.description?.blocks || []);
    } else if (product.descriptions[0]?.description?.blocks) {
      try {
        const standardDescriptionText =
          product.descriptions[0].description.blocks.map((block) => block.text);

        const payload = {
          inputText: standardDescriptionText,
          targetLanguage: i18n.language,
        };

        const getTranslation = await translateMultiLineText(payload);
        const { data, statusCode } = getTranslation;

        if (statusCode === 200) {
          const translatedBlocks = data.map((text, index) => ({
            key: `${index}`,
            text,
            type:
              product.descriptions[0].description.blocks[index].type ||
              'unordered-list-item',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
          }));

          setProductDescription(translatedBlocks);
        } else {
          setProductDescription(
            product.descriptions[0]?.description?.blocks || [],
          );
        }
      } catch (error) {
        setProductDescription(
          product.descriptions[0]?.description?.blocks || [],
        );
      }
    }
  };

  const renderProductDescription = (blocks, productId) => {
    if (!blocks || blocks.length === 0) return null;

    // Combine all text blocks into a single string
    const combinedText = blocks.map((block) => block.text).join(' ');

    // Extract the first 200 characters as an excerpt
    const excerpt =
      combinedText.length > 100
        ? `${combinedText.slice(0, 120)}...`
        : combinedText;

    return (
      <Box
        sx={{
          position: 'absolute',
          bottom: '35%', // 👈 move this higher as needed
          left: '5%',
          backgroundColor: theme.palette.secondary.main,
          padding: '10px 20px',
          borderRadius: '5px',
          zIndex: 2,
          maxWidth: '60%',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: theme.palette.secondary.contrastText,
            display: 'inline',
          }}
        >
          {excerpt}
        </Typography>
        {combinedText.length >= 120 && (
          <Typography
            component="a"
            href={`/product/${productId}`}
            variant="body1"
            sx={{
              color: theme.palette.primary.main,
              textDecoration: 'none',
              marginLeft: '5px',
              // cursor: 'pointer',
            }}
          >
            {transButtons('more')}
          </Typography>
        )}
      </Box>
    );
  };

  useEffect(() => {
    getFeaturedProducts({ limit: 20, page: 1 })
      .then((res) => {
        const productData = res.data;

        const featuredProducts = productData.reduce((acc, product) => {
          if (Array.isArray(product.images)) {
            // Add regular product info
            let productInfo = {
              title: product.product_name,
              _id: product._id,
              productData: product, // Store the original product data for description fetching
            };

            const models = [];

            // Get GLTF Image
            const gltfModel = product.images.find(
              (img) => img.imageType?.toLowerCase() === 'gltf',
            );

            if (gltfModel) {
              models.push(gltfModel);
            }

            // GET USDZ Image
            const usdzModel = product.images.find(
              (img) => img.imageType?.toLowerCase() === 'usdz',
            );

            if (gltfModel) {
              models.push(usdzModel);
            }

            acc.push({
              ...productInfo,
              models: models,
              gltfModel: gltfModel,
            });
          }
          return acc;
        }, []);

        setProducts(featuredProducts);

        // Fetch Descriptions for All Products After Setting State
        featuredProducts.forEach(async (product) => {
          const description = await fetchProductDescription({
            product: product.productData,
          });
          setProducts((prevProducts) =>
            prevProducts.map((item) =>
              item._id === product._id ? { ...item, description } : item,
            ),
          );
        });
      })
      .catch((err) => console.warn(err));
  }, [i18n.language]);

  // 3D Model States
  const [isHovered, setIsHovered] = useState(false);

  // Handler to stop autoplay when hovered
  const handleMouseEnter = () => setIsHovered(true);

  // Handler to resume autoplay when not hovered
  const handleMouseLeave = () => setIsHovered(false);

  // Set WireFrame Animation
  useEffect(() => {
    if (isHovered) return; // pause wireframe toggle on hover

    const interval = setInterval(() => {
      setWireframeMode((prev) => !prev);
    }, 8000); // toggle every 3 seconds

    return () => clearInterval(interval); // cleanup
  }, [isHovered]);

  // Deal with scrolling behaviopt on mobile phones
  useEffect(() => {
    if (isModelInteracting) {
      return;
    }

    const preventTouch = (e) => e.preventDefault();

    if (isModelInteracting) {
      document.body.style.overflowY = 'hidden';
      document.addEventListener('touchmove', preventTouch, { passive: false });
    } else {
      document.body.style.overflowY = 'auto';
    }

    return () => {
      document.body.style.overflowY = 'auto';
      document.removeEventListener('touchmove', preventTouch);
    };
  }, [isModelInteracting]);

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      padding="1rem 0 1rem 0"
      textAlign="center"
      sx={{
        width: '100%',
        height: '100vh',
        alignContent: 'center',
      }}
    >
      {/* Main Header Content */}
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{ width: '100vw', height: '100vh' }}
      >
        <Carousel
          sx={{
            top: 0,
            flex: 1,
            width: '100vw',
            height: '100vh',
            position: 'relative',
            backgroundColor: 'grey.main',
          }}
          index={activeIndex}
          animation="slide"
          duration={180}
          interval={20000}
          indicators={false}
          navButtonsAlwaysVisible={!isSmall && products.length > 1}
          NextIcon={
            !isSmall ? (
              <ArrowForwardIos
                sx={{ color: (theme) => theme?.palette?.tertiary.contrastText }}
              />
            ) : null
          }
          PrevIcon={
            !isSmall ? (
              <ArrowBackIosNew
                sx={{ color: (theme) => theme?.palette?.tertiary.contrastText }}
              />
            ) : null
          }
          navButtonsProps={{
            style: {
              display: products.length > 1 ? 'inline-flex' : 'none',
            },
          }}
          // swipe={!isModelInteracting && mobileView}
          swipe={false}
          autoPlay={!isHovered && !isModelInteracting}
          onChange={(now) => setActiveIndex(now)}
          onMouseEnter={handleMouseEnter} // Stop autoplay on hover
          onMouseLeave={handleMouseLeave} // Resume autoplay when not hovered
        >
          {/* Carouse Content */}
          {products.map((product, i) => (
            <Box
              key={i}
              sx={{ position: 'relative', width: '100%', height: '100vh' }}
            >
              {/* Gray Overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  zIndex: 1,
                  pointerEvents: !isModelInteracting ? 'auto' : 'none',
                }}
              />

              {/* Image Rendered Below Overlay */}
              <CarouselItem
                key={i}
                image={product?.gltfModel}
                showSliders={false}
                viewMode="view"
                style={{
                  height: '100vh',
                  width: '100%',
                  objectFit: 'cover',
                }}
                wireframeMode={wireframeMode}
                setWireframeMode={setWireframeMode}
                isModelInteracting={isModelInteracting}
                initialCenter={true}
                enableZoom={isHovered && isModelInteracting}
                autoRotate={!isModelInteracting}
                autoRotateSpeed={0.3}
                showOrbitControls={!isHovered || isModelInteracting}
              />

              {/* Product Title Box - More to the Left */}
              <motion.div
                variants={titleVariants}
                initial="hidden"
                animate={isModelInteracting ? 'hidden' : 'visible'}
              >
                {/* Product Title */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '10%',
                    left: '5%',
                    backgroundColor: theme.palette.primary.main,
                    paddingLeft: '40px',
                    paddingRight: '40px',
                    paddingTop: '10px',
                    paddingBottom: 0,
                    borderRadius: '10px',
                    zIndex: 2,
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    {product.title}
                  </Typography>
                </Box>
              </motion.div>

              {/* Product Description */}
              {!isSmall ? (
                <motion.div
                  variants={descriptionVariants}
                  initial="hidden"
                  animate={isModelInteracting ? 'hidden' : 'visible'}
                >
                  {productDescription && (
                    <Box
                      sx={{
                        zIndex: 2,
                        maxWidth: '60%',
                      }}
                    >
                      {renderProductDescription(
                        productDescription,
                        product._id,
                      )}
                    </Box>
                  )}
                </motion.div>
              ) : null}

              {/* 3D Model Buttons */}
              <motion.div
                variants={interactButtonVariants}
                initial="initial"
                animate={isModelInteracting ? 'centered' : 'default'}
                style={{
                  position: 'absolute',
                  top: isModelInteracting ? 'auto' : '45%',
                  right: isModelInteracting ? 'auto' : '5%',
                  transform: isModelInteracting ? 'translate(-50%, 0)' : 'none',
                  left: isModelInteracting ? '50%' : 'auto',
                  bottom: isModelInteracting
                    ? isSmall
                      ? '15%'
                      : '5%'
                    : 'auto',
                  zIndex: 2,
                }}
              >
                <Box
                  display="flex"
                  flexDirection={isModelInteracting ? 'row' : 'column'}
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                  sx={{
                    backgroundColor: !isModelInteracting
                      ? alpha(theme.palette.grey.light, 0.8)
                      : '#ededed',
                    border: `1px solid ${alpha(theme.palette.grey.light, 0.8)}`,
                    borderRadius: 3,
                    padding: '1rem',
                  }}
                >
                  {/* Interact Button */}
                  <IntuIconButton
                    type={isModelInteracting ? 'interact_off' : 'interact_on'}
                    tooltipTitle={
                      isModelInteracting
                        ? 'disable_interaction'
                        : 'enable_interaction'
                    }
                    buttonColor={!isModelInteracting ? 'white' : null}
                    variant="outlined"
                    onClick={() => {
                      setIsModelInteracting((prev) => !prev);
                      if (!!isModelInteracting) {
                        setIsHovered(false);
                      }
                    }}
                  />

                  {/* 3D Wireframe Button */}
                  <IntuIconButton
                    type={wireframeMode ? 'wireframe_off' : 'wireframe_on'}
                    tooltipTitle={
                      wireframeMode ? 'disable_wireframe' : 'enable_wireframe'
                    }
                    variant="outlined"
                    buttonColor={!isModelInteracting ? 'white' : null}
                    onClick={() => setWireframeMode((prev) => !prev)}
                  />

                  {/* AR Button */}
                  <ARButton
                    models={products[activeIndex]?.models}
                    color={!isModelInteracting ? 'white' : null}
                    variant="outlined"
                  />
                </Box>
              </motion.div>

              {/* View Product Button & Scroll Down Button */}
              <motion.div
                variants={viewMoreButtonVariants}
                initial="hidden"
                animate={isModelInteracting ? 'hidden' : 'visible'}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: { xs: '11%', md: '5%' },
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      padding: '15px 30px',
                      fontSize: '1rem',
                      backgroundColor:
                        theme?.palette?.primary?.main || '#1976d2',
                      '&:hover': {
                        backgroundColor:
                          theme?.palette?.primary?.dark || '#1565c0',
                      },
                    }}
                    onClick={() => navigate(`/product/${product._id}`)}
                  >
                    {transButtons('view_product')}
                  </Button>

                  {/* Two flashing arrows below */}
                  <Box
                    sx={{
                      marginTop: '1rem',
                      cursor: 'pointer',
                      animation: 'bounce 1.5s infinite',
                      '@keyframes bounce': {
                        '0%, 100%': {
                          transform: 'translateY(0)',
                          opacity: 1,
                        },
                        '50%': {
                          transform: 'translateY(10px)',
                          opacity: 0.5,
                        },
                      },
                    }}
                    onClick={() => {
                      const target = document.getElementById('homeContent');
                      if (target) {
                        target.scrollIntoView({ behavior: 'smooth' });
                      }
                    }}
                  >
                    <KeyboardDoubleArrowDown
                      sx={{
                        fontSize: '3rem',
                        color: 'white',
                      }}
                    />
                  </Box>
                </Box>
              </motion.div>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default ProductHeroContainer;
