import { useMediaQuery, useTheme, Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import '@google/model-viewer';
import IntuIconButton from '../buttons/IntuIconButton';
import { useTranslation } from 'react-i18next';

const ARButton = ({
  models, // An awway containing GLTF and USDZ file,
  color, // overwrite button color
  variant = 'outlined',
}) => {
  const { t: transButtons } = useTranslation('buttons');
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const [platform, setPlatform] = useState('desktop');
  const [gltfUrl, setGltfUrl] = useState(null);
  const [usdzUrl, setUsdzUrl] = useState(null);
  const [imageId, setImageId] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isIOS = /iPhone|iPad|iPod/.test(userAgent);
    const isAndroid = /Android/.test(userAgent);

    if (isIOS) setPlatform('ios');
    else if (isAndroid) setPlatform('android');
    // else setPlatform('desktop');

    if (Array.isArray(models)) {
      const gltf = models.find(
        (img) => img?.imageType?.toLowerCase() === 'gltf',
      );
      const usdz = models.find(
        (img) => img?.imageType?.toLowerCase() === 'usdz',
      );
      if (gltf) {
        setGltfUrl(gltf.url);
        setImageId(gltf.name);
      }
      if (usdz) {
        setUsdzUrl(usdz.url);
        setImageId(usdz.name);
      }
    }
  }, [models]);

  if (platform === 'desktop') return null;

  if (platform === 'ios' && usdzUrl) {
    return (
      <a rel="ar" href={usdzUrl}>
        <IntuIconButton
          type="ar"
          tooltipTitle={transButtons('ar_view')}
          variant={variant}
          buttonColor={color}
        />
      </a>
    );
  }

  if (platform === 'android' && gltfUrl) {
    return (
      <>
        <model-viewer
          id={`ar-viewer-${imageId}`}
          src={gltfUrl}
          ar
          ar-modes="scene-viewer webxr quick-look"
          style={{ display: 'none' }}
        />

        <IntuIconButton
          type="ar"
          tooltipTitle={transButtons('ar_view')}
          variant={variant}
          buttonColor={color}
          onClick={() => {
            const viewer = document.getElementById(`ar-viewer-${imageId}`);
            if (viewer) viewer.activateAR();
          }}
        />
      </>
    );
  }

  return null;
};

export default ARButton;
