import React, { useContext, useRef, useState } from 'react';
import FormikFieldWrapper from '../formik/FormikFieldWrapper';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { getNestedValue } from '../../helpers/getNestedValuesIn';
import ProcessingContext from '../../context/providers/ProcessingProvider';
import { CircularProgress, Skeleton } from '@mui/material';
import axios from 'axios';

const PhoneField = ({
  fieldTitle,
  fieldDescription,
  fieldID = 'phone',
  transNS = 'fields',
  transTypeID = 'user',
  transPrefix = 'account.PhoneField',
  transition = true,
  disabled = false,
  required = false,
  variant = 'outlined',
  onChange,
  onBlur,
  ...props
}) => {
  const phoneRef = useRef();
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });
  const { t: transTypes } = useTranslation('types');
  const { isLoading } = useContext(ProcessingContext);

  const { values, touched, errors, handleBlur, setFieldValue, setFieldError } =
    useFormikContext();

  const [isValidating, setIsValidating] = useState(false);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  const validatePhoneNumber = async (phoneValue) => {
    const controller = new AbortController();
    const signal = controller.signal;
    const payload = {
      phone: phoneValue,
    };
    setIsValidating(true);

    try {
      const url = '/api/twillio/phone/validate';
      const validate = await axios.post(url, payload, {
        signal,
        headers: { 'Content-Type': 'application/json' },
      });

      const { data } = validate?.data;

      if (!data.valid) {
        setFieldError(
          `${fieldID}.info.nationalNumber`,
          'Invalid or unreachable phone number',
        );
      }
    } catch (error) {
      setFieldError(
        `${fieldID}.info.nationalNumber`,
        'Validation failed. Try again.',
      );
    } finally {
      controller.abort(signal);
      setIsValidating(false);
    }
  };

  return isLoading.status ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <FormikFieldWrapper
      fieldTitle={fieldTitle}
      fieldDescription={fieldDescription}
      transition={transition}
    >
      <MuiTelInput
        {...props}
        required={required}
        disabled={disabled}
        defaultCountry={values?.info?.countryCode || 'US'}
        ref={phoneRef}
        id={fieldID}
        variant={variant}
        name={fieldID}
        autoComplete="off"
        className="form-select-field"
        aria-invalid={errors[fieldID] ? 'true' : 'false'}
        label={transFields('label', { type: transTypes(transTypeID) })}
        placeholder={transFields('placeholder', {
          type: transTypes(transTypeID),
        })}
        InputProps={{
          endAdornment: isValidating ? <CircularProgress size={24} /> : null,
        }}
        onChange={(value, info) => {
          if (onChange) {
            onChange(value, info);
          } else {
            setFieldValue(fieldID, { value: value, info });
          }
        }}
        onBlur={async (e) => {
          if (onBlur) {
            onBlur(e);
          } else {
            handleBlur(e);
            if (fieldValue?.value) {
              await validatePhoneNumber(fieldValue.value);
            }
          }
        }}
        value={fieldValue?.value}
        error={
          fieldError && fieldTouched && fieldError?.info?.nationalNumber
            ? true
            : false
        }
        helperText={
          fieldError?.info?.nationalNumber && fieldTouched
            ? fieldError?.info?.nationalNumber
            : null
        }
      />
    </FormikFieldWrapper>
  );
};

export default PhoneField;
