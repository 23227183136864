import { useState, useEffect, memo, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import { useProcessingHandler } from '../../../hooks/useProcessingHandler.js';
import useResponseHandling from '../../../hooks/useResponseHandler.js';
import { Trans, useTranslation } from 'react-i18next';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ListLoader from '../../status/ListLoader.jsx';
import { CompanyProfileContext } from '../../../context/company/CompanyProfileProvider.jsx';
import {
  createNewProduct,
  getAllProducts,
} from '../../../api/products/productsRoutes.js';
import { Stars } from '@mui/icons-material';
import ProductStatusIcon from './ProductStatusIcon.jsx';
import GLTFFileContainer from '../containers/GLTFContainer.jsx';

const createProductColumns = ({
  t,
  transButtons,
  handleEditClick,
  handleViewClick,
}) => {
  return [
    {
      field: 'image',
      headerName: 'Image',
      sortable: false,
      filterable: false,
      editable: false,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const productDetails = params.row;

        const [image] = productDetails?.images;
        const imageType = image?.imageType?.toLowerCase();

        if (imageType !== 'gltf') {
          return (
            <Box
              component="img"
              src={image?.url}
              alt="Product"
              sx={{
                height: 60,
                width: 60,
                objectFit: 'cover',
                borderRadius: 1,
                display: 'block',
                mx: 'auto',
              }}
            />
          );
        } else {
          return <GLTFFileContainer image={image} />;
        }
      },
    },
    {
      field: 'product_name',
      headerName: t('columns.product_name'),
      editable: false,
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'mpn',
      headerName: t('columns.mpn'),
      editable: false,
      minWidth: 50,
    },
    {
      field: 'staffpick',
      headerName: t('columns.staffpick'),
      editable: false,
      centerCell: true,
      flex: 0.3,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const picked = params.row?.staffpick;
        return picked ? <Stars /> : null;
      },
    },
    {
      field: 'list_price',
      headerName: t('columns.list_price'),
      editable: false,
      renderCell: (params) => {
        return (
          <span>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(params.row.list_price)}
          </span>
        );
      },
    },
    {
      field: 'min_price',
      headerName: t('columns.min_price'),
      editable: false,
      renderCell: (params) => {
        return (
          <span>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(params.row.min_price)}
          </span>
        );
      },
    },
    {
      field: 'status',
      headerName: t('columns.status'),
      editable: false,
      renderCell: (params) => {
        const status = params.row?.status;

        return <ProductStatusIcon status={params.row.status} />;
      },
    },
    {
      field: 'view',
      headerName: t('columns.view'),
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="info"
            iconOnly={true}
            variant="outlined"
            tooltipTitle={transButtons('view')}
            onClick={() => handleViewClick(params)}
            IconComponent={VisibilityIcon}
          />
        );
      },
    },
    {
      field: 'edit',
      headerName: t('columns.edit'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="edit"
            variant="outlined"
            iconOnly={true}
            onClick={() => handleEditClick(params)}
            tooltipTitle={transButtons('edit')}
          />
        );
      },
    },
  ];
};

const CompanyProductList = () => {
  const [products, setProducts] = useState([]);
  const { handleErrorResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.CompanyProductList',
  });
  const { t: transMessages } = useTranslation('messages', {
    keyPrefix: 'products.product_list',
  });
  const { t: transButtons } = useTranslation('buttons');

  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isListLoading, setIsListLoading] = useState(false);

  const { companyProfile } = useContext(CompanyProfileContext);

  const handleEditClick = (params) => {
    navigate(`/app/edit-product/${params.id}`);
  };

  const handleViewClick = (params) => {
    window.open(`/product/${params.row._id}?preview=true`, '_blank');
  };

  const productColumns = useMemo(() => {
    return createProductColumns({
      t,
      transButtons,
      handleEditClick,
      handleViewClick,
    });
  }, [t, handleEditClick, handleViewClick]);

  const getProducts = async () => {
    setIsListLoading(true);

    try {
      const { data, statusCode } = await getAllProducts();

      // console.log('Company Products Data -> ', data);

      if (statusCode === 200) {
        setProducts(data);
      }
    } catch (error) {
      console.error('error', error);
      handleErrorResponse(error);
    } finally {
      setIsListLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const createProduct = async () => {
    setIsLoading({
      status: true,
      type: 'spinner',
      text: t('spinner.new_product'),
    });

    try {
      const { data, statusCode } = await createNewProduct();

      const [productData] = data;

      // console.log('New Product Data ->', productData);

      if (statusCode === 200) {
        navigate(`/app/new-product/${productData._id}`);
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  };

  return (
    <Grid
      container
      className="content"
      sx={{ marginBottom: '8rem' }}
      spacing={5}
    >
      <Grid item className="content" xs={12}>
        {companyProfile.isAdmin || companyProfile.isSuperUser ? (
          <Box display="flex" justifyContent="flex-end" mb={4}>
            <Button
              variant="contained"
              type="submit"
              endIcon={<RocketLaunchIcon />}
              onClick={createProduct}
              sx={{ minWidth: 150, maxHeight: 40 }}
            >
              {transButtons('new_product')}
            </Button>
          </Box>
        ) : null}
        {isListLoading ? (
          <ListLoader columns={8} rows={5} />
        ) : !products?.length ? (
          <Grid item className="title content-centered" xs={12}>
            <Typography variant="h6">
              {companyProfile.isAdmin ||
                (companyProfile.isSuperUser ? (
                  <Trans t={transMessages} i18nKey="no_products_alt_admin" />
                ) : (
                  <Trans t={transMessages} i18nKey="no_products_alt_user" />
                ))}
            </Typography>
          </Grid>
        ) : (
          <Box height="auto" width="100%">
            <DataGrid
              isCellEditable={() => false}
              getRowId={(row) => row._id}
              rows={products}
              columns={productColumns}
              columnVisibilityModel={{
                list_price: !isMobileView && !isMediumView,
                min_price: !isMobileView && !isMediumView,
                attributes: !isMobileView && !isMediumView,
                applications: !isMobileView && !isMediumView,
                product_group: !isMobileView && !isMediumView,
                mpn: !isMobileView && !isMediumView,
                status: !isMobileView && !isMediumView,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 100,
                  },
                },
                sorting: {
                  sortModel: [{}],
                },
              }}
              pageSizeOptions={[100, 250, 500]}
              checkboxSelection={false}
              disableRowSelectionOnClick
              disableColumnMenu
              slots={{
                toolbar: GridToolbar,
                Row: MemoizedRow,
                ColumnHeaders: MemoizedColumnHeaders,
              }}
              sx={{
                borderRadius: '12px',
                boxShadow: 6,
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
                '& .MuiTablePagination-select': {
                  color: theme.palette.text.primary,
                },
              }}
            />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default CompanyProductList;
