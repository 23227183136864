import { Add, Remove } from '@mui/icons-material';
import { Box, Button, IconButton, TextField, Typography } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PublicProductPageContext } from '../../pages/products/PublicProductPage';
import { CartContext } from '../../context/cart/CartContext';
import { useTranslation } from 'react-i18next';

const AddToCartBox = ({ productRepresentatives, referalID }) => {
  const navigate = useNavigate();
  const { quantity, setQuantity, product, setIsBuyDialogOpen } = useContext(
    PublicProductPageContext,
  );

  const { cartProducts, setCartProducts } = useContext(CartContext);

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.AddToCartBox',
  });

  const { t: transButtons } = useTranslation('buttons');

  const handleBuyClick = () => {
    // Check if product already exists in cart
    const productInCart = cartProducts.find(
      (p) => p.product._id === product._id,
    );
    if (productInCart) {
      navigate('/cart');
      setCartProducts((prev) => {
        return prev.map((p) => {
          if (p.product._id === productInCart.product._id) {
            return {
              ...p,
              quantity: p.quantity + quantity,
            };
          }
          return p;
        });
      });
      return;
    }

    // Check if there are any non Intutec sales reps
    const foundSalesRep = productRepresentatives.find(
      (rep) => rep.account_type !== 'intutec',
    );

    if (foundSalesRep) {
      setIsBuyDialogOpen(true);
      return;
    } else {
      navigate('/cart');

      // Set to intutec associate as appointedRep as default
      const intutecAssociate = productRepresentatives.find(
        (rep) =>
          rep.account_type === 'intutec' && rep.email === 'sales@in2tec.io',
      );

      setCartProducts((prev) => {
        return [
          ...prev,
          {
            product,
            quantity,
            appointedRep: intutecAssociate,
            referalID,
          },
        ];
      });
    }
  };

  const handleQuantityChange = (amount) => {
    setQuantity((prev) => Math.max(1, prev + amount));
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        width="100%"
        gap={2}
        mb={4}
      >
        {/* Price */}
        <Box mt={1} display="flex" alignItems="center" justifyContent="center">
          <Typography variant="p" mr={1}>
            {t('price')}
          </Typography>
          <Typography
            variant="p"
            fontSize="20px"
            fontWeight="bold"
            // color="primary"
          >
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: product.currency.symbol,
            }).format(Number(product.list_price).toFixed(2))}
          </Typography>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={{ xs: 'column', md: 'row' }}
          width="100%"
          gap={2}
        >
          {/* Quantity */}
          <Box display="flex">
            <IconButton onClick={() => handleQuantityChange(-1)}>
              <Remove />
            </IconButton>
            <TextField
              type="number"
              value={quantity}
              onChange={(e) =>
                setQuantity(Math.max(1, parseInt(e.target.value) || 1))
              }
              inputProps={{ min: 1, style: { textAlign: 'center' } }}
              sx={{ mx: 1, minWidth: 50, width: 50 }}
              InputProps={{
                sx: {
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                },
              }}
            />
            <IconButton onClick={() => handleQuantityChange(1)}>
              <Add />
            </IconButton>
          </Box>

          {/* Add To Cart button */}
          <Box display="flex">
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleBuyClick()}
              sx={{ width: '150px' }}
            >
              {transButtons('add_to_cart')}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { AddToCartBox };
