import { useState } from 'react';
import CanvasContainer from '../../new-product/container/image/CanvasContainer';
import { Box } from '@mui/material';

const GLTFFileContainer = ({ image }) => {
  const [initialMeshes, setInitialMeshes] = useState([]);
  const [meshes, setMeshes] = useState([]);
  const [cameraPosition, setCameraPosition] = useState([0, 0, 0]);
  const [rotationX, setRotationX] = useState(0);
  const [rotationY, setRotationY] = useState(0);
  const [isProductImageDirtied, setIsProductImageDirtied] = useState(false);

  return (
    <Box minHeight={80} height={80} width="100%">
      <CanvasContainer
        url={image.url}
        options={image.options}
        viewMode="view"
        showSliders={false}
        minHeight="80px"
        rotationX={rotationX}
        setRotationX={setRotationX}
        rotationY={rotationY}
        setRotationY={setRotationY}
        cameraPosition={cameraPosition}
        setCameraPosition={setCameraPosition}
        setIsProductImageDirtied={setIsProductImageDirtied}
        meshes={meshes}
        setMeshes={setMeshes}
        setInitialMeshes={setInitialMeshes}
        initialCenter={true}
      />
    </Box>
  );
};

export default GLTFFileContainer;
