import { useRef, useState } from 'react';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsTracker.jsx';
import { useNavigate } from 'react-router-dom';
import bcrypt from 'bcryptjs';
import { Formik, Form } from 'formik';
import {
  Button,
  Stack,
  FormControl,
  CircularProgress,
  Box,
  Typography,
} from '@mui/material';
import { classes } from '../../../../settings/theme.js';
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n.js';
import { createSignUpValidationSchema } from './ValidationSchema.js';
import { createSignUpInitialValues } from './InitialValues.js';
import AccountTypeField from '../../fields/type/AccountTypeField.jsx';
import IndividualFirstNameField from '../../individual/IndividualFirstNameField.jsx';
import IndividualLastNameField from '../../individual/IndividualLastNameField.jsx';
import EmailField from '../../fields/email/EmailField.jsx';
import PasswordField from '../../PasswordField.jsx';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { createUserAccount } from '../../../../api/usersRoutes.js';
import LinkedInSignUpDialog from './LinkedInSignUpDialog.jsx';

const SignUpForm = ({ firstName, lastName, type, email }) => {
  const { gaEventTracker } = useAnalyticsEventTracker();

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  // State & Context
  const [linkedInDialogOpen, setLinkedInDialogOpen] = useState(false);

  // SALT should be created ONE TIME upon sign up
  const salt = bcrypt.genSaltSync(10);

  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const accType = params.get('accType') || type;

  const host_url = window.location.host;

  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignUpForm',
  });

  const { t: transButtons } = useTranslation('buttons');

  // Setup Initial Values for Form Validation
  const initialValues = createSignUpInitialValues({
    firstName,
    lastName,
    type: accType,
    email,
  });

  // Validation Schema
  const validationSchema = createSignUpValidationSchema();
  const formRef = useRef(null);

  //handle form submission process
  async function submitForm(values, formik) {
    const hashedPassword = bcrypt.hashSync(values.password, salt);

    let account_type = values.account_type;
    let first_name = values.first_name;
    let last_name = values.last_name;
    let email = values.email;
    let password = hashedPassword;

    //create new user account
    const payload = {
      account_type,
      first_name,
      last_name,
      email,
      password,
      host_url: host_url,
    };

    try {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: i18n.t('form.message.sign_up'),
      });

      const response = await createUserAccount(payload);

      const { data, status } = response;

      if (status === 200) {
        //user created
        formik.resetForm();
        gaEventTracker({
          category: 'account',
          action: 'events',
          label: 'New Account SignUp',
          value: 100,
        });
        navigate(`${data.navigate_to}&userAction=accConfirm`, {
          replace: true,
        });
      } else if (status === 230) {
        // user pending
        formik.resetForm();
        setIsLoading({ status: false, type: '', text: '' });
        navigate(`${data.navigate_to}&userAction=accConfirm`, {
          replace: true,
        });
      } else if (status === 231) {
        formik.resetForm();
        setTimeout(() => formik.validateForm(), 300);
        handleRegularResponse({
          open: true,
          status: 'error',
          message: data.message,
        });
      } else {
        // all other responses
        handleRegularResponse({
          open: true,
          status: 'error',
          message: data.message,
        });
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formik) => submitForm(values, formik)}
      validateOnChange={true}
      enableReinitialize={true}
      validateOnMount={true}
      innerRef={formRef}
    >
      {(formik) => {
        const { handleSubmit, isValid, isSubmitting } = formik;

        return (
          <Box display="flex" flexDirection="column" gap={3}>
            {/* LinkedIn SinUp Button */}
            <Button
              variant="linkedin"
              type="button"
              sx={{
                backgroundColor: (theme) => theme.palette.linkedIn.main,
                color: (theme) => theme.palette.linkedIn.contrastText,
                '&:hover': {
                  backgroundColor: (theme) => theme.palette.linkedIn.dark,
                },
                border: (theme) => theme.palette.linkedIn.main,
              }}
              onClick={() => setLinkedInDialogOpen(true)}
              startIcon={<LinkedInIcon sx={{ color: '#fff' }} />}
            >
              {transButtons('sign_up_linkedin')}
            </Button>
            <Typography textAlign="center" variant="h5">
              {t('manual_signup')}
            </Typography>
            <Form
              className="form-horizontal"
              role="form"
              onSubmit={handleSubmit}
              style={{ width: '100%' }}
            >
              <FormControl fullWidth>
                <Stack
                  spacing={{ xs: 2, sm: 2 }}
                  sx={{ paddingBottom: '50px' }}
                  direction="column"
                >
                  <AccountTypeField required />
                  <Stack
                    spacing={{ xs: 2, md: 1 }}
                    direction={{ xs: 'column', sm: 'row' }}
                  >
                    <IndividualFirstNameField required fieldID="first_name" />
                    <IndividualLastNameField required fieldID="last_name" />
                  </Stack>
                  <Stack spacing={4} style={classes.root}>
                    {/* Email address & Phone Number */}
                    <EmailField
                      required
                      fieldID="email"
                      transNS="fields"
                      transTypeID="your"
                      transPrefix="account.EmailField"
                    />
                  </Stack>
                  {/* User Password and Confirmation */}
                  <Stack
                    spacing={{ xs: 2, md: 1 }}
                    direction={{ xs: 'column', sm: 'row' }}
                    style={classes.root}
                  >
                    {/* Define Password */}
                    <PasswordField type="define" fieldID="password" />
                    {/* Password Confirmation */}
                    <PasswordField type="confirm" fieldID="passwordConf" />
                  </Stack>
                </Stack>
                <Box
                  display="flex"
                  flexDirection="row"
                  sx={{ paddingBottom: '50px', width: '100%' }}
                >
                  <Button
                    sx={{ marginLeft: 'auto' }}
                    variant="submit"
                    type="submit"
                    startIcon={
                      isSubmitting ? <CircularProgress size="1rem" /> : null
                    }
                    disabled={isSubmitting || !isValid}
                  >
                    {transButtons('sign_up')}
                  </Button>
                </Box>
              </FormControl>
            </Form>
            <LinkedInSignUpDialog
              linkedInDialogOpen={linkedInDialogOpen}
              setLinkedInDialogOpen={() => setLinkedInDialogOpen(false)}
            />
          </Box>
        );
      }}
    </Formik>
  );
};

export { SignUpForm };
