import { useRef, useContext, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';

// Materail UI
import { Stack, FormControl, Grid, Button } from '@mui/material';
import { classes } from '../../settings/theme.js';

// Import User Context
import useAuth from '../../hooks/useAuth.js';
import { useTranslation } from 'react-i18next';

import { createUserProfileValidationSchema } from './ValidationSchema.js';
import { UserProfileContext } from '../../context/users/UserProfileProvider.jsx';
import EmailField from '../../components/account/fields/email/EmailField.jsx';
import IndividualFirstNameField from '../../components/account/individual/IndividualFirstNameField.jsx';
import IndividualLastNameField from '../../components/account/individual/IndividualLastNameField.jsx';
import ChangeUserEmailDialog from '../../components/account/changeEmail/ChangeUserEmailDialog.jsx';
import UserProfilePicture from '../../components/account/individual/IndividualProfilePicture.jsx';
import { NewCompanyFormDialog } from '../company/newCompany/NewCompanyFormDialog.jsx';
import FormikTabs from '../formik/FormikTabs.jsx';
import AboutMeTab from './tabs/AboutMeTab.jsx';
import PersonalContactTab from './tabs/PersonalContactTab.jsx';
import SecurityTab from './tabs/SecurityTab.jsx';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext.jsx';
import { useProcessingHandler } from '../../hooks/useProcessingHandler.js';
import { SaveAlt } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import ExpertiseTab from './tabs/ExpertiseTab.jsx';
import AccountTab from './tabs/AccountTab.jsx';
import useValidateFormikForm from '../../helpers/forms/useValidateFormikForm.jsx';
import FormikValidationErrorDialog from '../status/FormikValidationErrorDialog.jsx';

// FORM CONTENT
const UserProfileForm = () => {
  // Translator
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.UserProfileForm',
  });
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');

  const { setFooterConfig } = useNavigationFooter();
  const navigate = useNavigate();
  const { userProfile, updateUserProfile, setChangeEmailDialog } =
    useContext(UserProfileContext);

  const { validate, dialogState, handleClose } =
    useValidateFormikForm('profile');

  const { auth } = useAuth();

  const accountType = auth.user_info.account_type;

  const userProfileValidationSchema = createUserProfileValidationSchema({
    accountType,
  });

  const { isLoading } = useProcessingHandler();

  const [isReinitialize, setIsReinitialize] = useState(true);

  // Set Up Form refs
  const formikRef = useRef(null);

  const profileTabs = [
    {
      title: t('tabs.about'),
      content: <AboutMeTab />,
    },
    {
      title: t('tabs.contact'),
      content: <PersonalContactTab />,
    },
    {
      title: t('tabs.expertise'),
      content: <ExpertiseTab />,
    },
    {
      title: t('tabs.security'),
      content: <SecurityTab />,
    },
    {
      title: t('tabs.account'),
      content: <AccountTab />,
    },
  ];

  // Set Footer Navigation
  useEffect(() => {
    setFooterConfig((prev) => ({
      ...prev,
      confirmLabel: transButtons('update', {
        type: transTypes('profile'),
      }),
      ConfirmIcon: SaveAlt,
      onConfirm: async () => {
        const formik = formikRef.current;

        if (formik) {
          const isValid = await validate(formik);

          if (isValid) {
            const update = await updateUserProfile(formikRef.current.values);

            if (update.status === 'error ') {
              formikRef.current.resetForm();
            } else {
              formikRef.current.setTouched({}, false);
            }
          } else {
            return;
          }
        }
      },
      isLoading: isLoading.status,
      // isDisabled: !formikRef.current.isValid,
      showFooter: true,
      onBack: () => {
        formikRef.current.resetForm();
        navigate(-1);
      },

      showConfirm: true,
    }));

    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, [isLoading.status, formikRef?.current?.isValid]);

  useEffect(() => {
    if (formikRef.current) {
      const allTouched = Object.keys(userProfile).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {});
      formikRef.current.setTouched(allTouched, true);
    }
  }, [isReinitialize]);

  return (
    <Grid item className="content" xs={12} sx={{ marginBottom: '8rem' }}>
      <Formik
        values={userProfile}
        initialValues={userProfile}
        validationSchema={userProfileValidationSchema}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={isReinitialize}
        innerRef={formikRef}
      >
        {(formik) => {
          const { values, errors, isValid } = formik;

          console.log('Values -> ', values);
          // console.log('Errors -> ', errors);
          // console.log('IsValid -> ', isValid);

          return (
            <Form className="form-horizontal intu__form" role="form">
              <FormControl fullWidth>
                <Stack
                  alignItems="stretch"
                  spacing={4}
                  style={classes.root}
                  sx={{ paddingBottom: '30px' }}
                >
                  <Grid
                    container
                    variant="mobileWidthFix"
                    spacing={{ xs: 10, sm: 8, md: 1 }}
                    justifyContent="center"
                    direction={{
                      xs: 'column',
                      sm: 'column',
                      md: 'row',
                    }}
                  >
                    <Grid
                      variant="mobileAlignFix"
                      container
                      item
                      xs={3}
                      alignItems="center"
                      justifyContent="center"
                      sx={{ paddingLeft: 0 }}
                    >
                      {/* Profile Picture  */}
                      <UserProfilePicture
                        src={userProfile?.profile_picture_view_url}
                        initials={userProfile?.initials}
                        setReinitialize={setIsReinitialize}
                      />
                    </Grid>
                    <Grid variant="mobileAlignFix" item sm={12} md={9}>
                      <Stack
                        spacing={{ xs: 3, sm: 3, md: 3 }}
                        direction={{ xs: 'column' }}
                        style={classes.root}
                        alignItems="stretch"
                      >
                        {/* Personal Details */}
                        <Stack
                          spacing={{ xs: 3, sm: 3, md: 1 }}
                          direction={{
                            xs: 'column',
                            sm: 'column',
                            md: 'row',
                          }}
                          style={classes.root}
                          alignItems="stretch"
                        >
                          {/* First Name */}
                          <IndividualFirstNameField
                            required={true}
                            fieldID="first_name"
                          />

                          {/* Last Name */}
                          <IndividualLastNameField
                            required={true}
                            fieldID="last_name"
                          />
                        </Stack>

                        {/* User Email */}
                        <EmailField
                          fieldID="email"
                          required={true}
                          disabled={true}
                          transNS="fields"
                          transPrefix="account.individual.EmailField"
                        />

                        <Grid item xs={4}>
                          <Button
                            onClick={() => {
                              setChangeEmailDialog(true);
                            }}
                            color="warning"
                          >
                            {transButtons('change_email')}
                          </Button>
                        </Grid>

                        {/* Change User Email */}
                        <ChangeUserEmailDialog />
                      </Stack>
                    </Grid>
                  </Grid>

                  <FormikTabs tabsContent={profileTabs} isScrollable={true} />
                </Stack>
                <NewCompanyFormDialog
                  info={{ name: values?.individual?.company?.name }}
                />
              </FormControl>
            </Form>
          );
        }}
      </Formik>
      <FormikValidationErrorDialog
        open={dialogState.open}
        title={dialogState.title}
        message={dialogState.message}
        onClose={handleClose}
      />
    </Grid>
  );
};

export { UserProfileForm };
