import { Box, Grid } from '@mui/material';

const PageHeader = (props) => {
  return (
    <Box
      className="intu__divider"
      sx={{ padding: 0, margin: 0, width: '100%' }}
    >
      <Box>{props?.body}</Box>
    </Box>
  );
};

export default PageHeader;
