import React, { useContext, useRef } from 'react';

// Formik
import { Formik, useFormikContext } from 'formik';

import { createChangeUserEmailValidationSchema } from './ValidationSchema.js';
import { createChangeUserEmailInitialValues } from './InitialValues.js';

// Response Habndling

// Translation
import { useTranslation } from 'react-i18next';

// Maeterial UI
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { classes } from '../../../settings/theme.js';

// Context
import { UserProfileContext } from '../../../context/users/UserProfileProvider.jsx';
import useUser from '../../../hooks/useUser.js';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate.js';
import useAuth from '../../../hooks/useAuth.js';
import FormikFieldWrapper from '../../formik/FormikFieldWrapper.jsx';
import useResponseHandling from '../../../hooks/useResponseHandler.js';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';
import { ChangeCircle, Email } from '@mui/icons-material';

const ChangeUserEmailDialog = () => {
  const {
    userProfile,
    setUserProfile,
    showOTP,
    setShowOTP,
    changeEmailDialog,
    setChangeEmailDialog,
  } = useContext(UserProfileContext);

  //   User Context
  const { user } = useUser();
  const { setAuth } = useAuth();

  //   Axios SetUp
  const axios = useAxiosPrivate();

  //   Set Host URL
  const host_url = window.location.host;

  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { values: parentFormValues, setValues: setParentformValues } =
    useFormikContext();

  //SetUp Trnaslation
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.users.ChangeUserEmailDialog',
  });

  // Set Up Form refs
  const errRef = useRef();
  const formRef = useRef(null);

  const initialValues = createChangeUserEmailInitialValues();
  const validationSchema = createChangeUserEmailValidationSchema();

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        validateOnChange={true}
        enableReinitialize={true}
        innerRef={formRef}
      >
        {(formik) => {
          const {
            values,
            setFieldValue,
            errors,
            touched,
            handleBlur,
            setValues,
            resetForm,
          } = formik;
          return (
            <Dialog maxWidth="sm" fullWidth open={changeEmailDialog}>
              <form>
                <Typography variant="dialogBoxTitle">
                  {!showOTP ? t('change.title') : t('confirm.title')}
                </Typography>
                <DialogContent>
                  <Typography variant="dialogBoxContent">
                    {!showOTP
                      ? t('change.description')
                      : t('confirm.description')}
                  </Typography>
                  {/* New Email Address */}
                  <FormikFieldWrapper>
                    <TextField
                      required
                      id="new_email"
                      name="new_email"
                      autoComplete="off"
                      className="form-select-field"
                      aria-invalid={errors?.new_email ? 'true' : 'false'}
                      aria-describedby="uidnote"
                      variant="standard"
                      label={t('form.new_email.label')}
                      placeholder={t('form.new_email.placeholder')}
                      type="text"
                      margin="dense"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          new_email: e.target.value,
                          otp: '',
                        });
                        setShowOTP(false);
                      }}
                      onBlur={handleBlur}
                      inputProps={{
                        autoComplete: 'off',
                      }}
                      value={values?.new_email}
                      error={
                        errors?.new_email && touched?.new_email ? true : false
                      }
                      helperText={
                        errors?.new_email && touched?.new_email
                          ? errors?.new_email
                          : null
                      }
                    />
                  </FormikFieldWrapper>
                  {/* OTP */}
                  <FormikFieldWrapper transition={showOTP}>
                    <TextField
                      required
                      style={classes.root}
                      id="userOTP"
                      name="userOTP"
                      className="form-select-field"
                      autoComplete="off"
                      aria-invalid={errors.userOTP ? 'true' : 'false'}
                      aria-describedby="uidnote"
                      variant="standard"
                      margin="dense"
                      label={t('form.userOTP.label')}
                      placeholder={t('form.userOTP.placeholder')}
                      type="password"
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value);
                      }}
                      inputProps={{
                        autoComplete: 'one-time-code',
                      }}
                      onBlur={handleBlur}
                      value={values.userOTP}
                      error={errors.userOTP && touched.userOTP ? true : false}
                      helperText={
                        errors.userOTP && touched.userOTP
                          ? errors.userOTP
                          : null
                      }
                    />
                  </FormikFieldWrapper>
                </DialogContent>
                <DialogActions>
                  <Box
                    display="flex"
                    flexDirection="row"
                    sx={{ width: '100%' }}
                  >
                    {/* Cancel */}
                    <Box>
                      <IntuIconButton
                        variant="outlined"
                        type="cancel"
                        color="s"
                        onClick={() => {
                          resetForm();
                          setChangeEmailDialog(false);
                        }}
                        tooltipTitle={t('buttons.cancel')}
                      />
                    </Box>

                    {/* Confirm Button */}
                    <Box sx={{ marginLeft: 'auto' }}>
                      {showOTP && (
                        <IntuIconButton
                          variant="contained"
                          type="submit"
                          color="success"
                          disabled={!showOTP || errors?.userOTP ? true : false}
                          onClick={async (e) => {
                            // Get Cookie Settings
                            let authSettings = user?.cookieSettings?.auth;

                            const url =
                              '/api/users/account/change/email/confirm';
                            const payload = {
                              email: values.new_email,
                              otp: values.userOTP,
                              host_url: host_url,
                              userDashboard: true,
                              cookies: authSettings,
                            };

                            try {
                              const request = await axios.post(
                                url,
                                JSON.stringify(payload),
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                  withCredentials: true,
                                },
                              );
                              const response = request?.data;

                              if (request.status === 201) {
                                const updatedUserData = { ...response.data };
                                const updatedAuthInfo = response.auth_info;
                                let updatedUserInfo = { ...response.user_info };

                                // Set Profile Picture
                                let image = '';
                                if (updatedUserInfo?.profile_picture) {
                                  image = `data:${updatedUserInfo.profile_picture.mimetype};base64,${updatedUserInfo.profile_picture.buffer.toString('base64')}`;
                                }

                                updatedUserInfo = {
                                  ...updatedUserInfo,
                                  profile_picture_view_url: image,
                                };

                                // Update User Auth
                                setAuth({
                                  auth_info: updatedAuthInfo,
                                  user_info: updatedUserInfo,
                                });

                                setUserProfile({
                                  ...userProfile,
                                  email: updatedUserData.email,
                                });

                                resetForm();
                                setChangeEmailDialog(false);

                                handleRegularResponse({
                                  open: true,
                                  status: response.status,
                                  message: response.message,
                                });
                              } else {
                                handleRegularResponse({
                                  open: true,
                                  status: response.status,
                                  message: response.message,
                                });
                              }
                            } catch (err) {
                              handleErrorResponse(err);
                              errRef.current?.focus();
                            }
                          }}
                          tooltipTitle={t('buttons.confirm')}
                        />
                      )}

                      {/* Change Button */}
                      {!showOTP && (
                        <IntuIconButton
                          variant="outlined"
                          disabled={errors?.new_email || showOTP ? true : false}
                          type="warning"
                          onClick={async (e) => {
                            const url =
                              '/api/users/account/change/email/request';
                            const payload = {
                              new_email: values.new_email,
                              host_url: host_url,
                            };

                            try {
                              const request = await axios.post(
                                url,
                                JSON.stringify(payload),
                                {
                                  headers: {
                                    'Content-Type': 'application/json',
                                  },
                                  withCredentials: true,
                                },
                              );
                              const response = request?.data;

                              if (request.status === 200) {
                                handleRegularResponse({
                                  open: true,
                                  status: response.status,
                                  message: response.message,
                                });
                                setFieldValue('new_email', values.new_email);
                                setShowOTP(true);
                              } else {
                                handleRegularResponse({
                                  open: true,
                                  status: response.status,
                                  message: response.message,
                                });
                              }
                            } catch (err) {
                              handleErrorResponse(err);
                              errRef.current?.focus();
                            }
                          }}
                          IconComponent={Email}
                          tooltipTitle={t('buttons.change')}
                        />
                      )}
                    </Box>
                  </Box>
                </DialogActions>
              </form>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default ChangeUserEmailDialog;
