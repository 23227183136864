import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import FaceIcon from '@mui/icons-material/Face';
import StyledBadge from './common/StyledBadge';
import { UserProfileContext } from '../../context/users/UserProfileProvider';
import useAuth from '../../hooks/useAuth';
import UserContext from '../../context/users/UserInfoProvider';
import ChatDocumentDialog from './ChatDocumentDialog';
import getRelativeTime from '../../helpers/getRelativeTime';
import { StyledAvatar } from '../image/fields/StyledAvatar';
import getUserImage from '../../helpers/getUserImage';
import IntuIconButton from '../buttons/IntuIconButton';

const ChatRoom = ({
  senders,
  selectedSender,
  conversationOptions,
  handleConversationChange,
  dropdownValue,
  showingConversation,
  handleSend,
}) => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const { t: transFields } = useTranslation('fields');
  const { userProfile } = useContext(UserProfileContext);
  const { getChatDocument, counterOffer, acceptOffer, declineOffer } =
    useContext(UserContext);
  const { auth } = useAuth();
  const navigate = useNavigate();

  const boxRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const scrollToBottom = () => {
    boxRef.current.scrollTop = boxRef.current.scrollHeight;
  };

  useEffect(() => {
    if (showingConversation) {
      scrollToBottom();
    }
  }, [showingConversation]);

  const onSend = (message, showingConversation) => {
    setIsLoading(true);
    handleSend(message, showingConversation);
    setMessage('');
    setIsLoading(false);
  };

  const handleViewDocument = async () => {
    const id =
      typeof showingConversation.chat_document === 'string'
        ? showingConversation.chat_document
        : showingConversation.chat_document._id;
    setIsLoading(true);
    await getChatDocument(id, showingConversation.chat_id);
    setIsDocumentDialogOpen(true);
    setIsLoading(false);
  };

  const [isDocumentDialogOpen, setIsDocumentDialogOpen] = useState(false);

  const handleAcceptApplication = async (doc_id) => {
    await acceptOffer(
      showingConversation.chat_id,
      doc_id,
      showingConversation.sender._id,
    );
  };

  const handleDeclineApplication = async (doc_id) => {
    await declineOffer(
      showingConversation.chat_id,
      doc_id,
      showingConversation.sender._id,
    );
  };

  const handleCounterApplication = async (newOffer) => {
    await counterOffer(showingConversation.chat_id, newOffer);
  };

  const title = useMemo(() => {
    if (!showingConversation || !showingConversation.chat_document) return '';
    if (showingConversation.chat_document.sender_id === auth.user_info._id) {
      return `${showingConversation.chat_document.type} for ${selectedSender}`;
    }
    return `${showingConversation.chat_title}`;
  }, [auth, showingConversation, selectedSender]);

  const handleSenderClick = () => {
    if (auth.user_info.account_type === 'manufacturer') {
      navigate(`/app/user-profile/${senders.get(selectedSender)._id}`);
    } else {
      navigate(`/app/company-profile/${senders.get(selectedSender)._id}`);
    }
  };

  return (
    <Box
      flex={1}
      borderRadius={2}
      boxShadow={6}
      p={2}
      display="flex"
      flexDirection="column"
    >
      {selectedSender ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Box display="flex">
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <StyledAvatar
                src={getUserImage(senders.get(selectedSender))}
                alt="profile picture"
              >
                <FaceIcon sx={{ color: 'primary.dark' }} />
              </StyledAvatar>
            </StyledBadge>
            <Box ml={2}>
              <Link
                component="button"
                variant="body1"
                onClick={handleSenderClick}
                underline="hover"
              >
                {selectedSender}
              </Link>
              {showingConversation ? (
                <Typography variant="body2">
                  {t('pages.chat.offline')} last seen{' '}
                  {getRelativeTime(showingConversation.sender.last_signin)}
                </Typography>
              ) : null}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <LoadingButton
              loading={isLoading}
              variant="outlined"
              color="primary"
              disabled={
                !showingConversation || !showingConversation.chat_document
              }
              onClick={handleViewDocument}
            >
              {transButtons('view', {
                type: transTypes('applications'),
              })}
            </LoadingButton>
          </Box>
        </Box>
      ) : null}
      {conversationOptions.length ? (
        <>
          <InputLabel id="conversation-select">
            {t('pages.chat.select')}
          </InputLabel>
          <Select
            labelId="conversation-select"
            onChange={handleConversationChange}
            label={'select a conversation'}
            name="conversation"
            value={dropdownValue}
          >
            {conversationOptions.map((conversation) => (
              <MenuItem
                key={conversation.chat_id}
                value={conversation.chat_title}
              >
                {conversation.chat_title}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <Typography align="center" variant="body1">
          {t('pages.chat.description')}
        </Typography>
      )}
      {showingConversation ? (
        <>
          <Box
            mt={2}
            flex={1}
            display="flex"
            flexDirection="column"
            sx={{ overflowY: 'scroll' }}
            ref={boxRef}
          >
            <Box>
              {showingConversation.messages.map((message) => {
                const isUser =
                  auth.user_info.account_type === 'manufacturer'
                    ? auth.user_info.company === message.sender_id
                    : auth.user_info._id === message.sender_id;

                const sender = senders.get(showingConversation.sender.name);
                let otherUserImage = '';
                if (sender.profile_picture) {
                  otherUserImage = `data:${sender.profile_picture.mimetype};base64,${sender.profile_picture.buffer.toString('base64')}`;
                }

                const userImage =
                  auth.user_info.account_type === 'manufacturer'
                    ? getUserImage(userProfile.company)
                    : userProfile.profile_picture_view_url;

                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    flexDirection={isUser ? 'row-reverse' : 'row'}
                    my={1}
                    key={message.created_at}
                  >
                    <StyledAvatar
                      src={isUser ? userImage : otherUserImage}
                      alt="profile picture"
                    >
                      <FaceIcon sx={{ color: 'primary.dark' }} />
                    </StyledAvatar>
                    <Box
                      bgcolor={isUser ? 'primary.main' : 'grey.light'}
                      color={
                        isUser ? 'tertiary.backgroundColor' : 'primary.main'
                      }
                      borderRadius={2}
                      boxShadow={6}
                      p={2}
                      mx={2}
                    >
                      <Typography
                        fontStyle={
                          message.type === 'system' ? 'italic' : 'initial'
                        }
                        color={
                          message.type === 'system' && !isUser
                            ? 'primary'
                            : 'background.paper'
                        }
                      >
                        {message.message}
                      </Typography>
                    </Box>
                    <Typography variant="body1">
                      {dayjs(message.created_at).format('h:mm A')}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box mt={2}>
            <TextField
              type="text"
              label={transFields('chat.type_message')}
              color="secondary.contrastText"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && message) {
                  onSend(message, showingConversation);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IntuIconButton
                      type="info"
                      variant="contained"
                      size="small"
                      tooltipTitle={transButtons('send')}
                      onClick={() => onSend(message, showingConversation)}
                      disabled={!message}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </>
      ) : null}
      {showingConversation && showingConversation.chat_document ? (
        <ChatDocumentDialog
          isOpen={isDocumentDialogOpen}
          handleClose={() => setIsDocumentDialogOpen(false)}
          handleConfirm={handleAcceptApplication}
          handleDecline={handleDeclineApplication}
          handleConfirmCounter={handleCounterApplication}
          document={showingConversation.chat_document}
          title={title}
        />
      ) : null}
    </Box>
  );
};

export default ChatRoom;
