// Holds exclusively infrmation about the authenticated user
// It is DEpenden on ProductTypeProvider and ApplicationsProvider

import { createContext, useContext, useEffect, useRef, useState } from 'react';
import i18n from 'i18next';
import { CompanyProfileContext } from '../company/CompanyProfileProvider';
import useResponseHandling from '../../hooks/useResponseHandler';
import { useAxiosPrivate } from '../../hooks/axios/useAxiosPrivate';
import dayjs from 'dayjs';
import languagesDataList from '../../data/LanguagesData.json';
import { getMuiRichTextEditorContent } from '../../helpers/muiRichtTextEditor';
import { useProcessingHandler } from '../../hooks/useProcessingHandler';
import { useTranslation } from 'react-i18next';
import { ProductTypeContext } from '../providers/ProductTypeContextProvider';
import { ApplicationsContext } from '../applications/ApplicationsContextProvider';
import AuthContext from '../auth/AuthProvider';
import {
  associateUserToCompany,
  unAssociateUserFromCompany,
} from '../../api/employeeRoutes';
import { IndustriesContext } from '../industries/IndustriesContextProvider';

export const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  const { auth, setAuth } = useContext(AuthContext);
  const { getCompanyProfile } = useContext(CompanyProfileContext);
  const {
    addNewProductTypesToDatabase,
    newProductTypeList,
    getProductTypeNamesById,
  } = useContext(ProductTypeContext);
  const {
    newApplicationList,
    addNewApplicationsToDatabase,
    getApplicationNamesById,
  } = useContext(ApplicationsContext);
  const { getIndustriesById } = useContext(IndustriesContext);
  const axios = useAxiosPrivate();
  const { t } = useTranslation();
  const [userProfile, setUserProfile] = useState({
    _id: '',
    account_type: '',
    first_name: '',
    last_name: '',
    full_name: '',
    dob: null,
    email: '',
    new_email: '',
    linkedin: {},
    languages: [],
    company: {},
    profile_link: '',
    initial_setup: false,
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postcode: '',
      country: { label: 'United States', dialCode: '1', code: 'US' },
    },
    phone: {
      value: '',
      info: {
        countryCallingCode: null,
        countryCode: null,
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    },
    mobile: {
      value: '',
      info: {
        countryCallingCode: null,
        countryCode: null,
        nationalNumber: null,
        numberType: null,
        numberValue: null,
        reason: null,
      },
    },
    mobile_verified: {
      accepted: '',
      ip: '',
      date: '',
      unixTimeStamp: '',
      agent: '',
    },
    intro: ' ',
    intro_rte: getMuiRichTextEditorContent(' '),
    account_notifications: false,
    applications: [],
    industries: [],
    product_types: [],
    profile_picture: '',
    profile_picture_view_url: '',
    profile_completion: 0,
    missing_fields: [],
  });
  const [isFetched, setIsFetched] = useState(false);

  const errRef = useRef(null);
  const [showOTP, setShowOTP] = useState(false);
  const [changeEmailDialog, setChangeEmailDialog] = useState(false);

  const [changeCompanyDialog, setChangeCompanyDialog] = useState(false);

  const { handleRegularResponse, handleErrorResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  // Search for Language Details
  function handleGetLanguages(languagesByCode) {
    // Guard Clause
    if (languagesByCode?.length === 0) {
      return [];
    }

    // Get Language Details
    let userLanguagess = [];

    languagesByCode.map((element) => {
      const languageInfo = languagesDataList.filter(
        (languageInfo) => element === languageInfo.code,
      );
      userLanguagess.push(...languageInfo);
    });

    return userLanguagess;
  }

  // Get User Profile Info
  async function getUserProfile() {
    // Guard Clause
    if (!auth.auth_info) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    try {
      const url = '/api/users/account/profile';
      const response = await axios.get(url, { signal });
      const { data, status } = response;
      if (status === 200) {
        const userData = data.data;
        let image = '';
        if (userData?.profile_picture?.mimetype) {
          image = `data:${userData.profile_picture.mimetype};base64,${userData.profile_picture.buffer.toString('base64')}`;
        }

        // Get User Profile Completion Status
        const getProfileCompletion = async () => {
          let profileCompletion;
          let missingFields;
          const getProfilecompletion = await getUserProfileProgress();
          const { data, statusCode } = getProfilecompletion;
          if (statusCode === 200) {
            const percentage = data.percentage;
            missingFields = data.missingFields;
            if (percentage) {
              profileCompletion = parseFloat(percentage + '%');
            }
          }
          return { profileCompletion, missingFields };
        };

        // Set OTP Field
        if (data?.new_email) {
          setShowOTP(true);
        }

        let companyData = {};
        if (userData?.company?.length > 0) {
          companyData = await getCompanyProfile(userData?.company);
        }

        const profileData = await getProfileCompletion();
        return {
          ...userProfile,
          _id: userData?._id,
          email: userData?.email,
          new_email: userData?.new_email,
          company: {
            _id: companyData?._id,
            name: companyData?.name,
            profile_picture: companyData?.profile_picture,
          },
          linkedin: userData?.linkedin,
          languages: userData?.languages
            ? handleGetLanguages(userData?.languages)
            : [],
          dob: userData?.dob ? dayjs(userData?.dob) : null,
          profile_link: userData?.profile_link || '',
          address: userData?.address,
          first_name: userData?.first_name,
          last_name: userData?.last_name,
          initials: `${userData?.first_name.charAt(0).toUpperCase()} ${userData?.last_name
            .charAt(0)
            .toUpperCase()}`,
          full_name: `${userData?.first_name} ${userData?.last_name}`,
          phone: userData?.phone,
          mobile: userData?.mobile,
          mobile_verified: userData?.mobile_verified,
          initial_setup: userData?.initial_setup || false,
          account_notifications: userData?.account_notifications,
          product_types:
            (await getProductTypeNamesById(userData?.product_types)) || [],
          applications:
            (await getApplicationNamesById(userData?.applications)) || [],
          industries: userData?.industries
            ? await getIndustriesById(userData?.industries)
            : [],
          intro: userData?.intro,
          intro_rte: userData?.intro || getMuiRichTextEditorContent(' '),
          account_type: userData?.account_type,
          profile_picture_view_url: image,
          profile_completion: profileData.profileCompletion,
          missing_fields: profileData.missingFields,
        };
      }
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
    }
  }

  // Get User Profile Progresss
  async function getUserProfileProgress() {
    // Guard Clause
    if (!auth.auth_info) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;
    try {
      const url = '/api/users/account/profile/completion-status';
      const response = await axios.get(url, { signal });

      const { data } = response;
      if (data) {
        return data;
      }
    } catch (error) {
      console.error('error', error);
      return error;
    } finally {
      controller.abort(signal);
    }
  }

  // Handle form submission process
  async function updateUserProfile(values) {
    setIsLoading({
      status: true,
      type: 'skeleton',
      text: t('form.message.update_profile'),
    });

    // Format Products by Ids and add newl added Products to Database
    let productTypesByID = [];
    if (values?.product_types?.length > 0) {
      productTypesByID = values?.product_types
        .filter((product) => product.type_id)
        .map((product) => product.type_id);

      if (newProductTypeList.length > 0) {
        const newProductData = await addNewProductTypesToDatabase();
        if (newProductData?.length > 0) {
          const newProductTypesById = newProductData.map(
            (product) => product.type_id,
          );

          productTypesByID = [...productTypesByID, ...newProductTypesById];
        }
      }
    }

    // Format Application by IDs and add newly added application
    let applicationsByID = [];
    if (values?.applications?.length > 0) {
      applicationsByID = values?.applications
        .filter((application) => application.application_id)
        .map((application) => application.application_id);

      // Add New Applications to the Database
      if (newApplicationList.length > 0) {
        const newApplicationData = await addNewApplicationsToDatabase();
        if (newApplicationData?.length > 0) {
          const newApplicationsById = newApplicationData.map(
            (application) => application.application_id,
          );

          applicationsByID = [...applicationsByID, ...newApplicationsById];
        }
      }
    }

    // Format Industries by IDs
    let industriesByID = [];
    if (values?.industries?.length > 0) {
      industriesByID = values?.industries
        .filter((industry) => industry.industry_id)
        .map((industry) => industry.industry_id);
    }

    // Format Languages b ID
    const languages = values?.languages.map((language) => language.code);

    // Update User Account
    const url = '/api/users/account/update';
    let payload = {
      _id: auth.user_info._id,
      first_name: values.first_name,
      last_name: values.last_name,
      dob: values.dob,
      address: values.address,
      profile_link: values.profile_link,
      linkedin: values.linkedin,
      phone: values.phone,
      account_type: values.account_type,
      product_types: productTypesByID,
      applications: applicationsByID,
      industries: industriesByID,
      company: values?.company?._id || null,
    };

    if (languages.length > 0) {
      payload.languages = languages;
    }

    if (values.intro.blocks[0].text) {
      payload.intro = values.intro;
    }

    const controller = new AbortController();
    const { signal } = controller;
    try {
      const request = await axios.put(url, JSON.stringify(payload), {
        signal,
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      const response = request?.data;

      if (response.statusCode === 201) {
        const user = await getUserProfile();
        setUserProfile(user);
      }

      handleRegularResponse({
        open: true,
        status: response.status,
        message: response.message,
      });

      return response;
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
      return err;
    } finally {
      controller.abort();
    }
  }

  // Remove Users from a Company
  async function removeUserFromCompany({ update = false, values, userData }) {
    try {
      // Guard Clause
      if (!values) {
        throw new Error('No Values or Action provided');
      }

      if (!userData) {
        throw new Error('No User Profile Data provided');
      }

      const updateCompanyAssociation = await unAssociateUserFromCompany(values);
      if (updateCompanyAssociation.statusCode === 200 && update === true) {
        setAuth({
          ...auth,
          user_info: { ...auth.user_info, company: null },
        });
      }

      if (updateCompanyAssociation.statusCode !== 200) {
        handleRegularResponse({
          open: true,
          status: updateCompanyAssociation.status,
          message: updateCompanyAssociation.message,
        });
      }

      return updateCompanyAssociation;
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
      return error;
    }
  }

  // Add users to Company
  async function addUserToCompany({ update = false, values, userData }) {
    try {
      // Guard Clause
      if (!values) {
        throw new Error('No Values or Action provided');
      }

      if (!userData) {
        throw new Error('No User Profile Data provided');
      }

      const updateCompanyAssociation = await associateUserToCompany(values);

      if (updateCompanyAssociation.statusCode === 200 && update === true) {
        setAuth({
          ...auth,
          user_info: { ...auth.user_info, company: values.company_id },
        });
      }

      if (updateCompanyAssociation.statusCode !== 200) {
        handleRegularResponse({
          open: true,
          status: updateCompanyAssociation.status,
          message: updateCompanyAssociation.message,
        });
      }

      return updateCompanyAssociation;
    } catch (error) {
      handleErrorResponse(error);
      console.error('error', error);
      return error;
    }
  }

  async function preLoadForm() {
    const user = await getUserProfile();
    setUserProfile(user);
  }

  useEffect(() => {
    setIsFetched(false);
    if (auth.auth_info) {
      try {
        setIsLoading({ status: true, type: 'skeleton', text: '' });
        preLoadForm();
      } catch (error) {
        console.error('error', error);
      } finally {
        setIsLoading({ status: false, type: '', text: '' });
        setIsFetched(true);
      }
    }
  }, [auth?.auth_info, i18n.language, userProfile?._id]);

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        setUserProfile,
        getUserProfile,
        updateUserProfile,
        showOTP,
        setShowOTP,
        changeEmailDialog,
        setChangeEmailDialog,
        changeCompanyDialog,
        setChangeCompanyDialog,
        addUserToCompany,
        removeUserFromCompany,
        isFetched,
        setIsFetched,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
