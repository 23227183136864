import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Drawer, Box, Grid, IconButton, Avatar, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

// Animation
import { motion } from 'framer-motion';
import { NavContainer } from '../../framer-motion/animations';

// Nav Context
import NonAuthNavItems from './navitems/NonAuthNavItem';
import { NavBarDrawerContext } from '../../context/nav/NavBarDrawerContextProvider';
import useUser from '../../hooks/useUser';
import { IntuAppBar } from '../../mui/appBar';
import { DrawerHeaderContent } from './drawer/DrawerHeaderContent';
import DrawerFooterContent from './drawer/DrawerFooterContent';
import { UserProfileProvider } from '../../context/users/UserProfileProvider';
import { CompanyProfileProvider } from '../../context/company/CompanyProfileProvider';
import IntuThemeContext from '../../context/providers/IntuThemeProvider';

const NonAuthNavBarComponent = () => {
  const { handleLanguageChange } = useUser();
  const { logo, theme } = useContext(IntuThemeContext);

  const navigate = useNavigate();
  const { handleDrawerClose, handleDrawerOpen, drawerOpen } =
    useContext(NavBarDrawerContext);

  return (
    <CompanyProfileProvider>
      <UserProfileProvider>
        <Box sx={{ flexGrow: 1 }}>
          <IntuAppBar position="fixed" open={drawerOpen}>
            <Toolbar variant="dense" sx={{ maxWidth: '100%', px: 2 }}>
              {/* Left side: Logo */}
              <IconButton
                onClick={() => navigate('/')}
                disableRipple
                sx={{ p: 0 }}
              >
                <Avatar
                  alt={logo.alt}
                  sx={{
                    width: '60px',
                    height: '60px',
                    margin: '10px 0',
                  }}
                  src={logo.file}
                  style={{ backgroundColor: 'transparent' }}
                  variant="square"
                />
              </IconButton>

              {/* Center: Navigation Items */}
              <Box
                sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}
              >
                <NonAuthNavItems mode="NavBar" />
              </Box>

              {/* Right: Hamburger Icon */}
              {!drawerOpen && (
                <IconButton
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    p: 1,
                    ...(drawerOpen && { display: 'none' }),
                  }}
                  data-testid="DrawerButton"
                >
                  <MenuIcon
                    sx={{
                      color: theme.components.MuiAppBar.menuIcon,
                      fontSize: '2rem',
                    }}
                  />
                </IconButton>
              )}
            </Toolbar>

            <motion.div>
              <Drawer
                sx={{
                  display: 'flex',
                }}
                variant="temporary"
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerClose}
                variants={NavContainer}
                animate={drawerOpen ? 'visible' : 'hidden'}
                initial="hidden"
              >
                <Grid container direction={'column'} sx={{ flex: 1 }}>
                  <Grid item>
                    <DrawerHeaderContent />
                  </Grid>
                  <Grid item sx={{ flex: 1 }}>
                    <NonAuthNavItems />
                  </Grid>
                  <Grid item>
                    <DrawerFooterContent
                      onLanguageChange={handleLanguageChange}
                    />
                  </Grid>
                </Grid>
              </Drawer>
            </motion.div>
          </IntuAppBar>
        </Box>
      </UserProfileProvider>
    </CompanyProfileProvider>
  );
};

export default NonAuthNavBarComponent;
