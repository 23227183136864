import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Check } from '@mui/icons-material';

const NavigationFooter = () => {
  const navigate = useNavigate();
  const { footerConfig } = useNavigationFooter();
  const {
    confirmLabel,
    backLabel,
    ConfirmIcon,
    BackIcon,
    onConfirm,
    isLoading,
    isDisabled,
    showFooter,
    showConfirm,
    otherComponents = null,
  } = footerConfig;

  const handleBack = () => {
    if (footerConfig.onBack) {
      footerConfig.onBack();
      return;
    }
    navigate(-1);
  };

  if (!showFooter) return null;

  return (
    <AppBar
      position="fixed"
      sx={{
        top: 'auto',
        bottom: 0,
        backgroundColor: 'background.dark',
        height: 60,
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Toolbar
        sx={{
          minHeight: 40,
          height: 40,
          px: 1,
        }}
      >
        {/* Back Button */}
        <Button
          variant="outlined"
          onClick={handleBack}
          size="small"
          startIcon={
            BackIcon ? (
              <BackIcon fontSize="small" sx={{ color: 'common.white' }} />
            ) : (
              <ArrowBackIosIcon
                fontSize="small"
                sx={{ color: 'common.white' }}
              />
            )
          }
          sx={{
            width: 200,
            color: 'common.white',
            borderColor: 'common.white',
            height: 30,
          }}
        >
          {backLabel}
        </Button>

        <Box display="flex" flexDirection="row" sx={{ flexGrow: 1 }} gap={2} />
        {/* Other Fields or Buttons */}
        <Box sx={{ marginRight: 2 }}>{otherComponents}</Box>

        {/* Confirm Button */}
        {showConfirm ? (
          <LoadingButton
            variant="outlined"
            onClick={onConfirm}
            loading={isLoading}
            disabled={isDisabled}
            endIcon={
              ConfirmIcon ? (
                <Check
                  sx={{ color: isDisabled ? '#2c2c2e' : 'common.white' }}
                />
              ) : null
            }
            size="small"
            sx={{
              color: 'common.white',
              border: 'common.white',
              width: 200,
              height: 30,
            }}
          >
            {confirmLabel}
          </LoadingButton>
        ) : null}
      </Toolbar>
    </AppBar>
  );
};

export default NavigationFooter;
