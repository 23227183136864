import { useRef, useContext } from 'react';
import { useAnalyticsEventTracker } from '../../../../hooks/useAnalyticsTracker.jsx';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from '../../../../hooks/axios/useAxios.js';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Formik, Form } from 'formik';

// Materail UI
import { Button, Stack, FormControl, Grid, Box } from '@mui/material';

// Response & Processing Handler
import useResponseHandling from '../../../../hooks/useResponseHandler.js';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';

// Get Cookie Settings for Signing
import useUser from '../../../../hooks/useUser.js';

// Translator
import { useTranslation } from 'react-i18next';
import i18n from '../../../../i18n.js';
import AuthContext from '../../../../context/auth/AuthProvider.jsx';
import { createSignInFormValidationSchema } from './validationSchemaSignInForm.js';
import { createSignInInititialValues } from './initialValuesSingInForm.js';
import EmailField from '../../fields/email/EmailField.jsx';
import PasswordField from '../../PasswordField.jsx';

// CRYPTO
import CryptoJS from 'crypto-js';

// FORM CONTENT
const SignInForm = () => {
  // Google Event Tracker
  const { gaEventTracker } = useAnalyticsEventTracker();

  // State & Context
  const { setAuth } = useContext(AuthContext);
  const { user } = useUser();

  // Response & Process Handling
  const { handleErrorResponse, handleRegularResponse } = useResponseHandling();
  const { setIsLoading } = useProcessingHandler();

  const navigate = useNavigate();
  const location = useLocation();
  const from_url = location.state?.from?.pathname || '/';
  const destination_url = location.state?.from?.pathname || null;
  const host_url = window.location.host;

  // Translator
  // Translation
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignInForm',
  });

  const { t: transButtons } = useTranslation('buttons');

  const params = new URLSearchParams(window.location.search);
  const userID = params.get('userID') || '';
  const userPass = params.get('userPass') || '';

  // Setup Initial Values for Form Validation
  const initialValues = createSignInInititialValues({
    user: userID,
    password: userPass,
  });
  const validationSchema = createSignInFormValidationSchema();
  // Set Up From refs
  const errRef = useRef();
  const formRef = useRef(null);

  // Handle Regular SignIn
  async function submitForm(values, formik) {
    // Get Cookie Settings
    const authSettings = user?.cookieSettings?.auth;

    const encryptedPassword = CryptoJS.AES.encrypt(
      values.password,
      process.env.REACT_APP_ENCRYPTION_KEY,
    ).toString();

    const url = '/api/users/account/signin';
    const payload = {
      email: values.email,
      password: encryptedPassword,
      host_url: host_url,
      from_url: from_url,
      cookies: authSettings,
    };

    try {
      setIsLoading({
        status: true,
        type: 'spinner',
        text: t('loading'),
      });

      const { data, status } = await axios.post(url, JSON.stringify(payload), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      // login successful
      if (status === 200) {
        const { twoFactor, user_info, auth_info, navigate_to } = data;
        if (twoFactor) {
          // Two Factor Authentication enabled;
          navigate(navigate_to, { replace: true });
        } else {
          gaEventTracker({
            category: 'account',
            action: 'events',
            label: 'User Logged In',
            value: 5,
          });

          let image = '';
          // Set Profile Picture
          if (user_info?.profile_picture?.mimetype) {
            image = `data:${user_info.profile_picture.mimetype};base64,${user_info.profile_picture.buffer.toString('base64')}`;
          }

          // Set User Auth State
          setAuth({
            auth_info,
            user_info: {
              ...user_info,
              profile_picture_view_url: image,
            },
          });

          // Navigate user
          if (destination_url) {
            // Redirect to original page
            navigate(destination_url, { replace: true });
          } else {
            // Navigate to user dashboard
            navigate(navigate_to, { replace: true });
          }
        }
      } else if (status === 230) {
        // Redirect to Account Confirmation Page
        navigate(
          `/account/confirm?userID=${values.email}&userAction=accConfirm`,
          {
            replace: true,
          },
        );
        handleRegularResponse({
          open: true,
          status: 'error',
          message: data.message,
        });
      } else {
        formik.setFieldValue('userPass', '');
        handleRegularResponse({
          open: true,
          status: 'error',
          message: data.message,
        });
      }
    } catch (err) {
      handleErrorResponse(err);
      errRef.current?.focus();
    } finally {
      setIsLoading({ status: false, type: '', text: '' });
    }
  }

  // Handle LinkedIn SignIn
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_SIGNIN_CLIENT_ID,
    scope: 'openid profile email',
    redirectUri: `${window.location.origin}/linkedin-callback`,
    onSuccess: async (authCode) => {
      const controller = new AbortController();
      const { signal } = controller;

      try {
        setIsLoading({
          status: true,
          type: 'spinner',
          text: i18n.t('loading'),
        });

        const url = `/api/linkedin/signin`;
        const params = {
          code: authCode,
          host_url: host_url,
        };

        // Fetch user profile info using the access token
        const userInfoResponse = await axios.get(url, {
          params,
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });

        if (userInfoResponse.status === 200) {
          // SIgnUp successful
          const { user_info, auth_info, navigate_to } = userInfoResponse.data;

          gaEventTracker({
            category: 'Account Management',
            action: 'Event',
            label: 'User Logged In',
          });

          let image = '';
          // Set Profile Picture
          if (user_info?.profile_picture?.mimetype) {
            image = `data:${user_info.profile_picture.mimetype};base64,${user_info.profile_picture.buffer.toString('base64')}`;
          }

          // Set User Auth State
          setAuth({
            auth_info,
            user_info: {
              ...user_info,
              profile_picture_view_url: image,
            },
          });

          // Navigate user
          if (destination_url) {
            // Redirect to original page
            navigate(destination_url, { replace: true });
          } else {
            // Navigate to user dashboard
            navigate(navigate_to, { replace: true });
          }
        } else {
          const { status, message, navigate_to } = userInfoResponse.data;

          if (navigate_to) {
            navigate(navigate_to, { replace: true });
          }

          handleRegularResponse({
            open: true,
            status: status,
            message: message,
          });
        }
      } catch (error) {
        console.log(error);
        handleErrorResponse(error);
      } finally {
        setIsLoading({ status: false, type: '', text: '' });
        controller.abort(signal);
      }
    },
    onError: (error) => {
      console.log('Error Authenticating: ', error);
    },
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formik) => submitForm(values, formik)}
      validateOnMount={true}
      validateOnChange={true}
      enableReinitialize={true}
      innerRef={formRef}
    >
      {(formik) => {
        const { handleSubmit, errors, isValid } = formik;
        return (
          <Form className="form-horizontal" role="form" onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <Stack
                spacing={2}
                sx={{ paddingBottom: '50px' }}
                direction="column"
              >
                {/* Email address */}
                <EmailField
                  required
                  fieldID="email"
                  transNS="fields"
                  transPrefix="account.EmailField"
                />

                {/* User Login Password Field */}
                <PasswordField
                  required
                  transition={!errors.email}
                  fieldID="password"
                />
              </Stack>

              {/* CTA  */}
              <Box
                display="flex"
                flexDirection={{ xs: 'column-reverse', md: 'row' }}
                gap={4}
                sx={{ paddingBottom: '50px' }}
              >
                <Button
                  variant="outlined"
                  color="warning"
                  type="button"
                  onClick={(e) => {
                    navigate('/account/reset-password', {
                      replace: false,
                    });
                  }}
                >
                  {transButtons('reset_password')}
                </Button>

                {/* LinkedIn SinUp Button */}
                <Button
                  variant="linkedin"
                  type="button"
                  sx={{
                    marginLeft: { md: 'auto' },
                    width: { xs: '100%', md: '200px' },
                    backgroundColor: (theme) => theme.palette.linkedIn.main,
                    color: (theme) => theme.palette.linkedIn.contrastText,
                    '&:hover': {
                      backgroundColor: (theme) => theme.palette.linkedIn.dark,
                    },
                    border: (theme) => theme.palette.linkedIn.main,
                  }}
                  onClick={linkedInLogin}
                  endIcon={
                    <LinkedInIcon
                      sx={{
                        color: (theme) => theme.palette.linkedIn.contrastText,
                      }}
                    />
                  }
                >
                  {transButtons('sign_in_linkedin')}
                </Button>

                {/* Regulat SignIn button */}
                <Button
                  variant="submit"
                  type="submit"
                  disabled={!isValid}
                  sx={{ width: { xs: '100%', md: '200px' } }}
                >
                  {transButtons('sign_in')}
                </Button>
              </Box>
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};

export { SignInForm };
