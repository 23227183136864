import { useContext, useMemo } from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import IntuIconButton from '../../components/buttons/IntuIconButton';
import UserContext from '../../context/users/UserInfoProvider';
import Transition from './Transition';
import ChatSenders from '../chat/ChatSenders';
import { Close } from '@mui/icons-material';

const SendersDialog = ({
  isOpen,
  handleClose,
  selectedSender,
  handleSenderChange,
}) => {
  const { conversations } = useContext(UserContext);

  const senders = useMemo(() => {
    // console.log('Conversations -0> ', conversations);

    if (!conversations || !conversations.length) return new Map();

    const sendersMap = new Map();
    conversations.forEach((conversation) => {
      const { _id, name, profile_picture, last_signin } = conversation.sender;
      if (sendersMap.has(name)) {
        const cur = sendersMap.get(name).unread_count;
        sendersMap.set(name, {
          _id,
          profile_picture,
          last_signin,
          unread_count: cur + conversation.unread_count,
          most_recent_message: conversation.most_recent_message || '',
        });
      } else {
        sendersMap.set(name, {
          _id,
          profile_picture,
          last_signin,
          unread_count: conversation.unread_count,
          most_recent_message: conversation.most_recent_message || '',
        });
      }
    });
    return sendersMap;
  }, [conversations]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xs"
      fullWidth
      TransitionComponent={Transition}
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        },
        '& .MuiDialog-paper': {
          margin: '0 16px 0 0',
          width: '100%',
        },
      }}
      hideBackdrop
    >
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 0 }}
      >
        <IntuIconButton
          size="small"
          type="info"
          variant="outlined"
          tooltipTitle={'Close'}
          onClick={handleClose}
          IconComponent={Close}
        />
      </DialogTitle>
      <DialogContent>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          sx={{ overflowY: 'scroll' }}
          maxHeight={600}
        >
          <ChatSenders
            senders={senders}
            handleSenderChange={handleSenderChange}
            selectedSender={selectedSender}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SendersDialog;
