import { useState, useEffect, memo, useMemo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { GridRow, GridColumnHeaders } from '@mui/x-data-grid-premium';
import { useAxiosPrivate } from '../../../hooks/axios/useAxiosPrivate.js';
import { Trans, useTranslation } from 'react-i18next';
import IntuIconButton from '../../buttons/IntuIconButton.jsx';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useAuth from '../../../hooks/useAuth.js';
import ResponseContext from '../../../context/providers/ResponseProvider.jsx';
import ListLoader from '../../status/ListLoader.jsx';

const ShareDialog = ({ isOpen, handleClose, selectedProduct }) => {
  const { setStatusMsg } = useContext(ResponseContext);
  const { auth } = useAuth();
  const customLink = `https://${window.location.host}/product/${selectedProduct._id}&referalID=${auth.user_info._id}`;

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(customLink);
      setStatusMsg({
        open: true,
        status: 'success',
        message: 'Copied to clipboard!',
      });
    } catch (error) {
      setStatusMsg({
        open: true,
        status: 'error',
        message: 'error copying to clipboard',
      });
      console.error('error', error);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>Share this product</DialogTitle>
      <DialogContent>
        <DialogContentText>Your custom shareable link</DialogContentText>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography color="primary" fontSize="0.8rem" noWrap mr={2}>
            {customLink}
          </Typography>
          <IntuIconButton
            size="small"
            type="add"
            variant="outlined"
            tooltipTitle={'Copy to clipboard'}
            onClick={handleCopyClick}
            IconComponent={ContentCopyIcon}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

const createProductColumns = (t, handleViewClick, handleShareClick) => {
  return [
    {
      field: 'product_name',
      headerName: t('form.products.name_label'),
      editable: false,
      flex: 1,
      minWidth: 80,
    },
    {
      field: 'mpn',
      headerName: t('form.products.mpn_label'),
      editable: false,
      flex: 0.3,
      minWidth: 50,
    },
    {
      field: 'product_group',
      headerName: 'Product Type',
      editable: false,
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => {
        if (!params.row.product_types || !params.row.product_types.length)
          return '';

        return params.row.product_types.map((type) => type.name_en).join(', ');
      },
    },
    {
      field: 'list_price',
      headerName: t('form.products.list_price'),
      editable: false,
    },
    {
      field: 'attributes',
      headerName: t('form.attributes'),
      valueGetter: (params) => {
        if (
          !params.row.product_attributes ||
          !params.row.product_attributes.length
        )
          return '';

        return params.row.product_attributes
          .map((attribute) => {
            return `${attribute.attribute_type} - ${attribute.attribute}`;
          })
          .join(', ');
      },
      flex: 1,
      editable: false,
    },
    {
      field: 'applications',
      headerName: t('form.apps'),
      editable: false,
      flex: 1,
      valueGetter: (params) => {
        if (
          !params.row.product_applications ||
          !params.row.product_applications.length
        )
          return '';

        return params.row.product_applications
          .map((application) => application)
          .join(', ');
      },
    },
    {
      field: 'status',
      headerName: t('form.status.status'),
      editable: false,
    },
    {
      field: 'view',
      headerName: 'View',
      sortable: false,
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="add"
            variant="outlined"
            tooltipTitle={'View'}
            onClick={() => handleViewClick(params)}
            IconComponent={VisibilityIcon}
          />
        );
      },
    },
    {
      field: 'share',
      headerName: 'Share',
      sortable: false,
      renderCell: (params) => {
        return (
          <IntuIconButton
            size="small"
            type="add"
            variant="outlined"
            tooltipTitle={'Share'}
            onClick={() => handleShareClick(params)}
            IconComponent={ShareIcon}
          />
        );
      },
    },
  ];
};

const SalesRepProductList = () => {
  const axiosPrivate = useAxiosPrivate();
  const [products, setProducts] = useState([]);
  const MemoizedRow = memo(GridRow);
  const MemoizedColumnHeaders = memo(GridColumnHeaders);

  const { t } = useTranslation();
  const { t: transMessages } = useTranslation('messages', {
    keyPrefix: 'products.product_list',
  });

  const navigate = useNavigate();
  const theme = useTheme();
  const isMediumView = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isListLoading, setIsListLoading] = useState(false);

  const handleViewClick = (params) => {
    window.open(`/product/${params.row._id}?preview=true`, '_blank');
  };

  const handleShareClick = (params) => {
    setSelectedProduct(params.row);
    setIsShareDialogOpen(true);
  };

  const productColumns = useMemo(() => {
    return createProductColumns(t, handleViewClick, handleShareClick);
  }, [t, handleViewClick, handleShareClick]);

  const getProducts = async () => {
    setIsListLoading(false);
    try {
      const { data, status } = await axiosPrivate.get(
        '/api/products/get-products-for-sales-rep',
      );

      console.log('Product List Response -> ', data);
      if (status === 200) {
        setProducts(data.data);
      }
    } catch (error) {
      console.error('error', error);
    } finally {
      setIsListLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const handleClose = () => {
    setIsShareDialogOpen(false);
  };

  return (
    <Grid
      container
      className="content"
      sx={{ marginBottom: '8rem' }}
      spacing={5}
    >
      <Grid item className="content" xs={12}>
        {isListLoading ? (
          <ListLoader columns={8} rows={4} />
        ) : !products?.length ? (
          <Grid item className="title content-centered" xs={12}>
            <Typography variant="h6">
              <Trans t={transMessages} i18nKey="no_products_alt_admin" />
            </Typography>
          </Grid>
        ) : (
          <Box height="auto" width="100%">
            <DataGrid
              isCellEditable={(params) => false}
              getRowId={(row) => row._id}
              rows={products}
              columns={productColumns}
              columnVisibilityModel={{
                list_price: !isMobileView,
                attributes: !isMobileView && !isMediumView,
                applications: !isMobileView && !isMediumView,
                product_group: !isMobileView && !isMediumView,
                mpn: !isMobileView,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                sorting: {
                  sortModel: [{}],
                },
              }}
              pageSizeOptions={[5, 10, 100]}
              checkboxSelection={false}
              disableRowSelectionOnClick
              disableColumnMenu
              slots={{
                Row: MemoizedRow,
                ColumnHeaders: MemoizedColumnHeaders,
              }}
              sx={{
                borderRadius: '12px',
                boxShadow: 6,
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                  outline: 'none',
                },
              }}
            />
          </Box>
        )}
      </Grid>
      {selectedProduct ? (
        <ShareDialog
          isOpen={isShareDialogOpen}
          handleClose={handleClose}
          selectedProduct={selectedProduct}
        />
      ) : null}
    </Grid>
  );
};

export default SalesRepProductList;
