import React, { useContext, useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  FileUpload,
  Tune,
} from '@mui/icons-material';
import { DndProvider } from 'intu-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CarouselItem } from './carousel/Carouseltem';
import { useIntuTheme } from '../../context/providers/IntuThemeProvider';
import { ProductImageHeaderContext } from '../new-product/container/image/ProductImageHeader';
import DraggableIndicator from './carousel/DraggableIndicator';
import { alpha, Box, Button, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import IntuIconButton from '../buttons/IntuIconButton';

// Utility Function to move an array item
const arrayMove = (array, fromIndex, toIndex) => {
  const newArray = [...array];
  const [movedItem] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, movedItem);
  return newArray;
};

export default function DraggableCarousel({
  setIsUploadImageDialogOpen,
  handleEditClick,
}) {
  const {
    activeImage,
    setActiveImage,
    images,
    editingImage,
    handleReSortImages,
  } = useContext(ProductImageHeaderContext);

  const { t: transButtons } = useTranslation('buttons');
  const { theme } = useIntuTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [filteredImages, setFilteredImages] = useState([]);

  // 3D Model Controls
  const [wireframeMode, setWireframeMode] = useState(false);
  const [isModelInteracting, setisModelInteracting] = useState(false);
  const [autoRotate, setAutoRoate] = useState(false);

  useEffect(() => {
    const filtered = images.filter(
      (image) =>
        image.imageType !== 'stp' &&
        image.imageType !== 'step' &&
        image.imageType !== 'obj' &&
        image.imageType !== 'usdz',
    );
    setFilteredImages(filtered);
  }, [images]);

  const moveImage = (fromIndex, toIndex) => {
    const updatedFilteredImages = arrayMove(filteredImages, fromIndex, toIndex);

    handleReSortImages(updatedFilteredImages);
  };

  useEffect(() => {
    if (!filteredImages || activeImage !== 0) return;
    setActiveImage(0);
  }, [filteredImages]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Carousel
        sx={{
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          alignContent: 'center',
          width: '100%',
          height: '100%',
        }}
        index={activeImage}
        navButtonsAlwaysVisible={images.length > 1}
        autoPlay={false}
        swipe={false}
        NextIcon={
          !isModelInteracting ? (
            <ArrowForwardIos
              sx={{ color: (theme) => theme.palette.tertiary.contrastText }}
            />
          ) : null
        }
        PrevIcon={
          !isModelInteracting ? (
            <ArrowBackIosNew
              sx={{ color: (theme) => theme.palette.tertiary.contrastText }}
            />
          ) : null
        }
        navButtonsProps={{
          style: {
            display:
              !isModelInteracting && images.length > 1 ? 'inline-flex' : 'none',
          },
        }}
        width="100vw"
        height="100vh"
        onChange={(now) => setActiveImage(now)}
      >
        {filteredImages.map((image, i) => {
          if (editingImage) return null;
          return (
            <React.Fragment key={`box-wrapper-${i}`}>
              <Box
                key={`box-${image?.url}-${i}`}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                  pointerEvents: !isModelInteracting ? 'auto' : 'none',
                }}
              />
              <CarouselItem
                key={`carousel-item-${image?.url}-${i}`}
                image={image}
                showSliders={false}
                viewMode="view"
                initialCenter={false}
                isModelInteracting={isModelInteracting}
                enableZoom={isModelInteracting}
                wireframeMode={wireframeMode}
                setWireframeMode={setWireframeMode}
                showOrbitControls={true}
                autoRotate={autoRotate}
                autoRotateSpeed={0.5}
              />
            </React.Fragment>
          );
        })}
      </Carousel>

      {/* Action Buttons */}
      <Box
        display="flex"
        alignItems="flex-end"
        flexDirection="column"
        sx={{
          position: 'absolute',
          top: '5%',
          right: 0,
          zIndex: 10, // overlay above canvas
          m: 2, // optional margin
        }}
      >
        {/* Buttons */}
        <Button
          component="span"
          variant="outlined"
          startIcon={<FileUpload sx={{ color: 'info.main' }} />}
          disabled={images.length > 10}
          sx={{
            textTransform: 'none',
            borderRadius: '24px',
            minWidth: 187,
            height: 40,
          }}
          color="info"
          onClick={() => setIsUploadImageDialogOpen(true)}
        >
          {transButtons('upload_img')}
        </Button>
        {images[activeImage] ? (
          <Button
            component="span"
            variant="outlined"
            startIcon={<Tune />}
            disabled={images.length > 10}
            sx={{
              textTransform: 'none',
              mt: 2,
              borderRadius: '24px',
              minWidth: 187,
              height: 40,
            }}
            color="secondary"
            onClick={handleEditClick}
          >
            {transButtons('edit_img')}
          </Button>
        ) : null}
      </Box>

      {/* 3D Model Buttons */}
      {images[activeImage].imageType?.toLowerCase() === 'gltf' && (
        <Box
          sx={{
            position: 'absolute',
            bottom: isModelInteracting
              ? isMobile
                ? '10%'
                : '8%'
              : isMobile
                ? '15%'
                : '20%',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 2,
          }}
        >
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'tween',
              duration: 0.6,
              ease: 'easeOut',
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              gap={2}
              width="200px"
              sx={{
                backgroundColor: alpha(theme.palette.secondary.light, 0.6),
                border: `1px solid ${alpha(theme.palette.secondary.light, 0.6)}`,
                borderRadius: 3,
                padding: 1,
              }}
            >
              {/* Interact */}
              <IntuIconButton
                type={isModelInteracting ? 'interact_off' : 'interact_on'}
                tooltipTitle={
                  isModelInteracting
                    ? 'disable_interaction'
                    : 'enable_interaction'
                }
                variant="outlined"
                onClick={() => setisModelInteracting((prev) => !prev)}
              />
              {/* Wireframe Toggle */}
              <IntuIconButton
                type={wireframeMode ? 'ar_off' : 'ar_on'}
                tooltipTitle={
                  wireframeMode ? 'disable_wireframe' : 'enable_wireframe'
                }
                variant="outlined"
                onClick={() => setWireframeMode((prev) => !prev)}
              />

              {/* Auto-Rotate Toggle */}
              <IntuIconButton
                type={autoRotate ? 'stop' : 'play'}
                tooltipTitle={
                  autoRotate ? 'disable_autoplay' : 'enable_autoplay'
                }
                variant="outlined"
                onClick={() => setAutoRoate((prev) => !prev)}
              />
            </Box>
          </motion.div>
        </Box>
      )}

      {/* Draggable Indicators */}
      <Box
        sx={{
          position: 'absolute',
          bottom: isModelInteracting
            ? isMobile
              ? -200
              : -500
            : isMobile
              ? '12%'
              : '10%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 2,
        }}
      >
        {filteredImages.map((image, index) => (
          <DraggableIndicator
            key={`${image.url}-${index}`} // Important: This key ensures React re-renders the component correctly
            image={image}
            index={index}
            actualIndex={index}
            isActive={activeImage === index}
            onClick={setActiveImage}
            theme={theme}
            moveImage={moveImage}
          />
        ))}
      </Box>
    </DndProvider>
  );
}
