import { useContext, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { LayoutBodyContext } from '../../context/layout/LayoutContextProvider';
import FormContextProvider from '../../context/layout/FormContextProvider';

const IntuPage = ({
  pageTitle, // pageTitle
  pageDescription, // Page Description
  hCentered, // Horizontal center true/false (Boolean)
  vCentered, // Vertical center true/false (Boolean)
  children, // Actual Content as chidlren (React.Element)
  lessMT,
  ...props
}) => {
  const { setLayoutTitle, setLayoutDescription } =
    useContext(LayoutBodyContext);

  // Change Form Title and Description on Language Change
  useEffect(() => {
    setLayoutTitle(pageTitle);
    setLayoutDescription(pageDescription);
  }, []);

  const alignItems = hCentered ? 'center' : 'flex-start';
  const alignText = hCentered ? 'center' : 'left';
  const justifyContent = vCentered ? 'center' : 'flex-start';

  return (
    <FormContextProvider>
      <Container
        component="section"
        maxWidth="xxl"
        sx={{
          margin: { xs: 0, md: '5rem 0 0 0' },
          width: '100%',
        }}
        {...props}
      >
        <Grid
          container
          item
          maxWidth="xxl"
          sx={{
            margin: lessMT ? '0' : { xs: '2rem 0 0 0', md: '8rem 0 0 0' },
            width: '100%',
          }}
          {...props}
        >
          <Grid
            container
            item
            maxWidth="xxl"
            direction="column"
            alignItems={alignItems}
            justifyContent={justifyContent}
            flexWrap="nowrap"
          >
            <Grid item className="title" xs={12} pb="3rem">
              {pageTitle && (
                <Typography variant="h1" textAlign={alignText}>
                  {pageTitle}{' '}
                </Typography>
              )}
              {pageDescription && (
                <Typography variant="h5" textAlign={alignText}>
                  {pageDescription}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ width: '100%' }}
              direction="column-reverse"
              justifyContent={justifyContent}
              alignItems={alignItems}
            >
              {children}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </FormContextProvider>
  );
};

export default IntuPage;
