import i18next from 'i18next';
import * as Yup from 'yup';
import { measurementOptions, weightOptions } from '../../data/MeasruementUnits';

export const createNewProductValidationSchema = () => {
  const t = i18next.getFixedT(null, 'validation');
  const transTypes = i18next.getFixedT(null, 'types');

  return Yup.object({
    product_name: Yup.string()
      .min(
        10,
        t('short', { type: transTypes('product_description'), char: 10 }),
      )
      .max(65, t('long', { type: transTypes('product_description'), char: 60 }))
      .required(t('product.name_required')),
    mpn: Yup.string()
      .min(2, t('short', { type: transTypes('mpn'), char: 2 }))
      .max(50, t('long', { type: transTypes('mpn'), char: 50 }))
      .required(t('product.mpn_required')),
    list_price: Yup.number()
      .transform((value, originalValue) => {
        // Remove commas if user types "1,000"
        if (typeof originalValue === 'string') {
          return parseFloat(originalValue.replace(/,/g, ''));
        }
        return value;
      })
      .when('isPurchasable', {
        is: true,
        then: (schema) =>
          schema
            .positive(
              t('attribute.value.positive', { type: transTypes('list_price') }),
            )
            .required(t('product.list_price_required')),
        otherwise: (schema) => schema.notRequired(),
      }),
    description: Yup.array().min(1, t('product_description.required')),
    product_types: Yup.array()
      .of(Yup.mixed())
      .min(1, t('product_types.min', { number: 1 }))
      .required(t('product_types.required')),
    product_applications: Yup.array()
      .of(Yup.mixed())
      .min(1, t('product_applications.min', { number: 1 }))
      .required(t('product_applications.required')),
    product_attributes: Yup.array()
      .of(Yup.mixed())
      .min(1, t('product_attributes.min', { number: 1 }))
      .required(t('product_attributes.required')),
    country_of_origin: Yup.object({
      code: Yup.string().required(t('country_of_origin.required')),
    }),
    tarif_code: Yup.string().required(t('tarif_code.required')),
    dimensions: Yup.object({
      length: Yup.number()
        .positive(
          t('attribute.value.positive', { type: transTypes('measurement') }),
        )
        .required(
          t('attribute.value.required', { type: transTypes('measurement') }),
        ),
      width: Yup.number()
        .positive(
          t('attribute.value.positive', { type: transTypes('measurement') }),
        )
        .required(
          t('attribute.value.required', { type: transTypes('measurement') }),
        ),
      height: Yup.number()
        .positive(
          t('attribute.value.positive', { type: transTypes('measurement') }),
        )
        .required(
          t('attribute.value.required', { type: transTypes('measurement') }),
        ),
      unit: Yup.string()
        .oneOf(
          measurementOptions,
          t('attribute.unit.oneOf', { type: transTypes('') }),
        )
        .required(
          t('attribute.unit.required', { type: transTypes('measurement') }),
        ),
    }),
    weight: Yup.object({
      weight: Yup.number()
        .positive(t('attribute.value.positive', { type: transTypes('weight') }))
        .required(
          t('attribute.value.required', { type: transTypes('weight') }),
        ),
      unit: Yup.string()
        .oneOf(
          weightOptions,
          t('attribute.unit.oneOf', { type: transTypes('weight') }),
        )
        .required(t('attribute.unit.required', { type: transTypes('weight') })),
    }),
    lead_time: Yup.number()
      .positive(t('attribute.value.positive', { type: transTypes('leadtime') }))
      .required(
        t('attribute.value.required', { type: transTypes('leadtime') }),
      ),
    status: Yup.string().required(t('product.status_required')),
    brand: Yup.string().required(t('brand.required')),
    images: Yup.array().of(Yup.object({})).min(1, t('image.min')),
  });
};
