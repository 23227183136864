import { useFormikContext } from 'formik';
import { Box, Typography } from '@mui/material';
import CurrencyConversionContainer from '../../currency/conversion/CurrencyConversionContainer';
import AmountField from '../../fields/number/AmountField';
import { useTranslation } from 'react-i18next';
import CurrencySelectionField from '../../currency/CurrencySelectionField';
import ToggleField from '../../fields/selection/ToggleField';

const PricingInfoTabContent = () => {
  const { t: transFields } = useTranslation('fields');
  const { t: transButtons } = useTranslation('buttons');

  const {
    values,
    setValues,
    errors,
    touched,
    isSubmitting,
    handleChange,
    handleBlur,
  } = useFormikContext();

  const handleListPriceUpdate = ({ conversionInfo }) => {
    const rate = conversionInfo.rate;
    const amount = conversionInfo.amount;
    const listPrice = rate * amount;

    // console.log('New List Price Conversion Info -> ', conversionInfo);

    setValues({
      ...values,
      list_price: listPrice,
      currency: {
        ...values.currency,
        rate: conversionInfo.rate,
        timestamp: conversionInfo.timestamp,
        base_amount: {
          ...values.currency.base_amount,
          list_price: conversionInfo.amount,
        },
      },
    });
  };

  const handleMinPriceUpdate = ({ conversionInfo }) => {
    const rate = conversionInfo.rate;
    const amount = conversionInfo.amount;
    const minPrice = rate * amount;

    // console.log('New Min Price Conversion Info -> ', conversionInfo);

    setValues({
      ...values,
      min_price: minPrice,
      currency: {
        ...values.currency,
        rate: conversionInfo.rate,
        timestamp: conversionInfo.timestamp,
        base_amount: {
          ...values.currency.base_amount,
          min_price: conversionInfo.amount,
        },
      },
    });
  };

  // console.log('Product Values -> ', values);
  return (
    <Box flex={1} display="flex" flexDirection="column" gap={5}>
      {/* Enable Purchase Option */}
      <ToggleField
        fieldID="isPurchasable"
        text={transButtons('enable_purchase')}
      />

      {values?.isPurchasable === true && (
        <>
          {/* Currency Selector */}
          <Box flex={1} display="flex" flexDirection="column" gap={1}>
            {/* Title */}
            <Box>
              <Typography variant="body" mb={2}>
                {transFields('products.currency.title')}
              </Typography>
            </Box>
            {/* Description */}
            <Box>
              <Typography mb={2}>
                {transFields('products.currency.description')}
              </Typography>
            </Box>
            <Box>
              <CurrencySelectionField
                fieldID={'currency.symbol'}
                required
                sx={{ width: 200 }}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    min_price: 0,
                    list_price: 0,
                    currency: {
                      ...values.currency,
                      symbol: newValue,
                      rate: 1,
                      timestamp: null,
                      base_amount: {
                        list_price: 0,
                        min_price: 0,
                      },
                    },
                  });
                }}
              />
            </Box>
          </Box>

          {/* List Price */}
          <Box flex={1} display="flex" flexDirection="column" gap={1}>
            {/* Title */}
            <Box>
              <Typography variant="body" mb={2}>
                {transFields('products.list_price.title')}
              </Typography>
            </Box>

            {/* Description */}
            <Box>
              <Typography mb={2}>
                {transFields('products.list_price.description')}
              </Typography>
            </Box>

            {/* Currency Selector & Converter */}
            {values.from !== 'USD' ? (
              <Box>
                <CurrencyConversionContainer
                  from={values?.currency?.symbol}
                  to="USD"
                  amount={values?.currency?.base_amount?.list_price}
                  rate={values?.currency?.rate}
                  timestamp={values?.currency?.timestamp}
                  handleConvertedCurrency={handleListPriceUpdate}
                />
              </Box>
            ) : null}

            {/* List Price Amount */}
            <Box>
              <AmountField
                required
                disabled={isSubmitting}
                fieldID="list_price"
                transPrefix="products.list_price"
                value={values.list_price}
                onChange={handleChange}
                onBlur={handleBlur}
                decimalScale={2}
                inputAdornment={null}
                fixedDecimalScale={true}
                thousandSeparator={true}
                error={errors.list_price && touched.list_price}
                helperText={
                  errors.list_price && touched.list_price
                    ? errors.list_price
                    : ''
                }
                sx={{
                  width: 200,
                }}
              />
            </Box>
          </Box>

          {/* Minimum Sales Price */}
          <Box flex={1} display="flex" flexDirection="column" gap={1}>
            {/* Title */}
            <Box>
              <Typography variant="body" mb={2}>
                {transFields('products.min_price.title')}
              </Typography>
            </Box>

            {/* Description */}
            <Box>
              <Typography mb={2}>
                {transFields('products.min_price.description')}
              </Typography>
            </Box>

            {/* Currency Conversion */}
            {values.from !== 'USD' ? (
              <Box flex={1} display="flex" flexDirection="column" gap={2}>
                <CurrencyConversionContainer
                  from={values?.currency?.symbol}
                  to="USD"
                  amount={values?.currency?.base_amount?.min_price}
                  rate={values?.currency?.rate}
                  timestamp={values?.currency?.timestamp}
                  handleConvertedCurrency={handleMinPriceUpdate}
                />
              </Box>
            ) : null}

            {/* Min Price */}
            <Box>
              <AmountField
                required
                disabled={isSubmitting}
                fieldID="min_price"
                transPrefix="products.min_price"
                value={values.min_price}
                onChange={handleChange}
                onBlur={handleBlur}
                decimalScale={2}
                fixedDecimalScale={true}
                error={errors.min_price && touched.min_price}
                helperText={
                  errors.min_price && touched.min_price ? errors.min_price : ''
                }
                sx={{
                  width: 200,
                }}
              />
            </Box>
          </Box>

          {values.currency.symbol !== 'USD' && values?.currency.timestamp && (
            <Typography variant="body1">
              {transFields('products.currency.conversion.timestamp.label', {
                rate: values?.currency?.rate,
                date:
                  values?.currency?.timestamp &&
                  values.currency.timestamp.toLocaleString('en-US'),
              })}
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};

export default PricingInfoTabContent;
