import { useTranslation } from 'react-i18next';
import DashboardLayoutComponent from '../../../components/layout/DashboardLayout';

import UserDashboardComponent from '../../../components/account/dashboard/UserDashboardComponent';

const UserDashboardPage = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'pages.account.UserDashboardPage',
  });

  return (
    <DashboardLayoutComponent
      dashboardTitle={t('title')}
      dashboardDescription={t('description')}
    >
      <UserDashboardComponent />
    </DashboardLayoutComponent>
  );
};

export default UserDashboardPage;
