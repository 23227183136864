import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { InputAdornment, Skeleton, TextField } from '@mui/material';
import ProcessingContext from '../../../context/providers/ProcessingProvider';
import { getNestedValue } from '../../../helpers/getNestedValuesIn';
import { NumericFormat } from 'react-number-format';

// https://s-yadav.github.io/react-number-format/
const AmountField = ({
  fieldTitle, // Title above the field
  fieldDescription, // Field description above the field
  fieldID = 'dialog.amount', // Determines the Name and IDD of the Field
  transNS = 'fields', // Namespace for translation Value
  transPrefix = 'AmountField', // KeyPrefix which holds label and placeholder
  transTypeID = 'max', // Defines The Translation Type e.g. User, Company
  transition = true, // Whether the field is being shown and faded in
  disabled = false, // Diables the field
  required = false, // Whether the field is required or not
  variant = 'outlined',
  isLoadingManual = false, // Set Loading State Manually
  showInitialErrors = false, // Show Errors even when the field has not been touched
  inputAdornment = '$', // sign at the end of the field e.g. $, %
  allowNegative = false, // Allow Negative Numbers
  valueIsNumericString = true, // Stringify the number output
  thousandSeparator = true, // Enable / Disable Thousand Seperator
  decimalScale = 0, // Amount of Decimals
  fixedDecimalScale = false, // Whether the Decimal Scale is Fixed
  ...props
}) => {
  // Translation
  const { t: transFields } = useTranslation(transNS, {
    keyPrefix: transPrefix,
  });

  const { t: transTypes } = useTranslation('types');

  //   Formik Context
  const { values, touched, errors, handleBlur, setFieldValue } =
    useFormikContext();

  const { isLoading } = useContext(ProcessingContext);

  const fieldValue = getNestedValue(values, fieldID);
  const fieldError = getNestedValue(errors, fieldID);
  const fieldTouched = getNestedValue(touched, fieldID);

  return isLoading.status || isLoadingManual ? (
    <Skeleton variant="rectangular" width={'100%'} height={60} />
  ) : (
    <NumericFormat
      disabled={disabled}
      required={required}
      id={fieldID}
      name={fieldID}
      autoComplete="off"
      aria-invalid={errors[fieldID] ? 'true' : 'false'}
      aria-describedby="uidnote"
      variant={variant}
      label={transFields('label', { type: transTypes(transTypeID) })}
      placeholder={transFields('placeholder', {
        type: transTypes(transTypeID),
      })}
      onValueChange={(values) => {
        setFieldValue(fieldID, values.floatValue);
      }}
      onBlur={handleBlur}
      value={fieldValue}
      error={showInitialErrors ? fieldError : fieldError && fieldTouched}
      helperText={fieldError && fieldTouched ? fieldError : null}
      customInput={TextField}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{inputAdornment}</InputAdornment>
        ),
        sx: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0,
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        },
      }}
      thousandSeparator={thousandSeparator}
      allowNegative={allowNegative}
      valueIsNumericString={valueIsNumericString}
      decimalScale={decimalScale}
      fixedDecimalScale={fixedDecimalScale}
      {...props}
    />
  );
};

export default AmountField;
