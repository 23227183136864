import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  CardHeader,
  Typography,
  useTheme,
  Badge,
} from '@mui/material';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import CanvasContainer from '../new-product/container/image/CanvasContainer';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const ProductCard = ({ product }) => {
  const { t: transButtons } = useTranslation('buttons');
  const { t: transTypes } = useTranslation('types');
  const theme = useTheme();
  const navigate = useNavigate();

  const imageType = useMemo(() => {
    if (!product || !Array.isArray(product.images) || !product.images.length) {
      return null;
    }

    return product.images[0].imageType.toLowerCase();
  }, [product]);

  const defaultImage = useMemo(() => {
    if (!product || !Array.isArray(product.images) || !product.images.length) {
      return null;
    }
    return product.images[0];
  }, [product]);

  const description = useMemo(() => {
    if (!product.descriptions || !product.descriptions.length) return '';
    if (
      !product.descriptions[0].description ||
      !product.descriptions[0].description.blocks ||
      !product.descriptions[0].description.blocks.length
    )
      return '';
    return product.descriptions[0].description.blocks[0].text;
  }, [product]);

  const isHighlightedProduct = useMemo(() => {
    const productCreationDate = dayjs(product.created);

    const isNew = dayjs().diff(productCreationDate, 'week') < 2;
    const staffpick = product.staffpick;

    if (staffpick) {
      return {
        label: transTypes('badges.pick'),
        color: 'error',
      };
    } else if (isNew) {
      return {
        label: transTypes('badges.new'),
        color: 'primary',
      };
    } else {
      return {
        label: null,
        color: null,
      };
    }
  }, [product]);

  const [initialMeshes, setInitialMeshes] = useState([]);
  const [meshes, setMeshes] = useState([]);
  const [cameraPosition, setCameraPosition] = useState([0, 0, 50]);
  const [rotationX, setRotationX] = useState(0);
  const [rotationY, setRotationY] = useState(0);
  const [isProductImageDirtied, setIsProductImageDirtied] = useState(false);
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.3 }}
      layout
    >
      <Badge
        badgeContent={isHighlightedProduct.label}
        color={isHighlightedProduct.color}
        sx={{ width: '100%', height: '100%' }}
      >
        <Card
          sx={{
            maxWidth: 345,
            width: 345,
            maxHeight: 460,
            boxShadow: theme.shadows[4],
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <CardHeader
            title={
              <Typography
                variant="h6"
                component="div"
                sx={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  navigate(`/product/${product._id}`, {
                    state: { from: window.location.hostname },
                  })
                }
              >
                {product.product_name?.slice(0, 61)}
              </Typography>
            }
            subheader={
              <Typography color="text.primary">{product.brand_name}</Typography>
            }
            sx={{
              '& span': {
                fontSize: '1.2rem',
              },
            }}
          />

          {/* Image */}
          {imageType !== 'gltf' && defaultImage ? (
            <CardMedia
              sx={{ minHeight: 140 }}
              image={defaultImage.url}
              title={product.product_name?.slice(0, 61)}
            />
          ) : imageType === 'gltf' ? (
            <Box minHeight={140} height={140}>
              <CanvasContainer
                url={defaultImage.url}
                options={defaultImage.options}
                viewMode="view"
                showSliders={false}
                minHeight="140px"
                rotationX={rotationX}
                setRotationX={setRotationX}
                rotationY={rotationY}
                setRotationY={setRotationY}
                cameraPosition={cameraPosition}
                setCameraPosition={setCameraPosition}
                setIsProductImageDirtied={setIsProductImageDirtied}
                meshes={meshes}
                setMeshes={setMeshes}
                setInitialMeshes={setInitialMeshes}
              />
            </Box>
          ) : (
            <Box
              height={140}
              display="flex"
              alignItems="center"
              justifyContent="center"
              bgcolor="grey.200"
            >
              <PrecisionManufacturingIcon
                style={{ fontSize: 60, color: theme.palette.primary.main }}
              />
            </Box>
          )}
          <CardContent>
            <Typography
              variant="body2"
              minHeight={100}
              maxHeight={100}
              overflow="scroll"
            >
              {description}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              px: 2,
            }}
          >
            <Typography>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: product.currency.symbol || 'USD',
              }).format(product.list_price)}
            </Typography>
            <Button
              size="small"
              variant="contained"
              onClick={() =>
                navigate(`/product/${product._id}`, {
                  state: { from: window.location.hostname },
                })
              }
            >
              {transButtons('details')}
            </Button>
          </CardActions>
        </Card>
      </Badge>
    </motion.div>
  );
};

export default ProductCard;
