import { Box, Typography } from '@mui/material';
import LogRocket from 'logrocket';
import { Component } from 'react';
import { Link } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, info) {
    console.error('ErrorBoundary caught an error', error, info);

    // LogRocket is only set in production
    if (process.env.NODE_ENV === 'production') {
      LogRocket.captureException(error, {
        extra: info,
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box
          width="100vw"
          height="100vh"
          display="flex"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          flexDirection="column"
          bgcolor="#333333"
          color="white"
        >
          <Typography textAlign="center" variant="body1">
            There was an error.
          </Typography>
          <Typography textAlign="center" variant="body1">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault();
                window.location.replace('/');
              }}
            >
              Click here
            </Link>{' '}
            to go back to the home page.
          </Typography>
          <Typography variant="body2" textAlign="center">
            Rest assured our engineering team has been notified and is working
            on a solution. If the problem persists please contact
            support@in2tec.io
          </Typography>
        </Box>
      );
    }

    // No error case
    return this.props.children;
  }
}

export default ErrorBoundary;
