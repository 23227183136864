import { useState, Children, createContext, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Step,
  StepLabel,
  Stepper,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n.js';

export const FormikStep = ({ children }) => {
  return <>{children}</>;
};

export const FormikStepperContext = createContext();

const FormikStepper = ({ children, ...props }) => {
  const { t } = useTranslation();
  const { t: transButtons } = useTranslation('buttons');
  const childrenArray = Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const [stepperFormData, setStepperFormData] = useState({});

  // Language State
  const [userLanguage, setUserLanguage] = useState(i18n.language);
  const [formikContext, setFormikContext] = useState(null);

  const formikContextRef = useRef(null);

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (formikContext && userLanguage !== i18n.language) {
      formikContext.resetForm();
      setStepperFormData({});
      setUserLanguage(i18n.language);
      setStep(0);
    }
  }, [i18n.language, formikContext, userLanguage]);

  useEffect(() => {
    if (formikContextRef.current) {
      setFormikContext(formikContextRef.current);
    }
  }, []);

  // SetUp Initial Values
  const initialValues = {
    ...currentChild.props.initialValues,
    ...stepperFormData,
  };

  const handleOnSubmit = async (values, helpers) => {
    if (isLastStep()) {
      await props.onSubmit(values, helpers);
      setCompleted(true);
    } else {
      setStepperFormData({ ...values, ...stepperFormData });
      setStep((s) => s + 1);
      setTimeout(() => {
        helpers.setTouched({});
      }, 100);
    }
  };

  return (
    <FormikStepperContext.Provider
      value={{ stepperFormData, setStepperFormData }}
    >
      <Formik
        {...props}
        initialValues={initialValues}
        validationSchema={currentChild.props.validationSchema}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        onSubmit={handleOnSubmit}
      >
        {(formikProps) => {
          if (!formikContextRef.current) {
            formikContextRef.current = formikProps;
          }

          // const { values } = formikProps;

          // console.log('Values -> ', values);

          return (
            <Form
              autoComplete="off"
              className="form-horizontal intu__form form-dark"
              role="form"
            >
              <FormControl fullWidth>
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Stepper
                      alternativeLabel
                      activeStep={step}
                      // sx={{ marginBottom: '2rem' }}
                    >
                      {childrenArray.map(
                        (child, index) =>
                          child.props?.includeNav !== false && (
                            <Step
                              key={child.props.label}
                              completed={step > index || completed}
                            >
                              <StepLabel>{child.props.label}</StepLabel>
                            </Step>
                          ),
                      )}
                    </Stepper>
                  </Box>
                  <Box sx={{ p: '5rem' }}>{currentChild}</Box>
                  <Box display="flex" flexDirection="row">
                    {step > 0 ? (
                      <Box>
                        <Button
                          disabled={formikProps.isSubmitting}
                          variant="outlined"
                          color="secondary"
                          sx={{ mr: 1 }}
                          onClick={() => {
                            setStepperFormData({
                              ...stepperFormData,
                              ...formikProps.values,
                            });
                            setStep((s) => s - 1);
                            setTimeout(() => {
                              formikProps.setTouched({});
                            }, 100);
                          }}
                        >
                          {transButtons('back')}
                        </Button>
                      </Box>
                    ) : null}
                    <Box sx={{ marginLeft: 'auto' }}>
                      <Button
                        startIcon={
                          formikProps.isSubmitting ? (
                            <CircularProgress size="1rem" />
                          ) : null
                        }
                        disabled={
                          formikProps.isSubmitting || !formikProps.isValid
                        }
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        {formikProps.isSubmitting
                          ? t('status_messages.submitting')
                          : isLastStep()
                            ? transButtons('complete')
                            : transButtons('next')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </FormControl>
            </Form>
          );
        }}
      </Formik>
    </FormikStepperContext.Provider>
  );
};

export default FormikStepper;
