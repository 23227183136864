import { axiosPrivate } from '../hooks/axios/axios';
import { t } from 'i18next';

const searchCompaniesByName = async (input) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = `/api/companies/company/name/${input}`;

  try {
    const request = await axiosPrivate.get(url, {
      signal,
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    const { data, status, message, statusCode } = request?.data;

    return {
      status,
      statusCode,
      message,
      data,
    };
  } catch (error) {
    return error;
  }
};

const getCompanyDetails = async (id) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const url = `/api/companies/company/details`;

  try {
    const request = await axiosPrivate.get(url, {
      signal,
      params: { id: id },
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });

    const { data, status, message, statusCode } = request?.data;

    if (statusCode === 200) {
      return {
        status,
        statusCode,
        message,
        data,
      };
    } else {
    }
  } catch (error) {
    return error;
  }
};

const getAllCompanies = async () => {
  try {
    const { data, status } = await axiosPrivate.get(`/api/companies`);
    if (status === 200) {
      return data.data;
    }
  } catch (error) {
    console.error('error', error);
    return [];
  }
};

const searchForExisitingEmployee = async (id) => {
  const req_fields = [];
  !id && req_fields.push(t('routes.req_fields.id'));
  const url = 'api/companies/company/search/employee';

  const payload = {
    data: [
      {
        value: id,
      },
    ],
  };

  try {
    const response = await axiosPrivate.post(url, JSON.stringify(payload), {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    });
    const { status, message, data } = response;
    if (status === 200) {
      if (data.length) {
        return true;
      } else {
        return false;
      }
    } else if (status === 228) {
      return false;
    } else {
      return {
        status,
        message,
      };
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export {
  searchCompaniesByName,
  getCompanyDetails,
  getAllCompanies,
  searchForExisitingEmployee,
};
