import { useRef, useContext, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Stack, FormControl, Grid } from '@mui/material';
import { classes } from '../../../../settings/theme.js';
import { useTranslation } from 'react-i18next';
import { CompanyProfileContext } from '../../../../context/company/CompanyProfileProvider.jsx';

import { createCompanyProfileValidationSchema } from '../../ValidationSchema.js';
import CompanyProfilePicture from '../../../account/company/CompanyProfilePicture.jsx';

import CompanyNameField from '../../../account/company/CompanyNameField.jsx';
import EmailField from '../../../account/fields/email/EmailField.jsx';
import WebsiteField from '../../../account/WebsiteField.jsx';

import { useNavigationFooter } from '../../../../context/footer/NavigationFooterContext.jsx';
import { SaveAlt } from '@mui/icons-material';
import { useProcessingHandler } from '../../../../hooks/useProcessingHandler.js';
import FormikTabs from '../../../formik/FormikTabs.jsx';
import AboutCompanyTab from './tabs/AboutCompanyTab.jsx';
import ProductApplicationsTab from './tabs/ProductApplicationsTab.jsx';
import CompanyBrandsTab from './tabs/CompanyBrandsTab.jsx';
import CompanyEmployeesTab from './tabs/CompanyEmployeesTab.jsx';
import { useNavigate } from 'react-router-dom';
import CompanyPaymentTermsTab from './tabs/CompanyPaymentTermsTab.jsx';
import CompanyCommissionTab from './tabs/CompanyCommissionTab.jsx';
import CompanyThemeTab from './tabs/CompanyThemeTab.jsx';
import CompanyDetailsTab from './tabs/CompanyDetailsTab.jsx';
import useValidateFormikForm from '../../../../helpers/forms/useValidateFormikForm.jsx';
import FormikValidationErrorDialog from '../../../status/FormikValidationErrorDialog.jsx';

const CompanyProfileForm = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'components.account.company.CompanyProfileForm',
  });
  const { t: transTypes } = useTranslation('types');
  const { t: transButtons } = useTranslation('buttons');

  const { setFooterConfig } = useNavigationFooter();

  const { validate, dialogState, handleClose } =
    useValidateFormikForm('company');

  const { companyProfile, updateCompanyProfile } = useContext(
    CompanyProfileContext,
  );
  const { isLoading } = useProcessingHandler();

  const validationSchema = createCompanyProfileValidationSchema();
  const formikRef = useRef(null);

  const [isReinitialize, setIsReinitialize] = useState(true);

  const navigate = useNavigate();

  // Set Footer Navigation
  useEffect(() => {
    setFooterConfig((prev) => ({
      ...prev,
      confirmLabel: transButtons('update', {
        type: transTypes('profile'),
      }),
      ConfirmIcon: SaveAlt,
      onConfirm: async () => {
        const formik = formikRef.current;
        const { values, resetForm, setTouched } = formik;

        if (formik) {
          const isValid = await validate(formik);

          if (isValid) {
            const update = await updateCompanyProfile(values);

            if (update.status === 'error ') {
              resetForm();
            } else {
              setTouched({}, false);
            }
          } else {
            return;
          }
        }
      },
      isLoading: isLoading.status,
      isDisabled: !companyProfile?.isAdmin,
      showFooter: true,
      onBack: () => {
        formikRef.current.resetForm();
        navigate(-1);
      },

      showConfirm: true,
    }));

    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, [isLoading.status, !companyProfile?.isAdmin]);

  const profileTabs = [
    {
      title: t('tabs.about'),
      content: <AboutCompanyTab />,
    },
    {
      title: t('tabs.details'),
      content: <CompanyDetailsTab />,
    },
    {
      title: t('tabs.brands'),
      content: <CompanyBrandsTab />,
      visible: companyProfile.type === 'manufacturer',
    },
    {
      title: t('tabs.product_applications'),
      content: <ProductApplicationsTab />,
      visible: !companyProfile.type === 'marketplace' ? false : true,
    },
    {
      title: t('tabs.employees'),
      content: <CompanyEmployeesTab />,
    },
    {
      title: t('tabs.payment_terms'),
      content: <CompanyPaymentTermsTab />,
      visible: companyProfile.type === 'manufacturer',
    },
    {
      title: t('tabs.commission'),
      content: <CompanyCommissionTab />,
      visible: companyProfile.type === 'manufacturer',
    },
    {
      title: t('tabs.theme'),
      content: <CompanyThemeTab />,
      visible: companyProfile.type === 'manufacturer',
    },
  ];

  const initialValues = {
    ...companyProfile,
  };

  return (
    <Grid className="content" sx={{ marginBottom: '8rem' }}>
      <Grid item className="content" xs={12}>
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          validateOnChange={true}
          enableReinitialize={isReinitialize}
        >
          {() => {
            return (
              <Form className="form-horizontal intu__form" role="form">
                <FormControl fullWidth>
                  <Stack
                    alignItems="stretch"
                    spacing={4}
                    style={classes.root}
                    sx={{ paddingBottom: '30px' }}
                  >
                    <Grid
                      container
                      justifyContent={{
                        sm: 'space-around',
                      }}
                      alignItems={{
                        xs: 'stretch',
                        md: 'center',
                      }}
                      direction={{
                        xs: 'column',
                        sm: 'row',
                        md: 'row',
                      }}
                    >
                      {/* Company Profile Picture */}
                      <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          marginBottom: { xs: '2rem', xl: 0 },
                        }}
                      >
                        <CompanyProfilePicture
                          disabled={!companyProfile.isAdmin}
                          setReinitialize={setIsReinitialize}
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <Stack
                          spacing={3}
                          direction={{ xs: 'column' }}
                          alignItems="stretch"
                        >
                          <Stack
                            spacing={0}
                            alignItems="center"
                            direction={{ xs: 'column', lg: 'row' }}
                          >
                            <Grid item xs={12} sx={{ width: '100%' }}>
                              <CompanyNameField
                                disabled={true}
                                editable={true}
                                showNameChange={true}
                                fieldID="name"
                                autocompleteID="name"
                              />
                            </Grid>
                          </Stack>

                          {/* Email Website */}
                          <Stack
                            spacing={{ xs: 3, sm: 3, md: 1 }}
                            direction={{
                              xs: 'column',
                              sm: 'column',
                              md: 'row',
                            }}
                            style={classes.root}
                            alignItems="stretch"
                          >
                            <EmailField
                              required={true}
                              disabled={!companyProfile.isAdmin}
                              fieldID="email"
                              transNS="fields"
                              transPrefix="account.company.EmailField"
                            />
                            <WebsiteField
                              required={true}
                              disabled={!companyProfile.isAdmin}
                              fieldID="website"
                              transNS="fields"
                              transPrefix="account.WebsiteField"
                              type={transTypes('company')}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>

                    <FormikTabs tabsContent={profileTabs} isScrollable={true} />
                  </Stack>
                </FormControl>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      <FormikValidationErrorDialog
        open={dialogState.open}
        title={dialogState.title}
        message={dialogState.message}
        onClose={handleClose}
      />
    </Grid>
  );
};

export { CompanyProfileForm };
