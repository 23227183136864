import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';

const defaultStatusOptions = [
  { value: 'draft', label: 'Draft' },
  { value: 'archived', label: 'Archived' },
  { value: 'published', label: 'Published' },
];

const StatusBox = ({ formik, setProductDetails }) => {
  const { values, setFieldValue, errors, touched } = formik;
  const theme = useTheme();

  const handleChangeClick = (e) => {
    const { name, value } = e.target;

    // handleChange(e);
    setFieldValue(name, value);
    setProductDetails(value);
  };

  return (
    <Box>
      <FormControl sx={{ flex: 0.4 }}>
        <Select
          onChange={handleChangeClick}
          name="status"
          defaultValue={defaultStatusOptions[0].value}
          error={errors.status && touched.status}
          value={values.status}
          sx={{
            minWidth: 120,
            height: 30,
            color: 'common.white',
            backgroundColor: 'transparent',
            border: `1px solid ${
              values.status === 'draft'
                ? theme.palette.blue.main
                : values.status === 'archived'
                  ? theme.palette.red.main
                  : theme.palette.primary.main
            }`,
            borderRadius: '8px',
            '& .MuiSelect-icon': { color: 'white' },
            '&:hover': {
              backgroundColor: theme.palette.secondary.main,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        >
          {defaultStatusOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Typography
                sx={{
                  color:
                    values.status === 'draft'
                      ? theme.palette.blue.main
                      : values.status === 'archived'
                        ? theme.palette.red.main
                        : theme.palette.primary.main,
                }}
              >
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default StatusBox;
