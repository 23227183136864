// useValidateFormikForm.jsx
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useValidateFormikForm = (typeKey = 'product') => {
  const { t: transTypes } = useTranslation('types');
  const { t: transMessages } = useTranslation('messages');

  const [dialogState, setDialogState] = useState({
    open: false,
    title: '',
    message: null,
  });

  const handleClose = useCallback(() => {
    setDialogState({ open: false, title: '', message: null });
  }, []);

  const validate = useCallback(
    async (formik) => {
      if (!formik?.validateForm) return false;

      const formErrors = await formik.validateForm();

      if (Object.keys(formErrors).length > 0) {
        const errorMessages = Object.entries(formErrors).map(
          ([field, message]) => {
            if (typeof message === 'object') {
              const subErrors = Object.entries(message).map(
                ([subField, subMessage]) => ({
                  subField: subField.replace(/_/g, ' '),
                  subMessage: subMessage.replace(/_/g, ' '),
                }),
              );
              return {
                field: field.replace(/_/g, ' '),
                subErrors,
              };
            }
            return {
              field: field.replace(/_/g, ' '),
              message: message.replace(/_/g, ' '),
            };
          },
        );

        setDialogState({
          open: true,
          title: transMessages('form.invalid', { type: transTypes(typeKey) }),
          message: errorMessages,
        });

        return false;
      }

      return true;
    },
    [transMessages, transTypes, typeKey],
  );

  return { validate, dialogState, handleClose };
};

export default useValidateFormikForm;
