import { useContext } from 'react';
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CartContext } from '../../../context/cart/CartContext.jsx';
import OrderShippingContainer from '../OrderShippingContainer.jsx';
import OrderTotalsProduct from '../OrderTotalsProduct.jsx';
import TaxTotalContainer from '../TaxTotalContainer.jsx';
import OrderTotalsPaymentContainer from './payment/OrderTotalsPaymentContainer.jsx';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import ElementsContainer from '../ElementsContainer.jsx';

const OrderTotalsContainer = ({
  shippingAddress,
  shippingIndividual,
  billingIndividual,
  billingAddress,
}) => {
  const { isValid } = useFormikContext();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const { cartProducts, cartSubTotal, cartTotal } = useContext(CartContext);
  const { t } = useTranslation('container', {
    keyPrefix: 'OrderTotalsContainer',
  });

  return cartProducts.length ? (
    <Box
      display="flex"
      flexDirection="column"
      margin="0 auto 60px"
      width={isMedium ? '100%' : '87%'}
      minWidth={isMedium ? 'auto' : 800}
    >
      <Divider />
      <Typography my={2} variant="h6">
        {t('title')}
      </Typography>
      <Box
        boxShadow={2}
        display="flex"
        flexDirection="column"
        border="1px solid"
        borderColor="secondary.light"
        borderRadius="4px"
      >
        <Box>
          {cartProducts.map((product) => (
            <OrderTotalsProduct key={product.product._id} product={product} />
          ))}
          <Box px={4} my={2}>
            <Divider />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              my={2}
            >
              <Typography flex={1} fontWeight={800}>
                {t('subtotal')}
              </Typography>
              <Typography flex={0.25} textAlign="right">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(cartSubTotal)}
              </Typography>
            </Box>
            <Divider />
          </Box>
        </Box>
        <Box display="flex">
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            {/* Shipping Cost */}
            {isValid ? (
              <OrderShippingContainer />
            ) : (
              <Box p={4} mb={4}>
                <Typography variant="body1" color="error" textAlign="center">
                  {t('missing_shipping_billing_details')}
                </Typography>
              </Box>
            )}

            {isValid ? (
              <>
                {/* Tax */}
                <Box p={4}>
                  <TaxTotalContainer />
                  <Divider />
                </Box>

                {/* Order Total */}

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={4}
                  mb={2}
                >
                  <Typography
                    fontWeight={800}
                    textTransform="none"
                    flex={1}
                    color="primary.dark"
                  >
                    {t('total')}:
                  </Typography>
                  <Typography flex={0.8} textAlign="right" color="primary.dark">
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(cartTotal)}
                  </Typography>
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
        {isValid ? (
          <ElementsContainer>
            <OrderTotalsPaymentContainer
              shippingAddress={shippingAddress}
              shippingIndividual={shippingIndividual}
              billingIndividual={billingIndividual}
              billingAddress={billingAddress}
            />
          </ElementsContainer>
        ) : null}
      </Box>
    </Box>
  ) : null;
};

export default OrderTotalsContainer;
