import { useContext, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from '../../../../../i18n.js';
import useAuth from '../../../../../hooks/useAuth.js';
import { ApplicationsContext } from '../../../../../context/applications/ApplicationsContextProvider.jsx';
import { ProductTypeContext } from '../../../../../context/providers/ProductTypeContextProvider.jsx';
import { CompanyProfileContext } from '../../../../../context/company/CompanyProfileProvider.jsx';
import { FormikStepperContext } from '../../../../formik/FormikStepper.jsx';
import { BrandsContext } from '../../../../../context/brands/BrandsProvider.jsx';
import CompanyNameField from '../../../company/CompanyNameField.jsx';
import CompanyBrandsField from '../../../company/CompanyBrandsField.jsx';
import BusinessTypeField from '../../../BusinessTypeField.jsx';
import EmailField from '../../../fields/email/EmailField.jsx';
import WebsiteField from '../../../WebsiteField.jsx';
import PhoneField from '../../../PhoneField .jsx';
import AddressField from '../../../../address/AddressField.jsx';
import AccountTypeField from '../../../fields/type/AccountTypeField.jsx';
import { createInitialValuesBusinessDetailsForm } from '../InitialValues.js';
import useUser from '../../../../../hooks/useUser.js';

const BusinessDetailsForm = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'context.account.SignUpComplete.BusinessDetailsForm',
  });
  const { auth, setAuth } = useAuth();
  const { user } = useUser();
  const { setNewApplicationList } = useContext(ApplicationsContext);
  const { setNewProductTypeList } = useContext(ProductTypeContext);
  const {
    newCompany,
    setNewCompany,
    setCompanyProfile,
    companyProfile,
    initialCompanyProfileValues,
  } = useContext(CompanyProfileContext);
  const { setNewBrandsList } = useContext(BrandsContext);
  const { values, resetForm, validateForm, setFieldValue, setFieldTouched } =
    useFormikContext();
  const { setStepperFormData } = useContext(FormikStepperContext);

  // Handle Business Type Change

  // Set Business TYpe
  useEffect(() => {
    if (values.account_type === 'manufacturer') {
      setFieldValue('business_type', 'company');
      setFieldTouched('business_type', true, true);
    }
  }, [values.account_type]);

  // Handle Clear Form
  const handleClear = async () => {
    const blankForm = createInitialValuesBusinessDetailsForm({ user, auth });

    // Clear Parent Form Values
    setNewCompany(true);
    setNewApplicationList([]);
    setNewProductTypeList([]);
    setNewBrandsList([]);
    setCompanyProfile(initialCompanyProfileValues);
    setStepperFormData(blankForm);

    // Clear Child Foem
    resetForm(blankForm);
    setTimeout(() => validateForm(), 300);
  };

  // set Initial Fields touched
  useEffect(() => {
    setFieldTouched('company.phone', true);
  }, []);

  // Set Company Info to False if companyPforileData exists
  // Set Compaany in case company Info is present
  useEffect(() => {
    console.log('Got Company Info');
    if (companyProfile?._id) {
      console.log('set New Company to false');
      setNewCompany(false);
    }
  }, [companyProfile?._id]);

  // Set Account Type to hide certain tabs on selection
  useEffect(() => {
    setAuth({
      ...auth,
      user_info: {
        ...auth.user_info,
        account_type: values.account_type,
      },
    });
  }, [values.account_type]);

  console.log('Company Profile -> ', companyProfile);
  console.log('Values -> ', values);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      sx={{ paddingBottom: '50px' }}
    >
      {((values.business_type === 'company' && newCompany === false) ||
        values?.company?.isSubsidiary) && (
        <>
          <Button variant="outlined" color="error" onClick={handleClear}>
            {i18n.t('buttons.clear')}
          </Button>
        </>
      )}

      {/* Show Account Type Field for LinkedIn Users */}
      {!auth?.user_info?.account_type && (
        <AccountTypeField
          fieldTitle={t('form.account_type.title')}
          fieldDescription={t('form.account_type.description')}
          required
        />
      )}

      <BusinessTypeField
        fieldTitle={t('form.business_type.title')}
        fieldDescription={t('form.business_type.description')}
        transition={
          !values?.account_type || values.account_type === 'manufacturer'
            ? false
            : true
        }
        disabled={values?.account_type === 'manufacturer'}
      />

      {values.business_type === 'company' ? (
        <>
          <CompanyNameField
            fieldTitle={t('form.company.title')}
            fieldDescription={t('form.company.description')}
            transition={values.business_type === 'company'}
            required={values?.business_type === 'company'}
            disabled={
              values?.business_type === 'company' && newCompany === false
            }
            showAdmins={true}
            transNS="fields"
            transPrefix="account.company.CompanyNameField"
          />

          <CompanyBrandsField
            fieldTitle={t('form.brands.title')}
            fieldDescription={t('form.brands.description')}
            transition={
              values.account_type === 'manufacturer' &&
              (newCompany === true || values?.company?.isSubsidiary === true)
                ? true
                : false
            }
            required={
              auth?.user_info?.account_type === 'manufacturer' &&
              (newCompany === false || values?.company?.isSubsidiary === true)
            }
            disabled={
              (auth?.user_info?.account_type !== 'manufacturer' &&
                values.account_type !== 'manufacturer') ||
              (values.business_type === 'company' &&
                (newCompany === false ||
                  values?.company?.isSubsidiary === true))
            }
          />
          <AddressField
            fieldTitle={t('form.address.title')}
            fieldDescription={t('form.address.description')}
            labelID={values?.company?.name}
            autocompleteID="company.address"
            phoneFieldID="company.phone"
            fieldID="company.address"
            transNS="fields"
            transPrefix="AddressField"
            required={values?.company ? true : false}
            transition={
              (values.business_type === 'company' ? true : false) ||
              (auth?.user_info?.account_type === 'manufacturer' &&
                values.business_type === 'company')
                ? true
                : false
            }
            disabled={!newCompany}
          />
          <WebsiteField
            fieldTitle={t('form.website.title')}
            fieldDescription={t('form.website.description')}
            transition={values.business_type === 'company'}
            disabled={
              values.business_type === 'company' && newCompany === false
            }
            required={values.business_type === 'company'}
            fieldID="company.website"
            transNS="fields"
            transPrefix="account.company.WebsiteField"
          />
          <EmailField
            fieldTitle={t('form.email.title')}
            fieldDescription={t('form.email.description')}
            fieldID="company.email"
            transNS="fields"
            transPrefix="account.company.EmailField"
            transition={values.business_type === 'company' ? true : false}
            required={values.business_type === 'company'}
            disabled={
              values.business_type === 'company' && newCompany === false
            }
          />
          <PhoneField
            fieldTitle={t('form.phone.title')}
            fieldDescription={t('form.phone.description')}
            fieldID="company.phone"
            transition={values.business_type === 'company'}
            required={values.business_type === 'company'}
            disabled={values.business_type === 'company' && !newCompany}
            transNS="fields"
            transPrefix="account.company.PhoneField"
          />
        </>
      ) : null}
    </Box>
  );
};

export { BusinessDetailsForm };
