import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useRef,
  createContext,
} from 'react';
import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
  alpha,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPublicProductById } from '../../api/products/productsRoutes.js';
import Carousel from 'react-material-ui-carousel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ProductRepresentativesContainer from '../../components/public-product/ProductRepresentativesContainer.jsx';
import MessageDialog from '../../components/public-product/MessageDialog';
import UserContext from '../../context/users/UserInfoProvider';
import PublicProductFiles from '../../components/public-product/PublicProductFiles';
import { useNavigationFooter } from '../../context/footer/NavigationFooterContext';
import { getProducRepresentativesById } from '../../api/products/productsRoutes.js';
import BuyDialog from './BuyDialog';
import { CartContext } from '../../context/cart/CartContext';
import { ReactComponent as Image3DSVG } from '../../assets/new-product/3d-image.svg';
import { CarouselItem } from '../../components/mui/carousel/Carouseltem.jsx';
import { trackActivity } from '../../api/tracker/trackerRoutes.js';
import ProductAttributesBox from '../../components/public-product/ProductAttributesBox.jsx';
import FormikTabs from '../../components/formik/FormikTabs.jsx';
import { useTranslation } from 'react-i18next';
import WarrantyTermsContainer from '../../components/public-product/containers/WarrantyTermsContainer.jsx';
import ProductApplicationsBox from '../../components/public-product/ProductApplicationsBox.jsx';
import ProductTypesBox from '../../components/public-product/ProductTypesBox.jsx';
import { AddToCartBox } from '../../components/public-product/AddToCartBox.jsx';
import ProductDescriptionBox from '../../components/public-product/ProductDescriptionBox.jsx';
import { getPublicCampaignDetails } from '../../api/referrals/referralRoutes.js';
import IntuIconButton from '../../components/buttons/IntuIconButton.jsx';
import ProductShippingContainer from '../../components/public-product/containers/ProductShippingContainer.jsx';
import { motion } from 'framer-motion';
import ARButton from '../../components/buttons/ARButton.jsx';

export const PublicProductPageContext = createContext();

const overlayTextVariants = {
  visible: {
    opacity: 1,
    y: 0,
    x: 0,
    transition: { type: 'tween', duration: 0.4, ease: 'easeOut' },
  },
  hidden: {
    opacity: 0,
    y: -100,
    x: 0,
    transition: { type: 'tween', duration: 0.3, ease: 'easeIn' },
  },
};

const PublicProductPage = () => {
  const params = new URLSearchParams(window.location.search);
  const referalID = params.get('referalID');
  const previewMode = params.get('preview') === 'true';

  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { conversations, setConversations } = useContext(UserContext);
  const { setCartProducts } = useContext(CartContext);
  const { setFooterConfig } = useNavigationFooter();
  const { t: transMessages } = useTranslation('messages');

  const [product, setProduct] = useState(null);
  const [selectedRep, setSelectedRep] = useState(null);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [isBuyDialogOpen, setIsBuyDialogOpen] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [wireframeMode, setWireframeMode] = useState(false);
  const [isModelInteracting, setisModelInteracting] = useState(false);
  const [autoRotate, setAutoRoate] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'components.PublicProductPage',
  });
  const { t: transTypes } = useTranslation('types');
  const { t: transButtons } = useTranslation('buttons');

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const productId = useMemo(() => {
    let productId = id;
    if (id.includes('&')) {
      productId = id.split('&')[0];
    }
    return productId;
  }, [id]);

  const getProduct = async () => {
    const response = await getPublicProductById(productId);
    setProduct(response);
  };

  const hasTrackedReferral = useRef(false);
  const hasTrackedVisit = useRef(false);

  const trackProductReferral = async (referalID) => {
    const payload = {
      documentId: referalID,
      module: 'referral_campaigns',
      action: 'visit',
      tracker: true,
    };
    const trackingResponse = await trackActivity({ payload });

    console.log('Track Referral on Visit -> ', trackingResponse);
  };

  const trackProductVisit = async (productID) => {
    const payload = {
      documentId: productID,
      module: 'products',
      action: 'visit',
      tracker: true,
    };
    const trackVisit = await trackActivity({ payload });

    // console.log('Track Product Visit on Product Level -> ', trackVisit);
  };

  useEffect(() => {
    // Guard Glause on Preview MOde
    if (previewMode) {
      return null;
    }

    //  Send request to acknowledge referral
    console.log('Has Tracker Referral -> ', hasTrackedReferral?.current);
    if (referalID && !hasTrackedReferral.current) {
      trackProductReferral(referalID);
      hasTrackedReferral.current = true;
    }

    //  Send request to acknowledge visit
    if (productId && !hasTrackedVisit.current) {
      trackProductVisit(productId);
      hasTrackedVisit.current = true;
    }
  }, [referalID, productId]);

  useEffect(() => {
    getProduct();
  }, []);

  const handleMessage = (rep) => {
    setSelectedRep(rep);
    setIsMessageDialogOpen(true);
  };

  const handleMessageDialogClose = () => {
    setSelectedRep(null);
    setIsMessageDialogOpen(false);
  };

  const handleSetChats = (newChats) => {
    setConversations(newChats);
    setIsMessageDialogOpen(false);
  };

  // Setup navigation footer
  useEffect(() => {
    // Check if user navigated from the same origin/domain
    const fromApp = window.location.hostname === location.state?.from;
    setFooterConfig({
      backLabel: transButtons('back'),
      showFooter: fromApp,
    });
    return () => {
      setFooterConfig((prev) => ({ ...prev, showFooter: false }));
    };
  }, []);

  const [productRepresentatives, setProductRepresentatives] = useState([]);

  // Get Sales Reps
  const getProductRepresentatives = async () => {
    const response = await getProducRepresentativesById(productId);

    // Get Referral User ID
    if (referalID) {
      // Get Referral Campaign Details
      const getReferralDetails = await getPublicCampaignDetails({
        id: referalID,
      });

      if (getReferralDetails.statusCode === 200) {
        const referralData = getReferralDetails.data;
        const userID = referralData.referrer;

        const filterAssociates = response.filter((rep) => rep._id === userID);

        if (filterAssociates.length > 0) {
          setProductRepresentatives(filterAssociates);
        } else {
          setProductRepresentatives(response);
        }
        return;
      }
    }
    setProductRepresentatives(response);
  };

  useEffect(() => {
    getProductRepresentatives();
  }, []);

  const handleSkip = () => {
    navigate('/cart');

    // Set to intutec associate as appointedRep as default
    const intutecAssociate = productRepresentatives.find(
      (rep) =>
        rep.account_type === 'intutec' && rep.email === 'sales@in2tec.io',
    );

    setCartProducts((prev) => {
      return [
        ...prev,
        {
          product,
          quantity,
          appointedRep: intutecAssociate,
          referalID,
        },
      ];
    });
  };

  const handleBuyConfirm = (rep) => {
    setCartProducts((prev) => {
      return [
        ...prev,
        {
          product,
          quantity,
          appointedRep: rep,
          referalID,
        },
      ];
    });
    navigate('/cart');
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const imageIndicators = useMemo(() => {
    if (!product || !product.images) return null;
    return product.images.map((image, i) => {
      if (image.imageType.toLowerCase() === 'usdz') {
        return;
      }

      if (image.imageType.toLowerCase() === 'gltf') {
        return <Image3DSVG key={i} width="50px" height="50px" />;
      }

      // console.log('Image Indicator URL -> ', image.url, image.name);
      return (
        <img
          key={image.name}
          alt={image.name}
          src={image.url}
          style={{
            width: '50px',
            height: '50px',
            margin: '0 4px',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '4px',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
            imageRendering: 'crisp-edges',
          }}
        />
      );
    });
  }, [product]);

  const column1Tabs = [
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.files.title')}
        </Typography>
      ),
      content: (
        <Box display="flex" flexDirection="column" gap={4}>
          <Box>
            <PublicProductFiles
              files={product?.files}
              images={product?.images}
            />
          </Box>

          <Box>
            <ProductApplicationsBox
              productApplications={product?.product_applications}
            />
          </Box>

          <Box>
            <ProductTypesBox productTypes={product?.product_types} />
          </Box>
        </Box>
      ),
    },
  ];

  const column2Tabs = [
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.product_info.title')}
        </Typography>
      ),
      content: <ProductDescriptionBox product={product} displayTitle={false} />,
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.technical_specification.title')}
        </Typography>
      ),
      content: (
        <ProductAttributesBox
          productAttributes={product?.product_attributes}
          displayTitle={false}
        />
      ),
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.warranty.title')}
        </Typography>
      ),
      content: (
        <WarrantyTermsContainer product={product} displayTitle={false} />
      ),
    },
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.shipping.title')}
        </Typography>
      ),
      content: (
        <ProductShippingContainer
          dimensions={product?.dimensions}
          weight={product?.weight}
          displayTitle={false}
          countryOfOrigin={product?.country_of_origin}
          tarifCode={product?.tarif_code}
        />
      ),
    },
  ];

  const column3Tabs = [
    {
      title: (
        <Typography variant="h6" textTransform="none">
          {t('tabs.purchase.title')}
        </Typography>
      ),
      content: (
        <>
          {product?.isPurchasable === true ? (
            <>
              <AddToCartBox
                productRepresentatives={productRepresentatives}
                referalID={referalID}
              />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                <Typography variant="body1">
                  {transTypes('leadtime')}:
                </Typography>
                <Typography variant="body1">
                  {product?.lead_time} {transTypes('days')}
                </Typography>
              </Box>
            </>
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="body" textAlign="center">
                {transMessages('available_soon')}
              </Typography>
            </Box>
          )}
          <ProductRepresentativesContainer
            productRepresentatives={productRepresentatives}
            handleMessage={handleMessage}
            chats={conversations}
            displayTitle={true}
          />
        </>
      ),
    },
  ];

  // Deal with scrolling behaviopt on mobile phones
  useEffect(() => {
    const preventTouch = (e) => e.preventDefault();

    if (isModelInteracting) {
      document.body.style.overflowY = 'hidden';
      document.addEventListener('touchmove', preventTouch, { passive: false });
    } else {
      document.body.style.overflowY = 'auto';
      document.removeEventListener('touchmove', preventTouch);
    }

    return () => {
      document.body.style.overflowY = 'auto';
      document.removeEventListener('touchmove', preventTouch);
    };
  }, [isModelInteracting]);

  if (!product) return <Skeleton height="60vh" />;

  return (
    <PublicProductPageContext.Provider
      value={{
        quantity,
        setQuantity,
        isBuyDialogOpen,
        setIsBuyDialogOpen,
        product,
        setProduct,
      }}
    >
      {/* Overlay Box */}
      <Box
        sx={{
          minHeight: '100vh', // fix the typo from "vdh"
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start', // so it doesn't vertically center everything
          overflowY: 'auto', // allow scrolling
        }}
      >
        {/* Product Meta */}
        <motion.div
          variants={overlayTextVariants}
          initial="hidden"
          animate={isModelInteracting ? 'hidden' : 'visible'}
          style={{
            position: 'absolute',
            left: isMobile ? '10%' : '15%',
            top: isMobile ? '0.5%' : '3%',
            width: isMobile ? '80%' : '70%',
            backgroundColor: alpha(theme.palette.primary.main, 0.85),
            border: `1px solid ${alpha(theme.palette.primary.main, 0.85)}`,
            borderRadius: '10px',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            zIndex: 4,
          }}
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              padding: 0.1,
            }}
          >
            <Typography variant="h6" color="white">
              {product.brand_name}
            </Typography>
            <Typography
              variant="h4"
              color="primary.contrastText"
              sx={{ mb: 0 }}
            >
              {product.product_name}
            </Typography>
          </Box>
        </motion.div>

        {/* Images */}
        <Box
          mb={2}
          sx={{
            height: isMobile ? '80vh' : '90vh',
            position: 'relative',
          }}
        >
          <Carousel
            sx={{
              flex: 1,
              flexDirection: 'column',
              alignItems: 'center',
              alignContent: 'center',
              width: '100%',
              height: '100%',
            }}
            index={activeIndex}
            animation="slide"
            navButtonsAlwaysVisible={product.images.length > 1}
            autoPlay={false}
            swipe={false}
            IndicatorIcon={!isModelInteracting ? imageIndicators : null}
            indicatorContainerProps={{
              style: {
                position: 'absolute',
                bottom: '5%',
                background: theme.palette.background.default,
                borderRadius: '5px',
                borderColor: theme.palette.primary.main,
              },
            }}
            indicatorIconButtonProps={{
              style: {
                zIndex: 100,
              },
            }}
            activeIndicatorIconButtonProps={{
              style: {
                borderBottom: `3px solid ${theme.palette.primary.main}`,
                borderRadius: 0,
                paddingBottom: '5px',
              },
            }}
            NextIcon={
              !isModelInteracting ? (
                <ArrowForwardIosIcon
                  sx={{ color: (theme) => theme.palette.tertiary.contrastText }}
                />
              ) : null
            }
            PrevIcon={
              !isModelInteracting ? (
                <ArrowBackIosNewIcon
                  sx={{ color: (theme) => theme.palette.tertiary.contrastText }}
                />
              ) : null
            }
            navButtonsProps={{
              style: {
                display:
                  !isModelInteracting && product.images.length > 1
                    ? 'inline-flex'
                    : 'none',
              },
            }}
            width="100vw"
            height="100vh"
            onChange={(now) => setActiveIndex(now)}
          >
            {product.images.map((image, i) => {
              return (
                <React.Fragment key={`carousel-wrapper-${i}`}>
                  <Box
                    key={`box-${i}`}
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: 1,
                      pointerEvents: !isModelInteracting ? 'auto' : 'none',
                    }}
                  />
                  <CarouselItem
                    key={`carousel-item-${i}`}
                    image={image}
                    showSliders={false}
                    viewMode="view"
                    initialCenter={true}
                    isModelInteracting={isModelInteracting}
                    enableZoom={isModelInteracting}
                    wireframeMode={wireframeMode}
                    setWireframeMode={setWireframeMode}
                    autoRotate={autoRotate}
                    autoRotateSpeed={0.5}
                    showOrbitControls={true}
                  />
                </React.Fragment>
              );
            })}
          </Carousel>

          {/* 3D Model Buttons */}
          {product.images[activeIndex]?.imageType?.toLowerCase() === 'gltf' && (
            <Box
              sx={{
                position: 'absolute',
                bottom: isModelInteracting
                  ? isMobile
                    ? '6%'
                    : '8%'
                  : isMobile
                    ? '16%'
                    : '14%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                zIndex: 2,
              }}
            >
              <motion.div
                initial={{ y: 100, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: 'tween',
                  duration: 0.6,
                  ease: 'easeOut',
                }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  width="200px"
                  sx={{
                    backgroundColor: alpha(theme.palette.secondary.light, 0.6),
                    border: `1px solid ${alpha(theme.palette.secondary.light, 0.6)}`,
                    borderRadius: 3,
                    padding: 1,
                  }}
                >
                  {/* Interact */}
                  <IntuIconButton
                    type={isModelInteracting ? 'interact_off' : 'interact_on'}
                    tooltipTitle={
                      isModelInteracting
                        ? 'disable_interaction'
                        : 'enable_interaction'
                    }
                    buttonColor={!isModelInteracting ? 'white' : null}
                    variant="outlined"
                    onClick={() => setisModelInteracting((prev) => !prev)}
                  />
                  {/* Wireframe Toggle */}
                  <IntuIconButton
                    type={wireframeMode ? 'wireframe_off' : 'wireframe_on'}
                    tooltipTitle={
                      wireframeMode ? 'disable_wireframe' : 'enable_wireframe'
                    }
                    variant="outlined"
                    buttonColor={!isModelInteracting ? 'white' : null}
                    onClick={() => setWireframeMode((prev) => !prev)}
                  />

                  {/* Auto-Rotate Toggle */}
                  <IntuIconButton
                    type={autoRotate ? 'stop' : 'play'}
                    tooltipTitle={
                      autoRotate ? 'disable_autoplay' : 'enable_autoplay'
                    }
                    variant="outlined"
                    buttonColor={!isModelInteracting ? 'white' : null}
                    onClick={() => setAutoRoate((prev) => !prev)}
                  />

                  {/* AR Button */}
                  <ARButton models={product.images} variant="outlined" />
                </Box>
              </motion.div>
            </Box>
          )}
        </Box>

        <Box
          display="flex"
          flexDirection={{ xs: 'column-reverse', lg: 'row' }}
          gap={4}
          p={2}
        >
          {/* Column 1 - Content */}
          <Box flex={0.25}>
            <FormikTabs tabsContent={column1Tabs} isScrollable={false} />
          </Box>

          {/* Column 2 - Content */}
          <Box flex={0.5}>
            <FormikTabs tabsContent={column2Tabs} isScrollable={true} />
          </Box>

          {/* Column 3 - Content */}
          <Box flex={0.25}>
            <FormikTabs tabsContent={column3Tabs} isScrollable={false} />
          </Box>
        </Box>

        <MessageDialog
          isOpen={isMessageDialogOpen}
          selectedRep={selectedRep}
          handleClose={handleMessageDialogClose}
          product={product}
          handleSetChats={handleSetChats}
          referalID={referalID}
          productID={productId}
        />
        <BuyDialog
          isOpen={isBuyDialogOpen}
          handleClose={() => setIsBuyDialogOpen(false)}
          handleConfirm={handleBuyConfirm}
          handleSkip={handleSkip}
          productName={product.product_name}
          isPurchasable={product.isPurchasable}
          quantity={quantity}
          productRepresentatives={productRepresentatives}
        />
      </Box>
    </PublicProductPageContext.Provider>
  );
};

export default PublicProductPage;
