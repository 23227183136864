import { useState, useEffect, useRef } from 'react';
import { Box, Slider, Stack } from '@mui/material';
import { Canvas } from '@react-three/fiber';
import ColorControlsContainer from './ColorControlsContainer';
import ThreeModel from './ThreeModel';

const CanvasContainer = ({
  url,
  type,
  options,
  viewMode,
  showSliders = true,
  showOrbitControls = true,
  minHeight = '400px',
  width = '100%',
  height = '100%',
  rotationX,
  setRotationX,
  rotationY,
  setRotationY,
  cameraPosition,
  setCameraPosition,
  setIsProductImageDirtied,
  meshes,
  setMeshes,
  setInitialMeshes,
  initialCenter,
  wireframeMode,
  enableZoom,
  autoRotate,
  autoRotateSpeed,
  enablePan,
  onLoaded,
}) => {
  const [showingArray, setShowingArray] = useState([]);
  const [localScene, setLocalScene] = useState(null);
  const [isInView, setIsInView] = useState(false);
  const containerRef = useRef(null);

  const handleRotationXSliderChange = (e) => {
    setRotationX(e.target.value);
    setIsProductImageDirtied(true);
  };

  const handleRotationYSliderChange = (e) => {
    setRotationY(e.target.value);
    setIsProductImageDirtied(true);
  };

  const handleSceneLoaded = (scene) => {
    setLocalScene(scene);
  };

  // Setup intersection observer to detect when component is visible
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsInView(entry.isIntersecting);
        });
      },
      { threshold: 0.1 }, // Consider visible when 10% of element is in viewport
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Reset local scene when component goes out of view
  useEffect(() => {
    if (!isInView && localScene) {
      setLocalScene(null);
    }
  }, [isInView, localScene]);

  useEffect(() => {
    if (options) {
      const {
        rotationX = 0,
        rotationY = 0,
        cameraPosition = [0, 0, 50],
      } = options;

      setRotationX(rotationX);
      setRotationY(rotationY);
      setCameraPosition(cameraPosition);
    }
  }, [options]);

  return (
    <Box
      ref={containerRef}
      display="flex"
      flexDirection="column"
      alignItems="center"
      minHeight={minHeight}
      height="100%"
    >
      <Box
        position="relative"
        width={width}
        height={height}
        sx={{
          width: width,
          height: height,
          touchAction: 'none', // 👈 blocks default swipe
        }}
      >
        {isInView ? (
          <Canvas sx={{ width: '100%', height: '100%' }}>
            <ThreeModel
              url={url}
              type={type}
              handleSceneLoaded={handleSceneLoaded}
              showOrbitControls={showOrbitControls}
              cameraPosition={cameraPosition}
              setCameraPosition={setCameraPosition}
              rotationX={rotationX}
              rotationY={rotationY}
              setIsProductImageDirtied={setIsProductImageDirtied}
              initialCenter={initialCenter}
              wireframeMode={wireframeMode}
              enableZoom={enableZoom}
              autoRotate={autoRotate}
              autoRotateSpeed={autoRotateSpeed}
              enablePan={enablePan}
              onLoaded={onLoaded}
            />
          </Canvas>
        ) : (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="grey.200"
          >
            {/* Placeholder when model is not in view */}
          </Box>
        )}
      </Box>
      {showSliders && isInView ? (
        <>
          <Slider
            defaultValue={0}
            type="range"
            min={0}
            max={63}
            value={rotationX}
            onChange={handleRotationXSliderChange}
            sx={{
              width: '50%',
              bottom: 5,
              color: 'secondary.main', // Use a secondary theme color
              '& .MuiSlider-thumb': {
                backgroundColor: 'green.main', // Change thumb color
              },
              '& .MuiSlider-track': {
                backgroundColor: 'grey.main', // Change track color
              },
              '& .MuiSlider-rail': {
                backgroundColor: 'grey.dark', // Change inactive track color
              },
            }}
            position="absolute"
          />
          <Stack
            sx={{
              position: 'absolute',
              height: 400,
              bottom: '10vh',
              right: '15vw',
            }}
            spacing={1}
            direction="row"
          >
            <Slider
              defaultValue={0}
              type="range"
              min={0}
              max={63}
              value={rotationY}
              onChange={handleRotationYSliderChange}
              sx={{
                top: 50,
                width: '20%',
                height: '30vh',
                color: 'secondary.main', // Use a secondary theme color
                '& .MuiSlider-thumb': {
                  backgroundColor: 'green.main', // Change thumb color
                },
                '& .MuiSlider-track': {
                  backgroundColor: 'grey.main', // Change track color
                },
                '& .MuiSlider-rail': {
                  backgroundColor: 'grey.dark', // Change inactive track color
                },
              }}
              orientation="vertical"
            />
          </Stack>
        </>
      ) : null}
      {viewMode === 'edit' && localScene && isInView ? (
        <ColorControlsContainer
          scene={localScene}
          showingArray={showingArray}
          setShowingArray={setShowingArray}
          meshes={meshes}
          setMeshes={setMeshes}
          setInitialMeshes={setInitialMeshes}
          setIsProductImageDirtied={setIsProductImageDirtied}
        />
      ) : null}
    </Box>
  );
};

export default CanvasContainer;
