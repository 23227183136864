import '@google/model-viewer';

const USDZModel = ({ url }) => {
  return (
    <model-viewer
      src={url}
      ar
      auto-rotate
      camera-controls
      style={{ width: '100%', height: '100%' }}
      ios-src={url}
    />
  );
};

export default USDZModel;
